import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, limit } from 'firebase/firestore';
import { db } from '../../../firebase';
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { getProfileImageFromProfileData } from '../../../Utils';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';

function UserAutoComplete() {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();

  const handleSearchSelect = (_event, value) => {
    navigate(`/${value.username}`)
  }

  const fetchMembers = async (input) => {
    if (!input.trim()) return;
    const q = query(collection(db, 'members'), where('username', '>=', input), where('username', '<=', input + '\uf8ff'), limit(10));
    const querySnapshot = await getDocs(q);
    const membersList = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setOptions(membersList);
  };

  return (
    <Autocomplete
      disablePortal
      id="user-search-box"
      options={options}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => {
        let lowercaseInput = newInputValue.toLowerCase();
        if (lowercaseInput.charAt(0) === "@") {
          lowercaseInput = lowercaseInput.slice(1);
        }
        setInputValue(lowercaseInput);
        fetchMembers(lowercaseInput);
      }}
      onChange={handleSearchSelect}
      getOptionLabel={(option) => option.username}
      className="userAutoComplete"
      sx={{
        [`& .${autocompleteClasses.popupIndicator}`]: {
          display: "none"
        },
        [`& .${autocompleteClasses.clearIndicator}`]: {
          display: "none"
        },
        "& .MuiOutlinedInput-root": {
          padding: "0px",
          height: "3em",
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          border: "none"
        },
        "& .MuiAutocomplete-inputRoot": {
          border: "none",
          paddingLeft: "5px",
          paddingTop: "5px",
          paddingBottom: "5px",
        },
        "& .MuiAutocomplete-input": {
          fontFamily: "'Outfit', sans-serif !important",
          border: "none",
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search username"
          InputProps={{
            ...params.InputProps,
            sx: {
              border: "none",
            },
            endAdornment: (
              <React.Fragment>
                {params.InputProps.endAdornment}
                <IconButton
                  sx={{
                    color: "#1e1e1e",
                    position: "absolute",
                    p: "0px",
                    right: "10px",
                    top: "calc(50% - 12px)",
                    cursor: "default",
                    "&:hover": {
                      background: "transparent"
                    }
                  }}>
                  <PersonSearchIcon />
                </IconButton>
              </React.Fragment>
            ),
          }}
          InputLabelProps={{
            sx: {
              fontFamily: "'Outfit', sans-serif !important",
              marginTop: "-4px",
              "&.Mui-focused": {
                display: "none",
              },
              "&.MuiInputLabel-outlined": {
                marginTop: "-5px",
                color: "#1e1e1e"
              }
            }
          }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props}>
          <img src={getProfileImageFromProfileData(option)} alt={option.username} className="dropdownProfileImg" />
          <div style={{ fontFamily: "'Outfit', sans-serif" }}>
            <span style={{ fontWeight: "500" }}> {option.username}<br /></span>
            <span style={{ fontSize: "0.92em", }}>{option.firstName + " " + option.lastName}</span>
          </div>
        </li>
      )}
    />
  );
}

export default UserAutoComplete;
