import React, { useState } from 'react';
import { handleCollapsibleUpdate } from '../../../../../Utils';
import Linkify from "linkify-react";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import TextField from '@mui/material/TextField';
import Rating from '@mui/material/Rating';
import Checkbox from '@mui/material/Checkbox';
import DSText from '../../../../../DSSystem/DSText';
import { DatePicker } from 'antd';
import './styles.css'
dayjs.extend(utc);

const CardCollapsible = ({ entryData, index, isEditable, hasRating, hasAnnotation, showAnnotations, setSelectedEntriesData, listId }) => {
  const [rating, setRating] = useState(entryData.rating ? entryData.rating : 0);
  const [annotation, setAnnotation] = useState(entryData.annotation || "Add review, annotation, or link...");
  const [checked, setChecked] = useState(entryData.checked || false);
  const [checkedTime, setCheckedTime] = useState(entryData.checkedTime || null);

  const handleAnnotationClick = () => {
    if (annotation === "Add review, annotation, or link...") {
      setAnnotation("");
    }
  }

  const handleAnnotationChange = (event) => {
    setAnnotation(event.target.value);
  };

  const handleAnnotationBlur = (event) => {
    if (annotation !== "Add review, annotation, or link...") {
      handleCollapsibleUpdate(listId, index, rating, annotation, checked, checkedTime, setSelectedEntriesData);
    }
    if (annotation === "") {
      setAnnotation("Add review, annotation, or link...")
    }
  }

  const handleDateChange = (date) => {
    const utcDate = date ? dayjs(date).utc() : dayjs().utc();
    setCheckedTime(utcDate.valueOf());
    handleCollapsibleUpdate(listId, index, rating, annotation, checked, utcDate.valueOf(), setSelectedEntriesData);
  };

  return (
    <>
      {(isEditable || hasRating || hasAnnotation) &&
        <div
          className="collapsibleContent"
          style={{ display: showAnnotations ? "block" : "none" }}>
          <DSText fontSize="0.9em" color="#1e1e1e">
            {isEditable ? (
              <>
                <div className="collapsibleCheckboxContainer">
                  {entryData.checked && checkedTime ? (
                    <div className='collapsibleCompletionDate'>
                      <span>Completed on:</span>
                      <DatePicker
                        className='collapsibleDatePicker'
                        defaultValue={dayjs(checkedTime)}
                        format="MMMM D, YYYY"
                        allowClear={false}
                        maxDate={dayjs()}
                        onChange={handleDateChange}
                      />
                    </div>
                  ) : (
                    "Mark Completed:"
                  )}
                  <Checkbox
                    checked={checked}
                    onChange={(event) => {
                      setChecked(event.target.checked);
                      setCheckedTime(Date.now());
                      handleCollapsibleUpdate(listId, index, rating, annotation, event.target.checked, Date.now(), setSelectedEntriesData);
                    }}
                    sx={{
                      '&:hover': {
                        bgcolor: 'transparent'
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: '1.1rem',
                        color: '#777',
                      },
                      padding: "9px 0px 9px 5px"
                    }}
                    className="collapsibleCheckbox"
                  />
                </div>
                <div className="collapsibleEditableRatingContainer">
                  <div className="collapsibleRatingText">Rating:</div>
                  <Rating
                    name="half-rating"
                    precision={0.5}
                    value={rating}
                    onChange={(_, newRating) => {
                      setRating(newRating);
                      handleCollapsibleUpdate(listId, index, newRating, annotation, checked, checkedTime, setSelectedEntriesData);
                    }}
                    sx={{
                      '& .MuiRating-icon': {
                        color: '#777'
                      },
                      '& .MuiRating-iconFilled': {
                        color: '#777',
                      },
                      '& .MuiRating-iconHover': {
                        color: '#777',
                      },
                      fontSize: '1.5rem',
                    }}
                    className="collapsibleRating"
                  />
                  {hasRating && rating ? <div className="collapsibleRatingValue">({rating})</div> : <></>}
                </div>
                <TextField
                  id="standard-multiline-static"
                  value={annotation}
                  multiline
                  variant="standard"
                  className="collapsibleText"
                  onClick={handleAnnotationClick}
                  onChange={handleAnnotationChange}
                  onBlur={handleAnnotationBlur}
                  sx={{ padding: "0px !important" }}
                  InputProps={{
                    spellCheck: 'false',
                    disableUnderline: true,
                    sx: {
                      fontFamily: "'Outfit', sans-serif !important",
                      fontSize: "1em",
                      lineHeight: "1.35em"
                    },
                  }}
                />
              </>
            ) : (
              <div className="collapsibleViewableContainer">
                {hasRating
                  ? (
                    <div className="collapsibleRatingContainer">
                      <div className="collapsibleRatingText">Rating:</div>
                      <Rating
                        name="read-only-half-rating"
                        readOnly={true}
                        precision={0.5}
                        value={rating}
                        sx={{
                          '& .MuiRating-iconFilled': {
                            color: '#777',
                          },
                          fontSize: '1.2rem',
                        }}
                        className="collapsibleRating collapsibleRatingReadOnly"
                      />
                      {hasRating && rating ? <div className="collapsibleRatingValue">({rating})</div> : <></>}
                    </div>
                  ) : <></>
                }
                {hasRating && hasAnnotation ? <div style={{ marginBottom: '1em' }}></div> : <></>}
                {hasAnnotation
                  ? (
                    <Linkify options={{ defaultProtocol: "https", target: "_blank" }}>
                      <div className="collapsibleText">
                        {entryData.annotation}
                      </div>
                    </Linkify>
                  ) : <></>
                }
              </div>
            )}
          </DSText>
        </div>
      }
    </>
  );
};

export default CardCollapsible;