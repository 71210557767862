import React from 'react';
import DSText from '../../../DSSystem/DSText';
import './styles.css';

const ProfileShareSquare = ({ profileImage, profileUsername, profileFirstName, profileLastName, publicListLength, followersLength }) => {
	return (
		<div style={{ position: "absolute", top: "0", opacity: "0", pointerEvents: "none" }}>
			<div className="profileShareSquare" id={`${profileUsername}-square`}>
				<div className="profileShareSquareBody">
					{profileImage && <img className="profileShareSquareThumbnail" src={profileImage} alt="thumbnail" />}
					<div>
						<DSText fontSize="18px" boldVar="600" marginBottom="4px">{profileFirstName} {profileLastName}</DSText>
						<DSText fontSize="14px" color="#cfcfcf">
							{publicListLength}
							{publicListLength === 1 ? " list" : " lists"}
							<span style={{ paddingRight: "10px" }}></span>
							{followersLength}
							{followersLength === 1 ? " follower" : " followers"}
						</DSText>
					</div>
				</div>
				<div className="profileShareSquareFooter">
					<DSText fontSize="12px">
						<span className="profileShareSquareLink">palette.build/{profileUsername}</span>
					</DSText>
				</div>
			</div>
		</div>
	);
};

export default ProfileShareSquare;