import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import FeatureCard from './FeatureCard';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ShareIcon from '@mui/icons-material/Share';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SearchIcon from '@mui/icons-material/Search';

function FeatureSet() {
  return (
    <ThemeProvider
      theme={createTheme({
        breakpoints: {
          values: {
            lg: 1500,
            md: 900,
            sm: 600,
            xs: 450,
            xxs: 0,
          },
        },
      })}
    >
      <Grid container spacing={2} justifyContent="center" alignItems="stretch">
        <Grid item xxs={10} xs={8} sm={6} md={4} lg={3} display="flex">
          <FeatureCard heading="Curate and Organize Everything You Care About" icon={FilterAltIcon} />
        </Grid>
        <Grid item xxs={10} xs={8} sm={6} md={4} lg={3} display="flex">
          <FeatureCard heading="Personalize with Annotations, Tags, and Ratings" icon={ModeEditIcon} />
        </Grid>
        <Grid item xxs={10} xs={8} sm={6} md={4} lg={3} display="flex">
          <FeatureCard heading="Follow Friends and Creators for their Latest Picks" icon={PersonAddAlt1Icon} />
        </Grid>
        <Grid item xxs={10} xs={8} sm={6} md={4} lg={3} display="flex">
          <FeatureCard heading="Share Your Finds with Rich Social Media Embeddings" icon={ShareIcon} />
        </Grid>
        <Grid item xxs={10} xs={8} sm={6} md={4} lg={3} display="flex">
          <FeatureCard heading="Collaborate with Your Friends and Family" icon={GroupAddIcon} />
        </Grid>
        <Grid item xxs={10} xs={8} sm={6} md={4} lg={3} display="flex">
          <FeatureCard heading="Explore New Content with Tailored Recommendations" icon={SearchIcon} />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default FeatureSet;
