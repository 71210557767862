import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DSText from '../../../DSSystem/DSText';
import { handleGroupListCreation } from '../../../Utils';
import './styles.css';

const TitleChip = ({ listTitle, listCategory, userData, groupId }) => {
  const navigate = useNavigate();
  const [isCreatingList, setIsCreatingList] = useState(false);

  return (
    <div className="titleChipContainer" onClick={() => handleGroupListCreation(listTitle, listCategory, userData, navigate, groupId, isCreatingList, setIsCreatingList, false)}>
      <DSText>{listTitle}</DSText>
    </div>
  );
};

export default TitleChip;