import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { auth, db, storage } from '../../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { collection, query, where, getDocs, getDoc, updateDoc, doc, arrayUnion, arrayRemove, setDoc, deleteDoc, runTransaction, increment } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { v4 as uuidv4 } from 'uuid';
import { getProfileImageFromProfileData, handleProfileShare, refreshUserData, updateProfileShareImage, updateUserStreak } from '../../Utils';
import axios from "axios";
import Linkify from "linkify-react";
import DSContainer from '../../DSSystem/DSContainer';
import DSText from '../../DSSystem/DSText';
import DSNavbar from '../../DSSystem/DSNavbar';
import DSConfirmDialog from '../../DSSystem/DSConfirmDialog';
import DSUserModal from '../../DSSystem/DSModal/DSUserModal';
import ProfileShareRectangle from '../ShareImages/ProfileShareRectangle';
import ProfileShareSquare from '../ShareImages/ProfileShareSquare';
import FeatureRequestForm from '../FeatureRequestForm';
import ShareIcon from '@mui/icons-material/Share';
import LinkIcon from '@mui/icons-material/Link';
import UploadIcon from '@mui/icons-material/Upload'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PaletteIcon from '@mui/icons-material/Palette';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import LogoutIcon from '@mui/icons-material/Logout';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import SportsKabaddiIcon from '@mui/icons-material/SportsKabaddi';
import ArchiveIcon from '@mui/icons-material/Archive';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import BlenderOutlinedIcon from '@mui/icons-material/BlenderOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import PaletteTab from './Palette';
import LikedTab from './Liked';
import RandomList from '../RandomList';
import ShareIcons from '../ShareIcons';
import dayjs from 'dayjs';
import './styles.css';

const HomePage = () => {
  const requireEmailVerification = process.env.REACT_APP_REQUIRE_EMAIL_VERIFICATION;
  const { username } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [userData, setUserData] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [imageHover, setImageHover] = useState(false);
  const [profileBio, setProfileBio] = useState("Add bio...");
  const [allListData, setAllListData] = useState([]);
  const [likedLists, setLikedLists] = useState([]);
  const [flagArray, setFlagArray] = useState([]);
  const [isHoverArray, setIsHoverArray] = useState([]);
  const [visibleListLength, setVisibleListLength] = useState(0);
  const [publicListLength, setPublicListLength] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFollowersPopupOpen, setFollowersPopupOpen] = useState(false);
  const [isFollowingPopupOpen, setFollowingPopupOpen] = useState(false);
  const [isLikedTab, setIsLikedTab] = useState(false);
  const [uid, setUid] = useState(null);
  const [flag, setFlag] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [logOutDialogOpen, setLogOutDialogOpen] = useState(false);
  const [logOutStatus, setLogOutStatus] = useState(false);
  const [confirmButtonText, setConfirmButtonText] = useState("Confirm");
  const shareImageRectURL = `https://firebasestorage.googleapis.com/v0/b/list-curation.appspot.com/o/shareImages%2Fprofile%2F${username}-rect.png?alt=media&token=208a6fc5-51a1-4b83-a054-ea065ea217e4`;
  const shareImageSquareURL = `https://firebasestorage.googleapis.com/v0/b/list-curation.appspot.com/o/shareImages%2Fprofile%2F${username}-square.png?alt=media&token=208a6fc5-51a1-4b83-a054-ea065ea217e4`;

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const userQuery = query(collection(db, 'members'), where('username', '==', username));
        const querySnapshot = await getDocs(userQuery);
        if (!querySnapshot.empty) {
          const userDoc = querySnapshot.docs[0];
          const userDataFromDB = userDoc.data();
          const userId = userDataFromDB.id;
          if (userId) {
            setUid(userId);
            setProfileData(userDataFromDB);
            if (userDataFromDB.bio) {
              setProfileBio(userDataFromDB.bio);
            } else {
              setProfileBio("Add bio...");
            }
            setProfileImage(getProfileImageFromProfileData(userDataFromDB));
            await resetUserStreakIfNeeded(userDataFromDB)
            fetchProfileLists(userId);
          } else {
            navigate("/");
          }
        } else {
          console.error('User not found');
          navigate("/404");
        }
      } catch (error) {
        console.error('Error fetching user UID:', error);
        navigate("/500");
      }
    };

    const resetUserStreakIfNeeded = async (userData) => {
      const userRef = doc(db, 'members', userData.email);
      const currentTime = dayjs();
      if (!userData.lastSeenAt) {
        // If lastSeenAt is not present, do nothing
        return;
      } else {
        const lastSeenTime = dayjs(userData.lastSeenAt);
        const differenceInDays = currentTime.diff(lastSeenTime, 'day');
        if (differenceInDays > 1) {
          await updateDoc(userRef, {
            currentStreak: 0,
          });
        }
      }
    };

    const fetchProfileLists = async (userId) => {
      try {
        // Make this faster by calling lists on userData directly.
        // This is way more inefficient.
        const q = query(collection(db, 'lists'), where('memberId', 'array-contains', userId));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          let userDocs = querySnapshot.docs.map(doc => doc.data());
          userDocs = userDocs.sort((a, b) => (b.score + b.likedBy.length) - (a.score + a.likedBy.length));
          setAllListData(userDocs);
          setFlagArray(Array(userDocs.length).fill(false));
          setIsHoverArray(Array(userDocs.length).fill(false));
        } else {
          setAllListData([]);
        }
      } catch (error) {
        console.error('Error fetching user lists:', error.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (username) {
      fetchProfileData();
    }
  }, [username, navigate]);

  useEffect(() => {
    const adminEmails = ["mehulrastogi2002@gmail.com", "mittalprakhar57@gmail.com"]
    const visibleListData = allListData.filter(listData => (
      listData.isPublic || (listData.memberId && userData && listData.memberId.includes(userData.id)) || (userData && adminEmails.includes(userData.email))
    ));
    setVisibleListLength(visibleListData.length)
    const publicListData = allListData.filter(listData => (listData.isPublic));
    setPublicListLength(publicListData.length)
  }, [userData, allListData])

  useEffect(() => {
    const handleUpdateShareImage = async () => {
      if (userData && username === userData.username && !isLoading && publicListLength) {
        await updateProfileShareImage(username);
      }
    }

    handleUpdateShareImage();
  }, [username, userData, isLoading, publicListLength])

  const handleCreateButtonClick = () => {
    navigate("/create");
  }

  const handleEditProfile = () => {
    navigate("/edit-profile")
  }

  const handleCopyLink = () => {
    setFlag(true);
    const currListPath = `/${username}`;
    const urlToCopy = window.location.origin + currListPath;
    navigator.clipboard.writeText(urlToCopy)
      .catch((error) => {
        console.error('Error copying link to clipboard:', error);
      });
  };

  const handleFollowersPopupToggle = () => {
    setFollowersPopupOpen(!isFollowersPopupOpen);
  };

  const handleFollowingPopupToggle = () => {
    setFollowingPopupOpen(!isFollowingPopupOpen);
  };

  useEffect(() => {
    const fetchUserData = async (userId) => {
      try {
        const q = query(collection(db, 'members'), where('id', '==', userId));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const userDoc = querySnapshot.docs[0];
          const userDataFromDB = userDoc.data();
          setUserData(userDataFromDB);
          setLikedLists(userDataFromDB.likedLists || []);
          await updateUserStreak(userDataFromDB)
          await refreshUserData(userId, setUserData)
        } else {
          console.error('User not found');
          navigate("/500");
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
        navigate("/500");
      }
    };

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        if (user.emailVerified || requireEmailVerification === "false") {
          fetchUserData(user.uid);
        } else {
          await signOut();
          navigate("/");
        }
      }
    });

    return () => unsubscribe();
  }, [navigate, requireEmailVerification]);

  // Profile picture logic
  const handleProfileImageChange = async (event) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      const storageRef = ref(storage, `profileImages/${profileData.email}`);
      const snapshot = await uploadBytes(storageRef, selectedImage);
      const downloadURL = await getDownloadURL(snapshot.ref);
      // setProfileImage(downloadURL);
      setImageHover(false);
      try {
        const memberRef = doc(db, 'members', profileData.email);
        await updateDoc(memberRef, {
          profileImage: downloadURL,
        });
      } catch (error) {
        console.error('Error updating profile image:', error.message);
      }
      window.location.reload();
    }
  }

  // Follower logic
  const handleFollowUnfollowClick = async () => {
    if (!userData) {
      navigate(`/login?redirectTo=${location.pathname}`);
    } else {
      try {
        if (profileData && profileData.followers !== undefined) {
          const memberRef = doc(db, 'members', profileData.email);
          const followingUserRef = doc(db, 'members', userData.email);
          const isFollowing = profileData.followers.includes(userData.id);
          let memberData;

          if (isFollowing) {
            // User is in followers list, remove them
            await updateDoc(memberRef, {
              followers: arrayRemove(userData.id),
            });
            await updateDoc(followingUserRef, {
              following: arrayRemove(profileData.id),
            });

            const updatedProfileDataSnapshot = await getDoc(memberRef);
            const updatedProfileData = updatedProfileDataSnapshot.data();
            setProfileData(updatedProfileData);

            // Notification logic
            const notificationRef = collection(db, "notifications");
            const deleteQuery = query(notificationRef,
              where("userId", "==", userData.id),
              where("profileId", "==", profileData.id),
              where("type", "==", "follow")
            );
            getDocs(deleteQuery).then(async (querySnapshot) => {
              if (!querySnapshot.empty) {
                const firstDoc = querySnapshot.docs[0];
                if (profileData && profileData.hasOwnProperty("notificationsTabLastSeenAt") && firstDoc.data().createdAt > profileData.notificationsTabLastSeenAt) {
                  await updateDoc(memberRef, {
                    notificationsUnseen: increment(-1),
                  });
                }
                deleteDoc(doc(db, "notifications", firstDoc.id))
                  .catch((error) => {
                    console.error("Error removing document: ", error);
                  });
                const memberSnapshot = await getDoc(memberRef);
                if (!memberSnapshot.empty) {
                  const memberDataFromDB = memberSnapshot.data();
                  if (memberDataFromDB.hasOwnProperty("notifications")) {
                    const profileNotification = memberDataFromDB.notifications;
                    const notificationDocRef = doc(db, 'profileNotifications', profileNotification);
                    await updateDoc(notificationDocRef, {
                      events: arrayRemove(firstDoc.id),
                    });
                  }
                }
              }
            }).catch((error) => {
              console.error("Error getting documents: ", error);
            });

          } else {
            // User is not in followers list, add them
            await updateDoc(memberRef, {
              followers: arrayUnion(userData.id),
            });
            await updateDoc(followingUserRef, {
              following: arrayUnion(profileData.id),
            });

            const updatedProfileDataSnapshot = await getDoc(memberRef);
            const updatedProfileData = updatedProfileDataSnapshot.data();
            setProfileData(updatedProfileData);

            // Notification logic
            const memberSnap = await getDoc(memberRef);
            if (memberSnap.exists()) {
              memberData = memberSnap.data()
              let profileNotification;
              if (!memberData.hasOwnProperty("notifications")) {
                profileNotification = uuidv4();
                await updateDoc(memberRef, {
                  notifications: profileNotification
                })
                await setDoc(doc(db, "profileNotifications", profileNotification), {
                  id: profileNotification,
                  events: []
                });
              } else {
                profileNotification = memberData.notifications
              }
              const profileNotificationsRef = doc(db, 'profileNotifications', profileNotification)
              const eventUUID = uuidv4();
              const newNotificationEvent = {
                id: eventUUID,
                type: "follow",
                createdAt: Date.now(),
                userId: userData.id,
                profileId: profileData.id
              }
              await setDoc(doc(db, "notifications", eventUUID), newNotificationEvent)
              await updateDoc(profileNotificationsRef, {
                events: arrayUnion(eventUUID),
              });
            }

            // Notification Count
            await runTransaction(db, async (transaction) => {
              const ownerDoc = await transaction.get(memberRef);
              const notificationsUnseen = ownerDoc.data()?.notificationsUnseen;
              if (notificationsUnseen !== undefined) {
                // If notificationsUnseen exists, increment it by 1
                transaction.update(memberRef, { notificationsUnseen: notificationsUnseen + 1 });
              } else {
                // If notificationsUnseen does not exist, set it to 1
                transaction.set(memberRef, { notificationsUnseen: 1 }, { merge: true });
              }
            });
          }

          const updatedProfileDataSnapshot = await getDoc(memberRef);
          const updatedProfileData = updatedProfileDataSnapshot.data();
          setProfileData(updatedProfileData);

          // Send email to member
          if (!isFollowing && memberData) {
            const followingUserSnap = await getDoc(followingUserRef);
            if (followingUserSnap.exists()) {
              const followingUserData = followingUserSnap.data()
              let email = memberData.email;
              let subject = "You have a new follower on Palette!";
              let template = "New Follower";
              let variables = {
                userFirstName: memberData.firstName,
                followerFirstName: followingUserData.firstName,
                followerFirstNameWithPossessive: `${followingUserData.firstName}'${!followingUserData.firstName.endsWith('s') ? 's' : ''}`,
                link: window.location.origin + "/" + followingUserData.username
              };
              try {
                await axios.post(`/api/email`, { email, subject, template, variables });
              } catch (error) {
                console.error('Error sending email:', error.message);
              }
            }
          }
        }
      } catch (error) {
        console.error('Error updating followers:', error.message);
      }
    }
  };

  // Bio logic
  const handleBioClick = () => {
    if (profileBio === "Add bio...") {
      setProfileBio("");
    }
  }

  const handleBioChange = (event) => {
    setProfileBio(event.target.value);
  };

  const handleBioBlur = (event) => {
    if (profileBio !== "Add bio...") {
      handleBioUpdate(profileBio);
    }
    if (profileBio === "") {
      setProfileBio("Add bio...")
    }
  }

  const handleBioUpdate = async (profileBio) => {
    try {
      const profileRef = doc(db, 'members', profileData.email);
      const profileDocSnapshot = await getDoc(profileRef);
      if (profileDocSnapshot.exists()) {
        await updateDoc(profileRef, {
          bio: profileBio,
        });
      }
    } catch (error) {
      console.error('Error updating bio: ', error);
    }
  }

  useEffect(() => {
    const logOutAfterConfirmation = async () => {
      try {
        await signOut(auth);
        navigate("/");
      } catch (error) {
        setConfirmButtonText("Error");
        console.error('Error logging out:', error.message);
      }
    }

    if (logOutStatus) {
      logOutAfterConfirmation();
    }
  }, [logOutStatus, navigate])

  return (
    <>
      <Helmet>
        <title>
          {profileData ?
            `${profileData?.firstName} ${profileData?.lastName} - Palette`
            : `Profile - Palette`}
        </title>
        <meta property="og:title"
          content={profileData ?
            `${profileData?.firstName} ${profileData?.lastName} - Palette`
            : `Profile - Palette`} />
        <link rel="canonical" href={"https://palette.build" + location.pathname} />
        <meta property="og:url" content={"https://palette.build" + location.pathname} />
        <meta property="twitter:image" content={shareImageSquareURL} />
        <meta property="og:image" content={shareImageSquareURL} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="400" />*
        <meta property="og:image" content={shareImageRectURL} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:description"
          content={profileData ?
            `Check out ${profileData.firstName}'${!profileData.firstName.endsWith('s') ? 's' : ''} palette!`
            : "Curate, share, and discover movies, TV shows, books, and more!"} />
        <meta name="keywords" content={`palette, lists, social media, profile`} />
      </Helmet>
      {!isLoading ? (
        <>
          {userData ? (
            <DSNavbar isLoggedIn={true} currentPage={userData && uid === userData.id && "Home"} userData={userData} />
          ) : (
            <DSNavbar isLoggedIn={false} />
          )}
          <DSContainer justifyContent="start">
            <div className="cardContainer">
              <>
                <div className="profileContainer">
                  <div className="topProfileContainer">
                    <div className="leftProfileContainer">
                      <div
                        onClick={() => {
                          if (userData && uid === userData.id) {
                            document.getElementById('profileImage').click()
                          }
                        }}
                        onMouseOver={() => {
                          if (userData && uid === userData.id) {
                            setImageHover(true);
                          }
                        }}
                        onMouseOut={() => setImageHover(false)}
                        className="profileImageContainer">
                        <img
                          className="profileImage"
                          src={profileImage}
                          alt="profile"
                          style={{ WebkitFilter: imageHover ? "brightness(50%)" : "brightness(100%)" }}
                        />
                        <input
                          type="file"
                          id="profileImage"
                          name="profileImage"
                          accept="image/*"
                          onChange={handleProfileImageChange}
                          style={{ display: "none" }}
                        />
                        {imageHover && <UploadIcon className="profileImageUploadIcon" />}
                        {"currentStreak" in profileData && profileData.currentStreak > 0 &&
                          <div className="streakAvatarContainer" style={{ position: 'absolute', top: '4px', right: '0px', backgroundColor: '#f5f5f5', borderRadius: '50%', padding: '3px 5px 4px 3px', boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)" }}>
                            <DSText color="black" fontSize="13px" boldVar="500">🔥{profileData.currentStreak}</DSText>
                          </div>
                        }
                      </div>
                      {userData && uid === userData.id
                        ? (
                          <button className="editProfileButton" onClick={handleEditProfile}>
                            <ManageAccountsIcon className="editProfileIcon" />
                            {" "} Edit Profile
                          </button>
                        ) : (
                          <Tooltip title="Coming Soon!" slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: 'offset',
                                  options: {
                                    offset: [0, -6],
                                  },
                                },
                              ],
                            },
                          }}>
                            <div className="blendButton">
                              <BlenderOutlinedIcon className="blendIcon" />
                              {" "} Make Blend
                            </div>
                          </Tooltip>
                        )
                      }
                    </div>
                    <div>
                      <DSText fontSize="1.9em" boldVar="600" marginTop="9px" marginBottom="1px">
                        {profileData.firstName}{' '}{profileData.lastName}
                      </DSText>
                      <DSText fontSize="1.05em" marginBottom="16px" color="#cfcfcf" boldVar="400">
                        <span style={{ color: "#f0f0f0", marginRight: "3px" }}>Username:</span> @{profileData.username}
                      </DSText>
                      <div className="socialCountContainer">
                        <div className="social1">
                          <DSText fontSize="1.1em">
                            {visibleListLength}
                            {visibleListLength === 1 ? " list" : " lists"}
                          </DSText>
                        </div>
                        <div className="social2" onClick={handleFollowersPopupToggle} style={{ cursor: 'pointer' }}>
                          <DSText fontSize="1.1em">
                            {profileData.followers.length}
                            {profileData.followers.length === 1 ? " follower" : " followers"}
                          </DSText>
                        </div>
                        <div className="social3" onClick={handleFollowingPopupToggle} style={{ cursor: 'pointer' }}>
                          <DSText fontSize="1.1em">
                            {profileData.following.length}
                            {" following"}
                          </DSText>
                        </div>
                      </div>
                      <div>
                        {userData && uid === userData.id
                          ? (
                            <button className="editProfileButtonMobile" onClick={handleEditProfile}>
                              <ManageAccountsIcon className="editProfileIcon" />
                              {" "} Edit
                            </button>
                          ) : (
                            <Tooltip title="Coming Soon!" slotProps={{
                              popper: {
                                modifiers: [
                                  {
                                    name: 'offset',
                                    options: {
                                      offset: [0, -6],
                                    },
                                  },
                                ],
                              },
                            }}>
                              <div className="blendButtonMobile">
                                <BlenderOutlinedIcon className="blendIcon" />
                                {" "} Blend
                              </div>
                            </Tooltip>
                          )
                        }
                        {userData && uid === userData.id ? (
                          <button className="createButton" onClick={handleCreateButtonClick}>
                            <AddCircleIcon className="createIcon" />
                            {" "} Create
                          </button>
                        ) : (
                          <button className="followButton" onClick={handleFollowUnfollowClick}>
                            {userData && profileData.followers && profileData.followers.includes(userData.id) ? (
                              <PersonRemoveAlt1Icon className="followIcon" />
                            ) : (
                              <PersonAddAlt1Icon className="followIcon" />
                            )}
                            {" "} {userData && profileData.followers && profileData.followers.includes(userData.id) ? 'Unfollow' : 'Follow'}
                          </button>
                        )}
                        {navigator.canShare && isMobile
                          ? (
                            <button className="shareProfileButton" onClick={() => handleProfileShare(profileData)}>
                              <ShareIcon className="shareProfileIcon" />
                              {" "} Share
                            </button>
                          ) : (
                            <button
                              className="shareProfileButton"
                              onClick={handleCopyLink}
                              onMouseOver={() => setIsHover(true)}
                              onMouseLeave={() => { setIsHover(false); setFlag(false) }}>
                              <LinkIcon className="shareProfileIcon" />
                              {" "} {flag && isHover ? "Copied" : "Copy link"}
                            </button>
                          )
                        }
                      </div>
                    </div>
                  </div>
                  {userData && uid === userData.id ? (
                    <div className="bioContainer">
                      <div className="bioHeading">About</div>
                      <TextField
                        id="standard-multiline-static"
                        value={profileBio}
                        multiline
                        variant="standard"
                        className="collapsibleText"
                        onClick={handleBioClick}
                        onChange={handleBioChange}
                        onBlur={handleBioBlur}
                        sx={{
                          marginTop: "7px !important",
                          padding: "0px !important",
                          background: "transparent !important",
                          width: "100% !important"
                        }}
                        InputProps={{
                          spellCheck: 'false',
                          disableUnderline: true,
                          sx: {
                            padding: "0px !important",
                            fontFamily: "'Outfit', sans-serif !important",
                            fontSize: "0.97em !important",
                            lineHeight: "1.32em !important",
                            color: "#e2e2e2 !important"
                          },
                        }} />
                    </div>
                  ) : profileData.bio ? (
                    <div className="bioContainer">
                      <div className="bioHeading">About</div>
                      <Linkify options={{ defaultProtocol: "https", target: "_blank" }}>
                        <div className="bioText">
                          {profileBio}
                        </div>
                      </Linkify>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {userData && uid === userData.id && (
                  <div className="badgesContainer">
                    <div className="home-tab-heading home-tab-heading-active">
                      Badges
                      <MilitaryTechIcon className="home-tab-icon" />
                    </div>
                    <div>
                      <DSText fontSize="0.97em" lineHeight="1.32em" marginTop="10px" marginBottom="10px">
                        You do not have any badges yet.<br />Participate in a challenge to earn one!
                      </DSText>
                      <button className="ongoingChallengeButton" onClick={() => navigate("/challenge")}>
                        <SportsKabaddiIcon className="ongoingChallengeIcon" />
                        {" "} Latest Challenge
                      </button>
                      <button className="allChallengesButton" onClick={() => navigate("/challenge/all")}>
                        <ArchiveIcon className="allChallengesIcon" />
                        {" "} All Challenges
                      </button>
                    </div>
                  </div>
                )}
                {userData && uid === userData.id ? (
                  <div style={{ marginTop: "35px" }}>
                    <div
                      className={`home-tab-heading ${!isLikedTab && "home-tab-heading-active"}`}
                      onClick={() => setIsLikedTab(false)}
                      style={{
                        "--hover-color": isLikedTab ? "#e5e5e5" : "fff",
                        "--hover-cursor": userData ? "pointer" : "default"
                      }}>
                      Palette <PaletteIcon className="home-tab-icon" />
                    </div>
                    <div
                      className={`home-tab-heading ${isLikedTab && "home-tab-heading-active"}`}
                      onClick={() => setIsLikedTab(true)}
                      style={{
                        "--hover-color": !isLikedTab ? "#e5e5e5" : "fff",
                        "--hover-cursor": "pointer"
                      }}>
                      Liked <FolderSpecialIcon className="home-tab-icon" />
                    </div>
                  </div>
                ) : (
                  <div className="home-tab-heading home-tab-heading-active" style={{ marginTop: "35px" }}>
                    Palette
                    <PaletteIcon className="home-tab-icon" />
                  </div>
                )}
                {!isLikedTab ? (
                  <>
                    <PaletteTab
                      uid={uid}
                      userData={userData}
                      likedLists={likedLists}
                      setLikedLists={setLikedLists}
                      allListData={allListData}
                      setAllListData={setAllListData}
                      flagArray={flagArray}
                      setFlagArray={setFlagArray}
                      isHoverArray={isHoverArray}
                      setIsHoverArray={setIsHoverArray} />
                    {allListData && userData && uid === userData.id && (
                      <>
                        {allListData.length === 0 ? (
                          <DSText marginTop="5px">To get started, choose one of the following prompts:</DSText>
                        ) : (
                          <div className="home-tab-subheading">Need Inspiration?</div>
                        )}
                        <RandomList userData={userData} allListData={allListData} />
                        {allListData.length === 0 && (
                          <div style={{ marginTop: "-15px", marginBottom: "10px" }}>
                            <DSText marginBottom="-12px">Or if you have something in mind already:</DSText>
                            <button className="createButtonBig" onClick={handleCreateButtonClick}>
                              <AddCircleIcon className="createIcon" />
                              {" "} Create Custom List
                            </button>
                          </div>
                        )}
                        {allListData.length > 0 &&
                          <>
                            <div className="home-tab-subheading">Show off your Palette 🎨</div>
                            <ShareIcons username={username} />
                          </>
                        }
                      </>
                    )}
                  </>
                ) : (
                  <LikedTab
                    userData={userData}
                    likedLists={likedLists}
                    setLikedLists={setLikedLists} />
                )}
                {userData && uid === userData.id &&
                  <div className="logoutButton" onClick={() => setLogOutDialogOpen(true)} style={{ marginTop: isLikedTab ? "0px" : "30px" }}>
                    <LogoutIcon className="logoutIcon" />
                    {" "} Log Out
                  </div>
                }
              </>
              <ProfileShareRectangle profileImage={profileImage} profileUsername={username} profileFirstName={profileData.firstName} profileLastName={profileData.lastName} publicListLength={publicListLength} followersLength={profileData.followers.length} />
              <ProfileShareSquare profileImage={profileImage} profileUsername={username} profileFirstName={profileData.firstName} profileLastName={profileData.lastName} publicListLength={publicListLength} followersLength={profileData.followers.length} />
            </div>
            <FeatureRequestForm />
          </DSContainer>
          <DSConfirmDialog
            title={"Log Out"}
            text={"Are you sure you want to log out?"}
            open={logOutDialogOpen}
            setOpen={setLogOutDialogOpen}
            setStatus={setLogOutStatus}
            confirmButtonText={confirmButtonText}
          />
          {isFollowersPopupOpen && <DSUserModal onClose={handleFollowersPopupToggle} listOfUsers={profileData.followers} listType="Followers" isViewerOwner={userData && uid === userData.id} />}
          {isFollowingPopupOpen && <DSUserModal onClose={handleFollowingPopupToggle} listOfUsers={profileData.following} listType="Following" isViewerOwner={userData && uid === userData.id} />}
        </>
      ) : (
        <DSContainer>
          <div className="circularProgress">
            <CircularProgress />
            <DSText fontSize="1.2em" color="white" boldVar="400" marginBottom="15px">
              Loading...
            </DSText>
          </div>
        </DSContainer>
      )}
    </>
  );
};

export default HomePage;