import React, { useState, useEffect } from 'react';
import { db } from "../../../firebase";
import { collection, query, where, getDocs } from 'firebase/firestore';
import { chunk } from 'lodash';
import { getListScoreFromListData, getTextForListOfCategory } from '../../../Utils';
import DSText from '../../../DSSystem/DSText';
import ListCard from '../../ListCard';
import FeatureRequestForm from '../../FeatureRequestForm';

const LikedTab = ({ userData, likedLists, setLikedLists }) => {
    const [allListData, setAllListData] = useState([]);
    const [hoveredId, setHoveredId] = useState(null);
    const [flagArray, setFlagArray] = useState([]);
    const [isHoverArray, setIsHoverArray] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [filteredListData, setFilteredListData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const categories = ['All', 'Movies', 'TV Shows', 'Books', 'Video Games', 'Web Pages', 'Freestyle'];

    useEffect(() => {
        const fetchLikedLists = async () => {
            if (likedLists.length === 0) {
                setIsLoading(false);
                return;
            }
            try {
                const querySnapshots = await Promise.all(
                    chunk(likedLists, 30).map((likedListsBatch) => {
                        return getDocs(query(collection(db, 'lists'), where('id', 'in', likedListsBatch)));
                    })
                );
                const userDocs = querySnapshots.flatMap((querySnapshot) => querySnapshot.docs).slice()
                    .sort((a, b) => {
                        if (getListScoreFromListData(a.data()) < getListScoreFromListData(b.data())) {
                            return 1;
                        }
                        return -1;
                    })
                    .map(doc => doc.data());
                setAllListData(userDocs);
                setFlagArray(Array(userDocs.length).fill(false));
                setIsHoverArray(Array(userDocs.length).fill(false));
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching user lists:', error.message);
            }
        }

        fetchLikedLists();
    }, [likedLists]);

    useEffect(() => {
        if (!userData || allListData.length === 0) {
            return;
        }
        const visibleListData = allListData.filter(listData => (
            listData.isPublic || (listData.memberId && userData && listData.memberId.includes(userData.id))
        ));
        setFilteredListData(selectedCategory === 'All'
            ? visibleListData
            : visibleListData.filter(listData => listData.category === selectedCategory));
        setIsLoading(false);
    }, [userData, allListData, selectedCategory])

    return (
        <>
            <div className="category-button-container">
                {categories.map(category => (
                    <button
                        key={category}
                        className={`category-button category-button-selected-${selectedCategory === category}`}
                        onClick={() => setSelectedCategory(category)}
                    >
                        {category}
                    </button>
                ))}
            </div>
            {isLoading ? (
                <DSText marginTop="15px" marginBottom="40px">Loading...</DSText>
            ) : (
                <>
                    {filteredListData.length > 0 ? (
                        <div className="listCardContainer">
                            {
                                filteredListData.map((listData, index) => (
                                    <ListCard
                                        key={listData.id}
                                        listData={listData}
                                        userData={userData}
                                        likedLists={likedLists}
                                        setLikedLists={setLikedLists}
                                        setAllListData={setAllListData}
                                        index={index}
                                        hoveredId={hoveredId}
                                        setHoveredId={setHoveredId}
                                        flagArray={flagArray}
                                        setFlagArray={setFlagArray}
                                        isHoverArray={isHoverArray}
                                        setIsHoverArray={setIsHoverArray}
                                    />
                                ))
                            }
                        </div>
                    ) : (
                        <DSText marginTop="15px" marginBottom="40px">No liked {getTextForListOfCategory(selectedCategory, true)} found.</DSText>
                    )}
                    {userData && <FeatureRequestForm />}
                </>
            )}
        </>
    );
};

export default LikedTab;
