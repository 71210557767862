import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { doc, getDoc, updateDoc, arrayUnion, setDoc, deleteDoc, arrayRemove, runTransaction, collection, query, where, getDocs, increment } from 'firebase/firestore';
import { db } from '../../../firebase';
import { v4 as uuidv4 } from 'uuid';
import queryString from 'query-string';
import axios from "axios";
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import AddCommentIcon from '@mui/icons-material/AddComment';
import DSComment from '../../../DSSystem/DSComment';
import './styles.css';

const ListComments = ({ userData, commentSectionId, setCommentCount, listId, ownerData }) => {
  const location = useLocation();
  const [currComment, setCurrComment] = useState('');
  const [commentEvents, setCommentEvents] = useState(null);
  const [commentsData, setCommentsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { commentId } = queryString.parse(location.search);

  useEffect(() => {
    const fetchOrCreateCommentSection = async () => {
      try {
        const commentSectionRef = doc(db, 'masterListComments', commentSectionId);
        const commentSectionSnap = await getDoc(commentSectionRef);
        if (commentSectionSnap.exists()) {
          setCommentEvents(commentSectionSnap.data().events || []);
        } else {
          await setDoc(commentSectionRef, { id: commentSectionId, events: [] });
          setCommentEvents([]);
        }
      } catch (error) {
        console.error('Error fetching or creating comment section:', error);
      }
    };

    fetchOrCreateCommentSection();
  }, [commentSectionId]);

  useEffect(() => {
    const fetchComments = async () => {
      const commentPromises = commentEvents.map(async (eventId) => {
        const eventRef = doc(db, 'listLevelComments', eventId);
        const eventSnap = await getDoc(eventRef);
        return eventSnap.exists() ? eventSnap.data() : null;
      });
      const allCommentsData = await Promise.all(commentPromises);
      const validCommentsData = allCommentsData.filter(comment => comment !== null);
      const sortedComments = validCommentsData.sort((a, b) => b.timestamp - a.timestamp);
      setCommentsData(sortedComments);
      setCommentCount(sortedComments.length);
      setIsLoading(false);
    };

    if (commentEvents !== null) {
      if (commentEvents.length > 0) {
        fetchComments();
      } else {
        setCommentsData([]);
        setCommentCount(0);
        setIsLoading(false);
      }
    }
  }, [isLoading, commentEvents, setCommentCount]);

  const handleSubmit = async () => {
    if (!currComment.trim()) return;

    try {
      const newCommentId = uuidv4();
      const newCommentRef = doc(db, 'listLevelComments', newCommentId);
      await setDoc(newCommentRef, {
        id: newCommentId,
        userId: userData.id,
        message: currComment,
        timestamp: Date.now(),
        events: []
      });

      const commentRef = doc(db, 'masterListComments', commentSectionId);
      await updateDoc(commentRef, {
        events: arrayUnion(newCommentId)
      });

      setCommentEvents(prevEvents => [...prevEvents, newCommentId]);
      setCurrComment('');

      // Notification logic
      const notifOwnerData = ownerData.filter(item => item.id !== userData.id);
      const listRef = doc(db, 'lists', listId)
      const docSnap = await getDoc(listRef);
      let listName;
      if (docSnap.exists()) {
        const currSnap = docSnap.data();
        listName = currSnap.title;
        let listNotification;
        if (!currSnap.hasOwnProperty("notifications")) {
          listNotification = uuidv4();
          await updateDoc(listRef, {
            notifications: listNotification
          })
          await setDoc(doc(db, "listNotifications", listNotification), {
            id: listNotification,
            events: []
          });
        } else {
          listNotification = currSnap.notifications;
        }
        const listNotificationsRef = doc(db, 'listNotifications', listNotification)
        const eventUUID = uuidv4()
        const newNotificationEvent = {
          id: eventUUID,
          type: "listComment",
          createdAt: Date.now(),
          userId: userData.id,
          listId: listId,
          commentId: newCommentId,
          comment: currComment
        }
        await setDoc(doc(db, "notifications", eventUUID), newNotificationEvent)
        await updateDoc(listNotificationsRef, {
          events: arrayUnion(eventUUID),
        });
      } else {
        return;
      }

      // Unseen Notifications Count
      for (let i = 0; i < notifOwnerData.length; i++) {
        const ownerDocRef = doc(db, 'members', notifOwnerData[i].email);
        await runTransaction(db, async (transaction) => {
          const ownerDoc = await transaction.get(ownerDocRef);
          const notificationsUnseen = ownerDoc.data()?.notificationsUnseen;
          if (notificationsUnseen !== undefined) {
            // If notificationsUnseen exists, increment it by 1
            transaction.update(ownerDocRef, { notificationsUnseen: notificationsUnseen + 1 });
          } else {
            // If notificationsUnseen does not exist, set it to 1
            transaction.set(ownerDocRef, { notificationsUnseen: 1 }, { merge: true });
          }
        });
      }

      // Send email to list owners
      for (let i = 0; i < notifOwnerData.length; i++) {
        const ownerDocRef = doc(db, 'members', notifOwnerData[i].email);
        const ownerSnap = await getDoc(ownerDocRef);
        if (ownerSnap.exists()) {
          const ownerData = ownerSnap.data();
          let email = ownerData.email;
          let subject = "Your list has a new comment on Palette!";
          let template = "New List Comment";
          let variables = {
            userFirstName: ownerData.firstName,
            listName: listName === "" ? "Untitled List" : listName,
            link: window.location.origin + "/list/" + listId,
            commenterFirstName: userData.firstName,
            commenterLink: window.location.origin + "/" + userData.username,
            commentLink: window.location.origin + "/list/" + listId + "?commentId=" + newCommentId
          };
          try {
            await axios.post(`/api/email`, { email, subject, template, variables });
          } catch (error) {
            console.error('Error sending email:', error.message);
          }
        }
      }
    } catch (error) {
      console.error('Error submitting comment:', error);
    }
  };

  const handleCommentDeletion = async (commentId) => {
    const commentRef = doc(db, 'listLevelComments', commentId);
    await deleteDoc(commentRef);

    const commentSectionRef = doc(db, 'masterListComments', commentSectionId);
    await updateDoc(commentSectionRef, {
      events: arrayRemove(commentId)
    });

    // Notification logic
    const notifOwnerData = ownerData.filter(item => item.id !== userData.id);
    const notificationRef = collection(db, "notifications");
    const deleteQuery = query(notificationRef,
      where("commentId", "==", commentId),
      where("listId", "==", listId),
      where("type", "==", "listComment")
    );
    getDocs(deleteQuery).then(async (querySnapshot) => {
      if (!querySnapshot.empty) {
        const firstDoc = querySnapshot.docs[0];
        for (let i = 0; i < notifOwnerData.length; i++) {
          if (notifOwnerData[i].hasOwnProperty("notificationsTabLastSeenAt") && firstDoc.data().createdAt > notifOwnerData[i].notificationsTabLastSeenAt) {
            const ownerDocRef = doc(db, 'members', notifOwnerData[i].email);
            await updateDoc(ownerDocRef, {
              notificationsUnseen: increment(-1),
            });
          }
        }
        deleteDoc(doc(db, "notifications", firstDoc.id))
          .catch((error) => {
            console.error("Error removing document: ", error);
          });
        const listRef = doc(db, 'lists', listId);
        const listSnapshot = await getDoc(listRef);
        if (!listSnapshot.empty) {
          const listDataFromDB = listSnapshot.data();
          if (listDataFromDB.hasOwnProperty("notifications")) {
            const listNotifications = listDataFromDB.notifications;
            const notificationDocRef = doc(db, 'listNotifications', listNotifications);
            await updateDoc(notificationDocRef, {
              events: arrayRemove(firstDoc.id),
            });
          }
        }
      }
    }).catch((error) => {
      console.error("Error getting documents: ", error);
    });

    setCommentEvents(prevEvents => prevEvents.filter(eventId => eventId !== commentId));
  }

  const handleCommentEditing = async (commentId, newMessage) => {
    const editTimestamp = Date.now();
    const commentRef = doc(db, 'listLevelComments', commentId);
    await updateDoc(commentRef, {
      message: newMessage,
      edited: true,
      timestamp: editTimestamp
    });

    // Notification logic
    const notificationRef = collection(db, "notifications");
    const editQuery = query(notificationRef,
      where("commentId", "==", commentId),
      where("listId", "==", listId),
      where("type", "==", "listComment")
    );
    getDocs(editQuery).then(async (querySnapshot) => {
      if (!querySnapshot.empty) {
        const firstDoc = querySnapshot.docs[0];
        await updateDoc(doc(db, "notifications", firstDoc.id), {
          comment: newMessage
        })
      }
    }).catch((error) => {
      console.error("Error getting documents: ", error);
    });

    const newCommentsData = prevComments => prevComments.map(comment => {
      if (comment.id === commentId) {
        return {
          ...comment,
          message: newMessage,
          edited: true,
          timestamp: editTimestamp
        };
      }
      return comment;
    }).sort((a, b) => b.timestamp - a.timestamp);
    setCommentsData(newCommentsData);
    setCommentCount(newCommentsData.length);
  }

  return (
    <>
      <div>
        {!isLoading
          ? (
            <>
              {commentsData.length === 0 && <p>No comments yet. {!userData && "Log in to comment!"}</p>}
              {commentsData.map((commentData) => (
                <DSComment
                  key={commentData.id}
                  userData={userData}
                  commentData={commentData}
                  handleCommentDeletion={handleCommentDeletion}
                  handleCommentEditing={handleCommentEditing}
                  scrollTo={commentId && commentId === commentData.id}
                />
              ))}
              {commentId && !commentsData.some(comment => comment.id === commentId) &&
                <DSComment
                  key={commentId}
                  commentData={{ id: commentId, message: 'This comment has been deleted.', edited: false, timestamp: Date.now() }}
                  handleCommentDeletion={handleCommentDeletion}
                  handleCommentEditing={handleCommentEditing}
                  scrollTo={true}
                  deleted={true}
                />
              }
            </>
          ) : (
            <>
            </>
          )
        }
      </div>
      {userData &&
        <>
          <div className="listCommentInputContainer">
            <Avatar alt={userData.username} src={userData.profileImage} sx={{ width: 50, height: 50, marginTop: '5px' }} />
            <TextField
              id="standard-multiline-static"
              value={currComment}
              onChange={(e) => setCurrComment(e.target.value)}
              multiline
              variant="standard"
              minRows={2}
              sx={{ padding: '0px !important' }}
              InputProps={{
                spellCheck: 'false',
                disableUnderline: true,
                sx: {
                  fontFamily: "'Outfit', sans-serif !important",
                  fontSize: '0.93em',
                  padding: '10px 15px !important',
                  backgroundColor: '#eee !important',
                  borderRadius: '5px'
                },
              }}
            />
          </div>
          <div style={{ textAlign: 'right', marginTop: '10px' }} onClick={handleSubmit}>
            <button className="addCommentButton">
              <AddCommentIcon className="addCommentIcon" />
              Submit
            </button>
          </div>
        </>}
    </>
  );
};

export default ListComments;