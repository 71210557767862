import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { getUsernameFromUserId } from '../../../Utils';

const HomeRoutingPage = () => {
  const requireEmailVerification = process.env.REACT_APP_REQUIRE_EMAIL_VERIFICATION;
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuthStateChange = async () => {
      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (user) {
          if (user.emailVerified || requireEmailVerification === "false") {
            const username = await getUsernameFromUserId(user.uid);
            navigate(`/${username}`);
          } else {
            navigate("/login");
          }
        } else {
          navigate(`/`);
        }
      });
      return () => unsubscribe();
    };

    handleAuthStateChange();
  }, [navigate, requireEmailVerification]);

  return null;
};

export default HomeRoutingPage;