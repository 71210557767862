import React, { useState, useEffect } from 'react';
import { db } from "../../../firebase";
import { collection, query, where, getDocs } from 'firebase/firestore';
import ListCard from '../../ListCard';
import './styles.css';

const LandingPageExplore = () => {
  const [allListData, setAllListData] = useState([]);
  const [hoveredId, setHoveredId] = useState(null);
  const [flagArray, setFlagArray] = useState([]);
  const [isHoverArray, setIsHoverArray] = useState([]);
  const [likedLists, setLikedLists] = useState([]);

  useEffect(() => {
    const listIds = [
      "fde776c2-2d25-4df6-8bb8-a309c5b352d4", "bd546250-dbf5-4617-8a33-e5c495186218", "788dc3ce-252a-42b3-9612-73f66152ac78",
      "4a174944-4f1c-4020-bcd6-958e20f9c024", "10539632-d831-411e-b5b3-6ee0e75e7f8b", "69ebc524-9559-4207-8115-c3da41887d1c"
    ]

    const fetchPublicLists = async () => {
      try {
        const q = query(collection(db, 'lists'), where('id', 'in', listIds));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          let lists = querySnapshot.docs.map(doc => doc.data()).sort((a, b) => (b.score + b.likedBy.length) - (a.score + a.likedBy.length));
          setAllListData(lists);
        }
      } catch (error) {
        console.error('Error fetching lists:', error.message);
      }
    };

    fetchPublicLists();
  }, []);

  return (
    <>
      <div className="landingListCardContainer">
        {allListData.map((listData, index) => (
          <ListCard
            className="landingListCard"
            key={listData.id}
            listData={listData}
            hoveredId={hoveredId}
            setHoveredId={setHoveredId}
            flagArray={flagArray}
            setFlagArray={setFlagArray}
            isHoverArray={isHoverArray}
            setIsHoverArray={setIsHoverArray}
            likedLists={likedLists}
            setLikedLists={setLikedLists}
            isInGrid={true}
            index={index}
          />
        ))}
      </div>
    </>
  );
};

export default LandingPageExplore;
