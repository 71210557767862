// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { setPersistence } from "firebase/auth";
import { browserLocalPersistence } from "firebase/auth";
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC2bvtCH9pyssGgoZFkWu54MYTF4bfG0GM",
  authDomain: "list-curation.firebaseapp.com",
  projectId: "list-curation",
  storageBucket: "list-curation.appspot.com",
  messagingSenderId: "709948789903",
  appId: "1:709948789903:web:5e72096f915b94a7510cfa",
  measurementId: "G-VSVLDDD0B7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
export { auth };

setPersistence(auth, browserLocalPersistence);

const db = getFirestore();
export { db };

const storage = getStorage(app);
export { storage };