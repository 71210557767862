import React, { useState } from 'react';
import { formatDate, getListImageFromEntryData, addListEntry, dislikeRecommendationForList } from '../../../../Utils';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import ThumbDown from '@mui/icons-material/ThumbDown';
import RenderDate from '../CardComponents/RenderDate';
import RenderChallengeInfo from '../CardComponents/RenderChallengeInfo';
import CardCollapsible from '../CardComponents/CardCollapsible';
import DSText from '../../../../DSSystem/DSText';
import DSListModal from '../../../../DSSystem/DSModal/DSListModal';
import InfoIcon from '@mui/icons-material/Info';
import DSSynopsisModal from '../../../../DSSystem/DSModal/DSSynopsisModal';
import LaunchIcon from '@mui/icons-material/Launch';
import MoveCardsUpDown from '../../MoveCardsUpDown';
import './styles.css';

const MovieCard = ({ index, entryData, userData, ownerIds, handleRemoveEntry, showAnnotations, setSelectedEntriesData, listId, isRecommendation, isChallengeItem, setUpdateTrigger, isInGroupList, selectedEntriesData, isCurrentlyFiltered }) => {
    const userExists = userData && ownerIds;
    const userOwnsList = userExists && ownerIds.includes(userData.id);
    const userDoesNotOwnList = userExists && !ownerIds.includes(userData.id);
    const [isAddElementModalOpen, setIsAddElementModalOpen] = useState(false);
    const [synopsisModal, setSynopsisModal] = useState(false);

    const addRecommendationToSameList = async (e) => {
        e.preventDefault();
        delete entryData["vote_average"];
        delete entryData["vote_count"];
        const updatedEntryData = {
            ...entryData,
            annotation: "",
            rating: null,
            checked: false,
            checkedTime: null,
            createdTime: Date.now(),
            lastUpdated: Date.now()
        };
        try {
            let addStatus = await addListEntry(listId, updatedEntryData);
            if (addStatus === "success") {
                setSelectedEntriesData(prev => [updatedEntryData, ...prev]);
            }
        } catch (error) {
            console.error('Failed to add entry:', error);
        }
    }

    const addToOtherList = async (e) => {
        e.preventDefault();
        setIsAddElementModalOpen(true);
    }

    const handleCloseModal = () => {
        setIsAddElementModalOpen(false);
    };

    const handleSynopsisModalClose = () => {
        setSynopsisModal(false);
    };

    return (
        <>
                <div>
                    <Card className={`movieCard ${isInGroupList ? 'groupListClass' : ''}`}>
                        {isInGroupList &&
                            <div className="circularBadge">
                                <DSText fontSize="0.85em">{index + 1}</DSText></div>
                        }
                        <CardMedia
                            component="img"
                            alt={entryData.movieName}
                            className="movieCardMedia"
                            image={getListImageFromEntryData(entryData, "Movies")}
                        />
                        <CardContent className="movieCardContent">
                            <DSText fontSize="1.05em" color="#1e1e1e" boldVar="600">
                                <div style={{ paddingRight: userOwnsList ? '15px' : '0px' }}>{entryData.movieName}</div>
                            </DSText>
                            <DSText fontSize="0.85em" color="#1e1e1e" marginTop="8px">
                                Released: {formatDate(entryData.releaseDate)}<br />
                                Runtime: {entryData.runtime !== 0 ? `${entryData.runtime} minutes` : 'Unknown'}
                            </DSText>
                            {entryData.genres &&
                                <div style={{ marginTop: "12px" }}>
                                    {entryData.genres.slice(0, 3).map((genre) => (
                                        <Chip key={genre.id} label={genre.name} size='small'
                                            sx={{
                                                marginBottom: '4px',
                                                marginRight: '4px',
                                                background: '#fafafa',
                                                color: 'black',
                                                border: '1px solid black',
                                                fontFamily: 'Outfit, sans-serif',
                                                fontSize: '0.78em',
                                                padding: '-20px'
                                            }}
                                        />
                                    ))}
                                </div>
                            }
                            {isChallengeItem && <RenderChallengeInfo entryData={entryData} />}
                            {!isChallengeItem && !isRecommendation && <RenderDate createdTime={entryData.createdTime} addedBy={entryData.addedBy} />}
                            <LaunchIcon
                            className= "linkIcon"
                            onClick={(event) => {
                                event.preventDefault();
                                window.open(`https://www.themoviedb.org/movie/${entryData.id}`, '_blank');
                            }} 
                            />
                            {(entryData.synopsis || entryData.overview) && <InfoIcon
                                className="synopsisIcon"
                                onClick={(event) => {
                                    event.preventDefault();
                                    setSynopsisModal(true)
                                }}
                            />}

                            {userDoesNotOwnList && <AddIcon className="addIcon" onClick={addToOtherList} />}
                            {userOwnsList && !isRecommendation &&
                                <>
                                    <AddIcon className="addIconOnOwnerList" onClick={addToOtherList} />
                                    <ClearIcon
                                        className="deleteIcon"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleRemoveEntry(index)
                                        }}
                                    />
                                </>
                            }
                            {!isCurrentlyFiltered && <MoveCardsUpDown
                                userOwnsList={userOwnsList}
                                isRecommendation={isRecommendation}
                                setSelectedEntriesData={setSelectedEntriesData}
                                index={index}
                                selectedEntriesData={selectedEntriesData}
                                listId={listId}
                            />}
                            {userOwnsList && isRecommendation && setSelectedEntriesData && <AddIcon className="addIcon" onClick={addRecommendationToSameList} />}
                            {userOwnsList && isRecommendation && setSelectedEntriesData && <ThumbDown className="thumbsDownIcon"
                                onClick={(event) => {
                                    dislikeRecommendationForList(event, listId, entryData.id);
                                    setUpdateTrigger(prev => !prev);
                                }} />
                            }
                        </CardContent>
                    </Card>
                </div>
            <CardCollapsible
                entryData={entryData}
                index={index}
                isEditable={userOwnsList}
                hasRating={entryData.rating && entryData.rating !== 0}
                hasAnnotation={entryData.annotation && entryData.annotation !== ""}
                showAnnotations={showAnnotations}
                setSelectedEntriesData={setSelectedEntriesData}
                listId={listId}
            />
            {isAddElementModalOpen && (
                <DSListModal
                    onClose={handleCloseModal}
                    listOfLists={userData.lists}
                    modalType={"Add to Your List"}
                    entryData={entryData}
                    entryCategory={"Movies"}
                />
            )}
            {synopsisModal && (
                <DSSynopsisModal
                    onClose={handleSynopsisModalClose}
                    synopsis={entryData.synopsis || entryData.overview}
                    name={entryData.movieName}
                />
            )}
        </>
    );
};

export default MovieCard;