import React from 'react';
import { PopupWidget } from 'react-calendly';
import "./styles.css";

const FeatureRequestForm = ({ id = "featureRequest" }) => {
  return (
    <div className="popup-widget" id={id}>
      <PopupWidget
        url="https://tally.so/r/wMRWj0"
        rootElement={document.getElementById('root')}
        text="Feature Request / Bug"
        textColor="#ffffff"
        color="#444444"
      />
    </div>
  );
};

export default FeatureRequestForm;
