import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { handleLike, handleUnlike, handleShare, scrollToTargetDiv } from '../../../Utils';
import Tooltip from '@mui/material/Tooltip';
import ShareIcon from '@mui/icons-material/Share';
import LinkIcon from '@mui/icons-material/Link';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DnsIcon from '@mui/icons-material/Dns';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import CommentIcon from '@mui/icons-material/Comment';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import DSUserModal from '../../../DSSystem/DSModal/DSUserModal';
import './styles.css';

const ListButtons = ({
    listId, listName, listCategory, isListPublic, selectedEntriesData, ownerIds, ownerData,
    userData, userLikedLists, setUserLikedLists, listLikedBy, setListLikedBy, listScore, getListScoreFromListData,
    itemsDivRef, commentsDivRef, commentCount
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [flag, setFlag] = useState(false);
    const [isHover, setIsHover] = useState(false);
    const [isLikePopupOpen, setIsLikePopupOpen] = useState(false);

    const handleLikePopupToggle = () => {
        setIsLikePopupOpen(!isLikePopupOpen);
    };

    const handleCopyLink = () => {
        setFlag(true);
        const currListPath = "/list/" + String(listId)
        const urlToCopy = window.location.origin + currListPath;
        navigator.clipboard.writeText(urlToCopy)
            .catch((error) => {
                console.error('Error copying link to clipboard:', error);
            });
    };

    return (
        <>
            <div className="listButtonContainer">
                <div className="likeButton">
                    <div className="likeIcon">
                        {(userData && userLikedLists && userLikedLists.includes(listId)) ? (
                            <FavoriteIcon className="likeIcon" onClick={() => {
                                if (userData && userLikedLists && userLikedLists.includes(listId)) {
                                    handleUnlike(listId, userData, userLikedLists, setUserLikedLists, ownerData, null, listLikedBy, setListLikedBy);
                                } else {
                                    handleLike(listName, listId, userData, userLikedLists, setUserLikedLists, location, navigate, ownerData, null, listLikedBy, setListLikedBy)
                                }
                            }} />
                        ) : (
                            <FavoriteBorderIcon className="likeIcon" onClick={() => {
                                if (userData && userLikedLists && userLikedLists.includes(listId)) {
                                    handleUnlike(listId, userData, userLikedLists, setUserLikedLists, ownerData, null, listLikedBy, setListLikedBy);
                                } else {
                                    handleLike(listName, listId, userData, userLikedLists, setUserLikedLists, location, navigate, ownerData, null, listLikedBy, setListLikedBy);
                                }
                            }} />)}
                    </div>
                    <div onClick={handleLikePopupToggle} className="likeText">
                        {getListScoreFromListData({ likedBy: listLikedBy, score: listScore })} {getListScoreFromListData({ likedBy: listLikedBy, score: listScore }) === 1 ? "like " : "likes"}
                    </div>
                </div>
                <button className="listButton itemsButton" onClick={() => scrollToTargetDiv(itemsDivRef)}>
                    <DnsIcon className='itemsIcon' />
                    {" "} {selectedEntriesData.length} {selectedEntriesData.length === 1 ? "item" : "items"}
                </button>
                <button className="listButton commentButton" onClick={() => scrollToTargetDiv(commentsDivRef)}>
                    <CommentIcon className="commentIcon" />
                    {" "} {commentCount} {commentCount === 1 ? "comment" : "comments"}
                </button>
                {isListPublic && (navigator.canShare && isMobile ? (
                    <button className="listButton shareButton" onClick={() => {
                        handleShare({ id: listId, name: listName, category: listCategory, memberId: ownerIds })
                    }}>
                        <ShareIcon className="shareIcon" />
                        Share
                    </button>
                ) : (
                    <button
                        className="listButton shareButton"
                        onClick={handleCopyLink}
                        onMouseOver={() => setIsHover(true)}
                        onMouseLeave={() => { setIsHover(false); setFlag(false) }}>
                        <LinkIcon className="shareIcon" />
                        {flag && isHover ? "Copied" : "Copy link"}
                    </button>
                ))}
                {!(ownerIds && userData && ownerIds.includes(userData.id)) &&
                    <Tooltip title="Coming Soon!" slotProps={{
                        popper: {
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, -6],
                                    },
                                },
                            ],
                        },
                    }}>
                        <button className="listButton requestCollaborationButton">
                            <GroupAddIcon className="requestCollaborationIcon" />
                            Collaborate
                        </button>
                    </Tooltip>
                }
            </div>
            {isLikePopupOpen && (
                <DSUserModal onClose={handleLikePopupToggle} listOfUsers={listLikedBy} listType="Likes" boostedLikes={listScore} />
            )}
        </>
    )
};

export default ListButtons;