import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { db, auth } from '../../../firebase.js';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { onAuthStateChanged, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import { Helmet } from 'react-helmet';
import { getStorageUrlFromGooglePhotoUrl, getUsernameFromUserId } from '../../../Utils/index.js';
import queryString from 'query-string';
import DSContainer from '../../../DSSystem/DSContainer';
import DSText from '../../../DSSystem/DSText';
import DSNavbar from '../../../DSSystem/DSNavbar';
import LoginEmailForm from './LoginEmail/index.js';
import './styles.css';

const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { type, redirectTo } = queryString.parse(location.search);
  const [loginType, setLoginType] = useState(!type ? 'main' : type);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        let username = await getUsernameFromUserId(user.uid)
        navigate(!redirectTo ? `/${username}` : redirectTo);
      }
      setLoginType(!type ? 'main' : type);
    });

    return () => unsubscribe();
  }, [navigate, redirectTo, type]);

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const userRef = doc(db, "members", user.email);
      const docSnap = await getDoc(userRef);
      if (!docSnap.exists()) {
        await signOut(auth);
        navigate(!redirectTo ? "/signup?type=google" : "/signup?type=google&redirectTo=" + redirectTo);
      } else {
        const userData = docSnap.data();
        if (!userData.profileImage) {
          await updateDoc(userRef, {
            profileImage: await getStorageUrlFromGooglePhotoUrl(user.email, user.photoURL)
          });
        }
      }
    } catch (error) {
      console.error("Error signing in with Google: ", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Login - Palette</title>
        <meta property="og:title" content="Login - Palette" />
        <link rel="canonical" href={"https://palette.build" + location.pathname} />
        <meta property="og:url" content={"https://palette.build" + location.pathname} />
        <meta property="og:image" content="https://palette.build/landing.png" />
        <meta property="og:description" content="Curate, share, and discover movies, TV shows, books, and more!" />
        <meta name="keywords" content={`palette, lists, social media, login`} />
      </Helmet>
      <DSNavbar isLoggedIn={false} currentPage={"Login"} />
      <DSContainer>
        <div className="card">
          <DSText fontSize="1.65em" color="#1e1e1e" boldVar="600">Welcome back!</DSText>
          <div className="alternative">Don't have an account? <Link to={!redirectTo ? "/signup" : "/signup?redirectTo=" + redirectTo} className="link">Sign Up</Link></div>
          {loginType !== "email" ? (
            <>
              <DSText fontSize="1em" color="#1e1e1e" boldVar="400" marginTop="25px" marginBottom="8px">Choose option:</DSText>
              <button className="continue-google-button" onClick={signInWithGoogle}>
                <img src="/google-icon-logo.svg" className="continue-icon" alt="Google" />
                Google
              </button>
              <button className="continue-email-button" onClick={() => {
                setLoginType("email");
                navigate(!redirectTo ? "/login?type=email" : redirectTo.includes("signup") ? "/signup?type=email&redirectTo=" + redirectTo : "/login?type=email&redirectTo=" + redirectTo);
              }}>
                <img src="/email-icon-logo.svg" className="continue-icon" alt="Email" />
                Email
              </button>
            </>
          ) : (
            <LoginEmailForm signInWithGoogle={signInWithGoogle} />
          )}
        </div>
      </DSContainer >
    </>
  );
};

export default LoginPage;
