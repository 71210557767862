import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './Components/Landing';
import WaitlistPage from "./Components/AccountManagement/Waitlist";
import SignupPage from "./Components/AccountManagement/Signup";
import LoginPage from './Components/AccountManagement/Login';
import ForgotPassword from './Components/AccountManagement/ForgotPassword'
import AccountUpdate from './Components/AccountManagement/AccountUpdate'
import HomeRoutingPage from './Components/Home/HomeRouting';
import HomePage from "./Components/Home";
import CreateListPage from './Components/CreateList';
import ListPage from './Components/List';
import ExplorePage from './Components/Explore';
import ErrorPage from './Components/Error';
import ActivityPage from './Components/Activity';
import AdminPage from './Components/Admin';
import EditProfile from './Components/AccountManagement/EditProfile';
import BookExplorePage from './Components/Explore/BookExplorePage';
import ChallengePage from './Components/Challenge';
import AllChallengePage from './Components/Challenge/AllChallenges';
import CreateChallengePage from './Components/Challenge/CreateChallenge';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/waitlist" element={<WaitlistPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/edit-profile" element={<EditProfile />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/account" element={<AccountUpdate />} />
        <Route path="/:username" element={<HomePage />} />
        <Route path="/user" element={<HomeRoutingPage />} />
        <Route path="/create" element={<CreateListPage />} />
        <Route path="/list/:listId" element={<ListPage />} />
        <Route path="/explore" element={<ExplorePage />} />
        <Route path="/explore/books" element={<BookExplorePage />} />
        <Route path="/activity" element={<ActivityPage />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/challenges" element={<AllChallengePage />} />
        <Route path="/challenge/all" element={<AllChallengePage />} />
        <Route path="/challenge/create" element={<CreateChallengePage />} />
        <Route path="/challenge/:challengeId?" element={<ChallengePage />} />
        <Route path="/*" element={<ErrorPage code="404" />} />
        <Route path="/404" element={<ErrorPage code="404" />} />
        <Route path="/500" element={<ErrorPage code="500" />} />
      </Routes>
    </Router>
  );
};

export default App;