import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { auth } from "../../../firebase.js";
import { onAuthStateChanged } from "firebase/auth";
import { Helmet } from 'react-helmet';
import { getUsernameFromUserId } from '../../../Utils/index.js';
import queryString from 'query-string';
import SignupEmailForm from './SignupEmail/index.js';
import SignupGoogleForm from './SignupGoogle/index.js';
import DSText from '../../../DSSystem/DSText';
import DSContainer from '../../../DSSystem/DSContainer';
import DSNavbar from '../../../DSSystem/DSNavbar';
import './styles.css';

const SignupPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { type, redirectTo } = queryString.parse(location.search);
  const [signupType, setSignupType] = useState(!type ? 'main' : type);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        let username = await getUsernameFromUserId(user.uid);
        if (username) {
          navigate(!redirectTo ? `/${username}` : redirectTo);
        }
      }
      setSignupType(!type ? 'main' : type);
    });
    return () => unsubscribe();
  }, [navigate, redirectTo, type]);

  return (
    <>
      <Helmet>
        <title>Signup - Palette</title>
        <meta property="og:title" content="Signup - Palette" />
        <link rel="canonical" href={"https://palette.build" + location.pathname} />
        <meta property="og:url" content={"https://palette.build" + location.pathname} />
        <meta property="og:image" content="https://palette.build/landing.png" />
        <meta property="og:description" content="Curate, share, and discover movies, TV shows, books, and more!" />
        <meta name="keywords" content={`palette, lists, social media, signup`} />
      </Helmet>
      <DSNavbar isLoggedIn={false} currentPage={"Signup"} />
      <DSContainer>
        <div className="card">
          <DSText fontSize="1.65em" color="#1e1e1e" boldVar="600">Start curating!</DSText>
          <div className="alternative">Already a user? <Link to={!redirectTo ? "/login" : "/login?redirectTo=" + redirectTo} className="link">Log In</Link></div>
          {signupType === "main" ? (
            <>
              <DSText fontSize="1em" color="#1e1e1e" boldVar="400" marginTop="25px" marginBottom="8px">Choose option:</DSText>
              <button className="continue-google-button" onClick={() => {
                setSignupType("google");
                navigate(!redirectTo ? "/signup?type=google" : "/signup?type=google&redirectTo=" + redirectTo);
              }}>
                <img src="/google-icon-logo.svg" className="continue-icon" alt="Google" />
                Google
              </button>
              <button className="continue-email-button" onClick={() => {
                setSignupType("email")
                navigate(!redirectTo ? "/signup?type=email" : "/signup?type=email&redirectTo=" + redirectTo);
              }}>
                <img src="/email-icon-logo.svg" className="continue-icon" alt="Email" />
                Email
              </button>
            </>
          ) : signupType === "email" ? (
            <SignupEmailForm setSignupType={setSignupType} />
          ) : (
            <SignupGoogleForm />
          )}
        </div>
      </DSContainer>
    </>
  );
};

export default SignupPage;