import React, { useState, useEffect } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import TitleChip from './TitleChip';
import DSText from '../../DSSystem/DSText';
import './styles.css';

const RandomList = ({ userData, allListData }) => {
    const [listTitles, setListTitles] = useState([]);

    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]]; 
        }
        return array;
    };

    useEffect(() => {
        const initialListTitles = [
            // Books
            ["Books I pretend to hate to impress my unintellectual friends", "Books", "1000"],
            ["Books that would get me banned from book clubs if I admitted I love them", "Books", "1001"],
            ["Trip down memory lane: Books that got me into reading", "Books", "1002"],
            ["Fall for you: Best self-love books", "Books", "1003"],
            ["Self-help books that actually work (or I think will work if I read them)", "Books", "1004"],
            ["My definitive book recommendations list", "Books", "1005"],
        
            // Movies
            ["Movies every ambitious person should watch", "Movies", "1006"],
            ["Movies that make me miss my ex", "Movies", "1007"],
            ["Movies that remind me of my special someone", "Movies", "1008"],
            ["Movies that will transport you back to your favorite memories", "Movies", "1009"],
            ["My favorite films of all time", "Movies", "1010"],
            ["Feel-good movies that never fail to make me smile", "Movies", "1011"],
        
            // TV Shows
            ["I can yap about these TV shows 25/8", "TV Shows", "1012"],
            ["TV shows that made my friends hate me (because I haven't watched them yet)", "TV Shows", "1013"],
            ["Why am I watching this again? TV shows with questionable rewatch value", "TV Shows", "1014"],
            ["Hidden gems: TV shows that deserve more hype", "TV Shows", "1015"],
            ["Quiet comfort: Low-key TV shows for peaceful evenings", "TV Shows", "1016"],
        
            // Video Games
            ["Video games I played 'for the plot'", "Video Games", "1017"],
            ["Hidden gems: Indie games that stole my heart", "Video Games", "1018"],
            ["Blast from the past: Childhood games that shaped my gamer identity", "Video Games", "1019"],
            ["Party starters: Essential games for any social gathering", "Video Games", "1020"]
        ];

        const shuffledTitles = shuffleArray([...initialListTitles]);

        const filteredTitles = shuffledTitles.filter(title =>
            !allListData.some(data => data.groupId === title[2])
        );
        setListTitles(filteredTitles);
    }, [allListData]);

    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % listTitles.length);
    };

    const handlePrevious = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + listTitles.length) % listTitles.length);
    };

    return (
        <div className="randomTitleCarousel">
            <div className="randomTitleChips">
                {listTitles.length > 0
                    ? (
                        <>
                            <div className="randomTitleCarouselArrow" onClick={handlePrevious}>
                                <KeyboardArrowLeftIcon />
                            </div>
                            <TitleChip
                                key={listTitles[currentIndex][0]}
                                listTitle={listTitles[currentIndex][0]}
                                listCategory={listTitles[currentIndex][1]}
                                userData={userData}
                                groupId={listTitles[currentIndex][2]}
                            />
                            <div className="randomTitleCarouselArrow" onClick={handleNext}>
                                <KeyboardArrowRightIcon />
                            </div>
                        </>
                    ) : (
                        <DSText color="white" fontSize="16px">No more titles available.</DSText>
                    )
                }
            </div>
        </div>
    );
};

export default RandomList;