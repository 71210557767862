import React, { useState, useEffect, useRef } from 'react';
import { scrollToTargetDiv } from '../../../Utils';
import CommentIcon from '@mui/icons-material/Comment';
import DSText from '../../../DSSystem/DSText';
import MovieCard from '../../List/CategoryCards/MovieCard';
import ShowCard from '../../List/CategoryCards/ShowCard';
import VideoGameCard from '../../List/CategoryCards/VideoGameCard';
import BookCard from '../../List/CategoryCards/BookCard';
import ChallengeComments from '../ChallengeComments';

const ChallengeItemsTab = ({ challengeId, challengeCategory, itemsMap, userData }) => {
    const commentsDivRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [itemsData, setItemsData] = useState([]);

    useEffect(() => {
        if (itemsMap === null) {
            setIsLoading(true);
        } else if (Object.keys(itemsMap).length !== 0) {
            const sortedItemsData = Object.values(itemsMap).sort((a, b) => b.score - a.score);
            setItemsData(sortedItemsData);
        }
        setIsLoading(false);
    }, [itemsMap]);

    return (
        <>
            {isLoading
                ? <DSText marginBottom="40px">Loading...</DSText>
                : <>
                    {itemsData.length > 0
                        ? <div className="listCardContainer">
                            <div className="challengeMessageBox">
                                <DSText lineHeight="1.4em">You shared your opinions and we ran the algorithms! Here is the definitive list as voted by the Palette community.</DSText>
                                <DSText fontSize="0.87em" color="#cfcfcf" marginTop="7px">
                                    <div className="challengeMessageLink" onClick={() => scrollToTargetDiv(commentsDivRef)}>
                                        Discuss in the comments.
                                    </div>
                                </DSText>
                            </div>
                            {itemsData.map((itemData, index) => {
                                if (challengeCategory === "Movies") {
                                    return (
                                        <MovieCard
                                            key={itemData.id}
                                            index={index}
                                            entryData={itemData}
                                            isChallengeItem={true}
                                            isInGroupList={true}
                                        />
                                    );
                                } else if (challengeCategory === "TV Shows") {
                                    return (
                                        <ShowCard
                                            key={itemData.id}
                                            index={index}
                                            entryData={itemData}
                                            isChallengeItem={true}
                                            isInGroupList={true}
                                        />
                                    );
                                } else if (challengeCategory === "Video Games") {
                                    return (
                                        <VideoGameCard
                                            key={itemData.id}
                                            index={index}
                                            entryData={itemData}
                                            isChallengeItem={true}
                                            isInGroupList={true}
                                        />
                                    );
                                } else if (challengeCategory === "Books") {
                                    return (
                                        <BookCard
                                            key={itemData.id}
                                            index={index}
                                            entryData={itemData}
                                            isChallengeItem={true}
                                            isInGroupList={true}
                                        />
                                    );
                                } else {
                                    return (
                                        <></>
                                        // Web pages and freestyle categories not supported in challenges yet
                                    );
                                }
                            })}
                        </div>
                        : <DSText marginBottom="40px">No cards added yet. You can be the first one to take on the challenge!</DSText>
                    }
                    <div className="challengeCommentSection" ref={commentsDivRef}>
                        <div className="challengeSectionTitleContainer">
                            <CommentIcon className="challengeSectionTitleIcon" />
                            <DSText fontSize="1.3em" boldVar="800">Comments</DSText>
                        </div>
                        <ChallengeComments
                            userData={userData}
                            challengeId={challengeId}
                        />
                    </div>
                </>
            }
        </>
    );
};

export default ChallengeItemsTab;
