import React from 'react';
import "./styles.css";

const DSContainer = ({ textAlign, justifyContent, hasNoNav, children }) => {
    const style = {
        textAlign: textAlign || 'left',
        justifyContent: justifyContent || 'center'
    };
    return (
        <div id={`container-${hasNoNav}`} className="container" style={style}>
            {children}
        </div>
    );
}

export default DSContainer;
