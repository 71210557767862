import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { auth } from '../../../firebase.js';
import { onAuthStateChanged, sendPasswordResetEmail, signOut } from "firebase/auth";
import { Helmet } from 'react-helmet';
import { getUsernameFromUserId } from '../../../Utils/index.js';
import DSContainer from '../../../DSSystem/DSContainer';
import DSText from '../../../DSSystem/DSText';
import DSButton from '../../../DSSystem/DSButton';
import DSNavbar from '../../../DSSystem/DSNavbar';
import './styles.css';

const ForgotPassword = () => {
  const requireEmailVerification = process.env.REACT_APP_REQUIRE_EMAIL_VERIFICATION;
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        if (user.emailVerified || requireEmailVerification === "false") {
          let username = await getUsernameFromUserId(user.uid)
          navigate(`/${username}`);
        } else {
          await signOut(auth);
          navigate("/login");
        }
      }
    });

    return () => unsubscribe();
  }, [navigate, requireEmailVerification]);

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setSuccessMessage('');
    setErrorMessage('');

    try {
      await sendPasswordResetEmail(auth, email);
      setSuccessMessage('If you have an account with us, you will receive a password reset email. Please check your inbox.');
    } catch (error) {
      setErrorMessage('Error sending password reset email. Please try again.');
      console.error('Error:', error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Forgot Password - Palette</title>
        <meta property="og:title" content="Forgot Password - Palette" />
        <link rel="canonical" href={"https://palette.build" + location.pathname} />
        <meta property="og:url" content={"https://palette.build" + location.pathname} />
        <meta property="og:image" content="https://palette.build/landing.png" />
        <meta property="og:description" content="Curate, share, and discover movies, TV shows, books, and more!" />
        <meta name="keywords" content={`palette, lists, social media, forgot password`} />
      </Helmet>
      <DSNavbar isLoggedIn={false} />
      <DSContainer>
        <div className="card">
          <form onSubmit={handleForgotPassword}>
            <DSText fontSize="1.65em" color="#1e1e1e" boldVar="600" marginBottom="20px">Forgot Password</DSText>
            <label>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required />
            {successMessage && <div className="successForgot">{successMessage} <Link to="/login" className="link">Login</Link>.</div>}
            {errorMessage && <div className="invalid">{errorMessage}</div>}
            <div style={{ marginTop: "20px" }}><DSButton buttonText="Reset Password" /></div>
          </form>
        </div>
      </DSContainer>
    </>
  );
};

export default ForgotPassword;
