import React, { useEffect, useState } from 'react';
import { convertUTCToLocalTime, getOwnerDataFromOwnerIds } from '../../../../../Utils';
import DSText from '../../../../../DSSystem/DSText';
import OwnerStringWithLink from '../../../../../Utils/OwnerStringWithLength';

const RenderDate = ({ createdTime, addedBy }) => {
  const [ownerData, setOwnerData] = useState([]);

  useEffect(() => {
    if (addedBy) {
      getOwnerDataFromOwnerIds([addedBy])
        .then(data => {
          setOwnerData(data);
        });
    }
  }, [addedBy]);

  const dateText = createdTime ? `on ${convertUTCToLocalTime(createdTime)}` : 'before March 1, 2024';

  return (
    <DSText fontSize="0.6em" color="#808080" marginTop="12px">
      Added {dateText} {ownerData.length > 0 && `by `}
      {ownerData.length > 0 && <OwnerStringWithLink ownerData={ownerData} />}
    </DSText>
  );
};

export default RenderDate;