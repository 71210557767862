import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { db } from '../../firebase';
import { doc, getDoc } from '@firebase/firestore';
import { getOwnerDataFromOwnerIds, handleShare, deleteList, getListImageFromEntryData, getListScoreFromListData, handleLike, handleUnlike, convertUTCToLocalTime } from '../../Utils';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import ClearIcon from '@mui/icons-material/Clear'
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import DnsIcon from '@mui/icons-material/Dns';
import CommentIcon from '@mui/icons-material/Comment';
import ShareIcon from '@mui/icons-material/Share';
import LinkIcon from '@mui/icons-material/Link';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DSText from '../../DSSystem/DSText';
import DSConfirmDialog from '../../DSSystem/DSConfirmDialog';
import DSUserModal from '../../DSSystem/DSModal/DSUserModal';
import ListShareRectangle from '../ShareImages/ListShareRectangle';
import ListShareSquare from '../ShareImages/ListShareSquare';
import OwnerStringWithLink from '../../Utils/OwnerStringWithLength';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import './styles.css';

const ListCard = ({ listData, userData, likedLists, setLikedLists, setAllListData, index, hoveredId, setHoveredId, flagArray, setFlagArray, isHoverArray, setIsHoverArray, isInGrid, isGroupList }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [ownerData, setOwnerData] = useState([]);
  const [commentCount, setCommentCount] = useState(0);
  const [exists, setExists] = useState(true);
  const [isLikePopupOpen, setIsLikePopupOpen] = useState(false);
  const [deleteListDialogOpen, setDeleteListDialogOpen] = useState(false);
  const [deleteListStatus, setDeleteListStatus] = useState(false);
  const [confirmButtonText, setConfirmButtonText] = useState("Confirm");

  useEffect(() => {
    const fetchOwnerData = async () => {
      const ownerDataArray = await getOwnerDataFromOwnerIds(listData.memberId);
      setOwnerData(ownerDataArray);
    };

    fetchOwnerData();
  }, [listData.memberId]);

  useEffect(() => {
    const fetchCommentCount = async () => {
      if (listData.commentId) {
        const commentSectionRef = doc(db, 'masterListComments', listData.commentId);
        const commentSectionSnap = await getDoc(commentSectionRef);
        if (commentSectionSnap.exists()) {
          setCommentCount(commentSectionSnap.data().events.length);
        }
      } else {
        setCommentCount(0);
      }
    }

    fetchCommentCount();
  }, [listData.commentId])

  useEffect(() => {
    const deleteListAfterConfirmation = async () => {
      const stillExists = await deleteList(listData.id);
      setExists(stillExists);
      if (!stillExists) {
        setDeleteListStatus(false);
        setDeleteListDialogOpen(false);
      } else {
        setConfirmButtonText("Retry");
      }
    }

    if (deleteListStatus) {
      deleteListAfterConfirmation();
    }
  }, [deleteListStatus, listData.id])

  const handleLikePopupToggle = () => {
    setIsLikePopupOpen(!isLikePopupOpen);
  };

  const renderIcon = () => {
    if (index === 0) {
      return <EmojiEventsIcon style={{ color: "#d4af37", fontSize: '40px' }} size="large" />;
    } else if (index === 1) {
      return <EmojiEventsIcon style={{ color: "#BCC6CC", fontSize: '40px' }} size="large" />;
    } else if (index === 2) {
      return <EmojiEventsIcon style={{ color: "#cd7f32", fontSize: '40px' }} size="large" />;
    } else {
      return <LabelImportantIcon style={{ color: "#333333", fontSize: '40px' }} size="large" />;
    }
  };

  return (
    <>
      {exists &&
        <>
          <Link to={"/list/" + String(listData.id)} className="link">
            <div>
              <Card className={`${isInGrid ? "listCardInGrid" : "listCard"} ${isGroupList ? "isGroupList" : ""}`}>
                {listData && userData && listData.memberId.includes(userData.id) &&
                  <ClearIcon
                    sx={{
                      width: '0.75em',
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      color: '#1e1e1e',
                      cursor: 'pointer',
                      transition: 'all 0.15s ease-in-out',
                      '&:hover': {
                        backgroundColor: 'transparent !important',
                        color: '#ef00e7 !important'
                      }
                    }}
                    onClick={async (event) => {
                      event.preventDefault();
                      setDeleteListDialogOpen(true);
                    }} />
                }
                {isGroupList &&
                  <div className="trophyBadge">
                    {renderIcon()}
                    {index > 2 ?
                      <div className="badgeText labelText">{index + 1}</div> :
                      <div className="badgeText trophyText">{index + 1}</div>
                    }
                  </div>
                }
                <CardMedia
                  className={`listCardMedia ${isGroupList ? "darkerCardMedia" : ""}`}
                  component="img"
                  alt="List Cover"
                  image={listData.listImage ? listData.listImage : getListImageFromEntryData(listData.data[listData.data.length - 1], listData.category)}
                />
                <CardContent className="listCardContent">
                  <div className='listCardTopRow'>
                    {isGroupList
                      ? (
                        <>
                          <DSText fontSize="1.1em" color="#1e1e1e" boldVar="600" marginBottom="6px" lineHeight="1.15em">{ownerData[0]?.firstName + " " + ownerData[0]?.lastName} </DSText>
                          <DSText fontSize="0.83em" color="#1e1e1e" marginBottom="13px">Created: {convertUTCToLocalTime(listData.createdTime)}</DSText>
                        </>
                      )
                      : (
                        <>
                          <DSText fontSize="1.1em" color="#1e1e1e" boldVar="600" marginBottom="4px" lineHeight="1.15em">{listData.title}</DSText>
                          <DSText fontSize="0.83em" color="#1e1e1e" marginBottom="9px"><OwnerStringWithLink ownerData={ownerData} /></DSText>
                        </>
                      )
                    }
                  </div>
                  <div className="allCardButtons">
                    <div>
                      <div className="listCardButton">
                        {likedLists && likedLists.includes(listData.id) ? (
                          <FavoriteIcon
                            className='listCardLikeIcon'
                            sx={{
                              color: '#ef00e7'
                            }}
                            onClick={(event) => {
                              event.preventDefault();
                              if (likedLists && likedLists.includes(listData.id)) {
                                handleUnlike(listData.id, userData, likedLists, setLikedLists, ownerData, setAllListData);
                              } else {
                                handleLike(listData.title, listData.id, userData, likedLists, setLikedLists, location, navigate, ownerData, setAllListData);
                              }
                            }}
                            onMouseEnter={() => {
                              if (!likedLists.includes(listData.id)) {
                                setHoveredId(listData.id);
                              }
                            }}
                            onMouseLeave={() => setHoveredId(null)}
                          />
                        ) : (
                          <FavoriteBorderIcon
                            className='listCardLikeIcon'
                            sx={{
                              color: 'black',
                              transition: 'color 0.3s',
                              ...(hoveredId === listData.id && !likedLists.includes(listData.id) && { color: '#ef00e7' }),
                            }}
                            onClick={(event) => {
                              event.preventDefault();
                              if (likedLists && likedLists.includes(listData.id)) {
                                handleUnlike(listData.id, userData, likedLists, setLikedLists, ownerData, setAllListData);
                              } else {
                                handleLike(listData.title, listData.id, userData, likedLists, setLikedLists, location, navigate, ownerData, setAllListData);
                              }
                            }}
                            onMouseEnter={() => {
                              if (!likedLists.includes(listData.id)) {
                                setHoveredId(listData.id);
                              }
                            }}
                            onMouseLeave={() => setHoveredId(null)}
                          />)}
                        <div className="likeScore" onClick={(e) => { e.preventDefault(); handleLikePopupToggle(); }}>
                          {" "} {getListScoreFromListData(listData)} {getListScoreFromListData(listData) === 1 ? "like" : "likes"}
                        </div>
                      </div>
                      <div className="listCardButton">
                        <DnsIcon className='listCardItemIcon' />
                        <div className="textElement">
                          {" "} {listData.data.length} {listData.data.length === 1 ? "item" : "items"}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="listCardButton">
                        <CommentIcon className='listCardCommentIcon' />
                        <div className="textElement">
                          {" "} {commentCount} {commentCount === 1 ? "comment" : "comments"}
                        </div>
                      </div>
                      {listData.isPublic ? (
                        <div className="listCardButton"
                          onClick={(event) => {
                            event.preventDefault();
                            if (navigator.canShare && isMobile) {
                              handleShare(listData);
                            } else {
                              setFlagArray(flagArray.map((c, i) => {
                                if (i === index) {
                                  return true;
                                } else {
                                  return c;
                                }
                              }));
                              const linkToCopy = window.location.origin + "/list/" + String(listData.id);
                              navigator.clipboard.writeText(linkToCopy)
                                .catch((error) => {
                                  console.error('Error copying link to clipboard:', error.message);
                                });
                            }
                          }}
                          onMouseOver={() => {
                            if (!navigator.canShare || !isMobile) {
                              setIsHoverArray(isHoverArray.map((c, i) => {
                                if (i === index) {
                                  return true;
                                } else {
                                  return c;
                                }
                              }))
                            }
                          }}
                          onMouseLeave={() => {
                            if (!navigator.canShare || !isMobile) {
                              setIsHoverArray(isHoverArray.map((c, i) => {
                                if (i === index) {
                                  return false;
                                } else {
                                  return c;
                                }
                              }));
                              setFlagArray(flagArray.map((c, i) => {
                                if (i === index) {
                                  return false;
                                } else {
                                  return c;
                                }
                              }))
                            }
                          }}
                        >
                          {navigator.canShare && isMobile ? (
                            <>
                              <ShareIcon className='listCardShareIcon' />
                              <div className="textElement">
                                {" Share"}
                              </div>
                            </>
                          ) : (
                            <>
                              <LinkIcon className='listCardShareIcon' />
                              <div className="textElement">
                                {flagArray[index] && isHoverArray[index]
                                  ? " Copied"
                                  : (
                                    <>
                                      <div className='shortText'> Copy</div>
                                      <div className='longText'> Copy link</div>
                                    </>
                                  )
                                }
                              </div>
                            </>
                          )}
                        </div>
                      ) : (
                        <div
                          className="listCardButton"
                          style={{ cursor: 'default' }}
                          onClick={(e) => e.preventDefault()}>
                          <VisibilityOffIcon className='listCardShareIcon' />
                          <div className="textElement">
                            {" Private"}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </Link>
          <ListShareRectangle listId={listData.id} listName={listData.title} listCategory={listData.category} listImage={listData.listImage} ownerData={ownerData} selectedEntriesData={listData.data} />
          <ListShareSquare listId={listData.id} listName={listData.title} listCategory={listData.category} ownerData={ownerData} selectedEntriesData={listData.data} />
        </>
      }
      {isLikePopupOpen && (
        <DSUserModal onClose={handleLikePopupToggle} listOfUsers={listData.likedBy} listType="Likes" boostedLikes={listData.score} />
      )}
      <DSConfirmDialog
        title={"Delete List"}
        text={"Are you sure you want to delete this list?"}
        open={deleteListDialogOpen}
        setOpen={setDeleteListDialogOpen}
        setStatus={setDeleteListStatus}
        confirmButtonText={confirmButtonText}
        setConfirmButtonText={setConfirmButtonText}
      />
    </>
  );
};

export default ListCard;