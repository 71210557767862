import React, { useState, useEffect } from 'react';
import { getUsernameFromUserId } from '../..';
import "./styles.css"

const OwnerLink = ({ firstName, lastName, uid }) => {
    const [username, setUsername] = useState('');

    useEffect(() => {
        const fetchUsername = async () => {
            const fetchedUsername = await getUsernameFromUserId(uid);
            setUsername(fetchedUsername || '');
        };

        fetchUsername();
    }, [uid]);

    return (
        <a href={`${window.location.origin}/${username}`} className="owner-link" onClick={(e) => { e.stopPropagation() }}>
            {firstName} {lastName}
        </a>
    );
};

export default OwnerLink;
