import React, { useState } from 'react';
import { getListImageFromEntryData } from '../../../../Utils'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import RenderDate from '../CardComponents/RenderDate';
import RenderChallengeInfo from '../CardComponents/RenderChallengeInfo';
import CardCollapsible from '../CardComponents/CardCollapsible';
import DSText from '../../../../DSSystem/DSText';
import DSListModal from '../../../../DSSystem/DSModal/DSListModal';
import LaunchIcon from '@mui/icons-material/Launch';
import MoveCardsUpDown from '../../MoveCardsUpDown'
import './styles.css';

const VideoGameCard = ({ index, entryData, userData, ownerIds, handleRemoveEntry, showAnnotations, setSelectedEntriesData, listId, isChallengeItem, isInGroupList, selectedEntriesData, isCurrentlyFiltered }) => {
    const userExists = userData && ownerIds;
    const userOwnsList = userExists && ownerIds.includes(userData.id);
    const userDoesNotOwnList = userExists && !ownerIds.includes(userData.id);
    const [isAddElementModalOpen, setIsAddElementModalOpen] = useState(false);

    const addToOtherList = async (e) => {
        e.preventDefault();
        setIsAddElementModalOpen(true);
    }

    const handleCloseModal = () => {
        setIsAddElementModalOpen(false);
    };

    return (
        <>
                <Card className={`gameCard ${isInGroupList ? 'groupListClass' : ''}`}>
                    {isInGroupList &&
                        <div className="circularBadge">
                            <DSText fontSize="0.85em">{index + 1}</DSText></div>
                    }
                    <CardMedia
                        component="img"
                        alt={entryData.gameName}
                        className="gameCardMedia"
                        image={getListImageFromEntryData(entryData, "Video Games")}
                    />
                    <CardContent className="gameCardContent">
                        <DSText fontSize="1.05em" color="#1e1e1e" boldVar="600">
                            <div style={{ paddingRight: userOwnsList ? '15px' : '0px' }}>{entryData.gameName}</div>
                        </DSText>
                        {entryData.platforms &&
                            <DSText fontSize="0.8em" color="#1e1e1e" marginTop='10px'>
                                <span style={{ fontWeight: 600 }}>Platforms:</span> {entryData.platforms.join(', ')}
                            </DSText>
                        }
                        {(entryData.genres || entryData.tags) &&
                            <div style={{ marginTop: "12px" }}>
                                {entryData.tags?.slice(0, 2).map((tag) => (
                                    <Chip key={tag} label={tag} size='small'
                                        sx={{
                                            marginBottom: '4px',
                                            marginRight: '4px',
                                            background: '#fafafa',
                                            color: 'black',
                                            border: '1px solid black',
                                            fontFamily: 'Outfit, sans-serif',
                                            fontSize: '0.78em',
                                            padding: '-20px'
                                        }}
                                    />
                                ))}
                                {entryData.genres?.slice(0, 3).map((genre) => (
                                    <Chip key={genre} label={genre} size='small'
                                        sx={{
                                            marginBottom: '4px',
                                            marginRight: '4px',
                                            background: '#fafafa',
                                            color: 'black',
                                            border: '1px solid black',
                                            fontFamily: 'Outfit, sans-serif',
                                            fontSize: '0.78em',
                                            padding: '-20px'
                                        }}
                                    />
                                ))}
                            </div>
                        }
                        {isChallengeItem && <RenderChallengeInfo entryData={entryData} />}
                        {!isCurrentlyFiltered && <MoveCardsUpDown
                            userOwnsList={userOwnsList}
                            setSelectedEntriesData={setSelectedEntriesData}
                            index={index}
                            selectedEntriesData={selectedEntriesData}
                            listId={listId}
                        />}
                        {!isChallengeItem && <RenderDate createdTime={entryData.createdTime} addedBy={entryData.addedBy} />}
                        {userDoesNotOwnList && <AddIcon className="addIcon" onClick={addToOtherList} />}
                        <LaunchIcon
                            className= "linkIcon"
                            onClick={(event) => {
                                event.preventDefault();
                                window.open(`https://rawg.io/games/${entryData.slug}`, '_blank');
                            }} 
                        />
                        {userOwnsList && (
                            <>
                                <AddIcon className="addIconOnOwnerList" onClick={addToOtherList} />
                                <ClearIcon
                                    className="deleteIcon"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        handleRemoveEntry(index)
                                    }}
                                />
                            </>
                        )}
                    </CardContent>
                </Card>
            <CardCollapsible
                entryData={entryData}
                index={index}
                isEditable={userOwnsList}
                hasRating={entryData.rating && entryData.rating !== 0}
                hasAnnotation={entryData.annotation && entryData.annotation !== ""}
                showAnnotations={showAnnotations}
                setSelectedEntriesData={setSelectedEntriesData}
                listId={listId}
            />
            {isAddElementModalOpen && (
                <DSListModal
                    onClose={handleCloseModal}
                    listOfLists={userData.lists}
                    modalType={"Add to Your List"}
                    entryData={entryData}
                    entryCategory={"Video Games"}
                />
            )}
        </>
    );
};

export default VideoGameCard;