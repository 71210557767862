import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { doc, getDoc, updateDoc, arrayUnion, setDoc, deleteDoc, arrayRemove } from 'firebase/firestore';
import { db } from '../../../firebase';
import { v4 as uuidv4 } from 'uuid';
import queryString from 'query-string';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import AddCommentIcon from '@mui/icons-material/AddComment';
import DSComment from '../../../DSSystem/DSComment';
import './styles.css';

const ChallengeComments = ({ userData, challengeId }) => {
    const location = useLocation();
    const [currComment, setCurrComment] = useState('');
    const [commentEvents, setCommentEvents] = useState(null);
    const [commentsData, setCommentsData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { commentId } = queryString.parse(location.search);

    useEffect(() => {
        const fetchOrCreateCommentSection = async () => {
            if (!challengeId) {
                return;
            }
            try {
                const commentSectionRef = doc(db, 'masterGroupComments', challengeId);
                const commentSectionSnap = await getDoc(commentSectionRef);
                if (commentSectionSnap.exists()) {
                    setCommentEvents(commentSectionSnap.data().events || []);
                } else {
                    await setDoc(commentSectionRef, { id: challengeId, events: [] });
                    setCommentEvents([]);
                }
            } catch (error) {
                console.error('Error fetching or creating comment section:', error);
            }
        };

        fetchOrCreateCommentSection();
    }, [challengeId]);

    useEffect(() => {
        const fetchComments = async () => {
            const commentPromises = commentEvents.map(async (eventId) => {
                const eventRef = doc(db, 'groupLevelComments', eventId);
                const eventSnap = await getDoc(eventRef);
                return eventSnap.exists() ? eventSnap.data() : null;
            });
            const allCommentsData = await Promise.all(commentPromises);
            const validCommentsData = allCommentsData.filter(comment => comment !== null);
            const sortedComments = validCommentsData.sort((a, b) => b.timestamp - a.timestamp);
            setCommentsData(sortedComments);
            setIsLoading(false);
        };

        if (commentEvents !== null) {
            if (commentEvents.length > 0) {
                fetchComments();
            } else {
                setCommentsData([]);
                setIsLoading(false);
            }
        }
    }, [isLoading, commentEvents]);

    const handleSubmit = async () => {
        if (!currComment.trim()) return;

        try {
            const newCommentId = uuidv4();
            const newCommentRef = doc(db, 'groupLevelComments', newCommentId);
            await setDoc(newCommentRef, {
                id: newCommentId,
                userId: userData.id,
                message: currComment,
                timestamp: Date.now(),
                events: []
            });

            const commentRef = doc(db, 'masterGroupComments', challengeId);
            await updateDoc(commentRef, {
                events: arrayUnion(newCommentId)
            });

            setCommentEvents(prevEvents => [...prevEvents, newCommentId]);
            setCurrComment('');
        } catch (error) {
            console.error('Error submitting comment:', error);
        }
    };

    const handleCommentDeletion = async (commentId) => {
        const commentRef = doc(db, 'groupLevelComments', commentId);
        await deleteDoc(commentRef);

        const commentSectionRef = doc(db, 'masterGroupComments', challengeId);
        await updateDoc(commentSectionRef, {
            events: arrayRemove(commentId)
        });

        setCommentEvents(prevEvents => prevEvents.filter(eventId => eventId !== commentId));
    }

    const handleCommentEditing = async (commentId, newMessage) => {
        const editTimestamp = Date.now();
        const commentRef = doc(db, 'groupLevelComments', commentId);
        await updateDoc(commentRef, {
            message: newMessage,
            edited: true,
            timestamp: editTimestamp
        });

        const newCommentsData = prevComments => prevComments.map(comment => {
            if (comment.id === commentId) {
                return {
                    ...comment,
                    message: newMessage,
                    edited: true,
                    timestamp: editTimestamp
                };
            }
            return comment;
        }).sort((a, b) => b.timestamp - a.timestamp);
        setCommentsData(newCommentsData);
    }

    return (
        <>
            <div>
                {!isLoading
                    ? (
                        <>
                            {commentsData.length === 0 && <p>No comments yet. {!userData && "Log in to comment!"}</p>}
                            {commentsData.map((commentData) => (
                                <DSComment
                                    key={commentData.id}
                                    userData={userData}
                                    commentData={commentData}
                                    handleCommentDeletion={handleCommentDeletion}
                                    handleCommentEditing={handleCommentEditing}
                                    scrollTo={commentId && commentId === commentData.id}
                                />
                            ))}
                            {commentId && !commentsData.some(comment => comment.id === commentId) &&
                                <DSComment
                                    key={commentId}
                                    commentData={{ id: commentId, message: 'This comment has been deleted.', edited: false, timestamp: Date.now() }}
                                    handleCommentDeletion={handleCommentDeletion}
                                    handleCommentEditing={handleCommentEditing}
                                    scrollTo={true}
                                    deleted={true}
                                />
                            }
                        </>
                    ) : (
                        <>
                        </>
                    )
                }
            </div>
            {userData &&
                <>
                    <div className="challengeCommentInputContainer">
                        <Avatar alt={userData.username} src={userData.profileImage} sx={{ width: 50, height: 50, marginTop: '5px' }} />
                        <TextField
                            id="standard-multiline-static"
                            value={currComment}
                            onChange={(e) => setCurrComment(e.target.value)}
                            multiline
                            variant="standard"
                            minRows={2}
                            sx={{ padding: '0px !important' }}
                            InputProps={{
                                spellCheck: 'false',
                                disableUnderline: true,
                                sx: {
                                    fontFamily: "'Outfit', sans-serif !important",
                                    fontSize: '0.93em',
                                    padding: '10px 15px !important',
                                    backgroundColor: '#eee !important',
                                    borderRadius: '5px'
                                },
                            }}
                        />
                    </div>
                    <div style={{ textAlign: 'right', marginTop: '10px' }} onClick={handleSubmit}>
                        <button className="addCommentButton">
                            <AddCommentIcon className="addCommentIcon" />
                            Submit
                        </button>
                    </div>
                </>}
        </>
    );
};

export default ChallengeComments;