import React, { useState } from 'react';
import { getListImageFromEntryData, getVisualListFromArray } from '../../../../Utils'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import RenderDate from '../CardComponents/RenderDate';
import RenderChallengeInfo from '../CardComponents/RenderChallengeInfo';
import CardCollapsible from '../CardComponents/CardCollapsible';
import DSText from '../../../../DSSystem/DSText';
import DSListModal from '../../../../DSSystem/DSModal/DSListModal';
import InfoIcon from '@mui/icons-material/Info';
import DSSynopsisModal from '../../../../DSSystem/DSModal/DSSynopsisModal';
import LaunchIcon from '@mui/icons-material/Launch';
import MoveCardsUpDown from '../../MoveCardsUpDown';
import './styles.css';

const BookCard = ({ index, entryData, userData, ownerIds, handleRemoveEntry, showAnnotations, setSelectedEntriesData, listId, isRecommendation, isChallengeItem, isInGroupList, selectedEntriesData, isCurrentlyFiltered }) => {
    const userExists = userData && ownerIds;
    const userOwnsList = userExists && ownerIds.includes(userData.id);
    const userDoesNotOwnList = userExists && !ownerIds.includes(userData.id);
    const [isAddElementModalOpen, setIsAddElementModalOpen] = useState(false);
    const [synopsisModal, setSynopsisModal] = useState(false);

    const addToOtherList = async (e) => {
        e.preventDefault();
        setIsAddElementModalOpen(true);
    }

    const handleCloseModal = () => {
        setIsAddElementModalOpen(false);
    };

    const handleSynopsisModalClose = () => {
        setSynopsisModal(false);
    };

    return (
        <>
                <div>
                    <Card className={`bookCard ${isInGroupList ? 'groupListClass' : ''}`}>
                        {isInGroupList &&
                            <div className="circularBadge">
                                <DSText fontSize="0.85em">{index + 1}</DSText></div>
                        }
                        <CardMedia
                            component="img"
                            alt={entryData.bookName}
                            className="bookCardMedia"
                            image={getListImageFromEntryData(entryData, "Books")}
                        />
                        <CardContent className="bookCardContent">
                            <DSText fontSize="1em" color="#1e1e1e" boldVar="600">
                                <div style={{ paddingRight: userOwnsList ? '15px' : '0px' }}>{entryData.bookName}</div>
                            </DSText>
                            <DSText fontSize="0.83em" color="#1e1e1e" marginTop="2px" marginBottom="10px">
                                {entryData.authors ? (
                                    <>
                                        {'by '}
                                        {getVisualListFromArray(entryData.authors)}<br />
                                    </>
                                ) : null}
                            </DSText>
                            <DSText fontSize="0.8em" color="#1e1e1e">
                                {entryData.publishDate ? (
                                    <>
                                        Year Published: {String(entryData.publishDate).substring(0, 4)}<br />
                                    </>
                                ) : null}
                                {entryData.pageCount ? (
                                    <>
                                        Pages: {entryData.pageCount}
                                    </>
                                ) : null}
                            </DSText>
                            {entryData.genres &&
                                <div style={{ marginTop: '12px' }}>
                                    {entryData.genres && entryData.genres.slice(0, 3).map((genre) => (
                                        <Chip key={genre} label={genre} size='small'
                                            sx={{
                                                marginBottom: '4px',
                                                marginRight: '4px',
                                                background: '#fafafa',
                                                color: 'black',
                                                border: '1px solid black',
                                                fontFamily: 'Outfit, sans-serif',
                                                fontSize: '0.78em',
                                                padding: '-20px'
                                            }}
                                        />
                                    ))}
                                </div>
                            }
                            {isChallengeItem && <RenderChallengeInfo entryData={entryData} />}
                            {!isChallengeItem && !isRecommendation && <RenderDate createdTime={entryData.createdTime} addedBy={entryData.addedBy} />}
                            <LaunchIcon
                            className= "linkIcon"
                            onClick={(event) => {
                                event.preventDefault();
                                window.open(`https://www.amazon.com/s?k=${entryData.bookName}${entryData.authors ? " by " + entryData.authors[0] : ""}&i=stripbooks&ref=nb_sb_noss_1`, '_blank');
                            }} 
                            />
                            {entryData.synopsis && <InfoIcon
                                className="synopsisIcon"
                                onClick={(event) => {
                                    event.preventDefault();
                                    setSynopsisModal(true)
                                }}
                            />}
                            {!isCurrentlyFiltered &&
                            <MoveCardsUpDown
                                userOwnsList={userOwnsList}
                                setSelectedEntriesData={setSelectedEntriesData}
                                index={index}
                                selectedEntriesData={selectedEntriesData}
                                listId={listId}
                            />}
                            {(userDoesNotOwnList || isRecommendation) && <AddIcon className="addIcon" onClick={addToOtherList} />}
                            {userOwnsList && (
                                <>
                                    <AddIcon className="addIconOnOwnerList" onClick={addToOtherList} />
                                    <ClearIcon
                                        className="deleteIcon"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleRemoveEntry(index)
                                        }}
                                    />
                                </>
                            )}
                        </CardContent>
                    </Card>
                </div>
            <CardCollapsible
                entryData={entryData}
                index={index}
                isEditable={userOwnsList}
                hasRating={entryData.rating && entryData.rating !== 0}
                hasAnnotation={entryData.annotation && entryData.annotation !== ""}
                showAnnotations={showAnnotations}
                setSelectedEntriesData={setSelectedEntriesData}
                listId={listId}
            />
            {isAddElementModalOpen && (
                <DSListModal
                    onClose={handleCloseModal}
                    listOfLists={userData.lists}
                    modalType={"Add to Your List"}
                    entryData={entryData}
                    entryCategory={"Books"}
                />
            )}
            {synopsisModal && (
                <DSSynopsisModal
                    onClose={handleSynopsisModalClose}
                    synopsis={entryData.synopsis}
                    name={entryData.bookName}
                />
            )}
        </>
    );
};

export default BookCard;