import React, { useState, useCallback } from 'react';
import { getCroppedImg } from '../../../../../../Utils';
import Cropper from 'react-easy-crop';
import Slider from '@mui/material/Slider';
import './styles.css';

const ImageCropper = ({ setNewImage }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const onCropComplete = useCallback(async (croppedArea, croppedAreaPixels) => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      const file = new File([croppedImage], 'croppedImage.jpg', { type: 'image/jpeg' });
      setNewImage(file);
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, setNewImage]);

  const onSelectFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setImageSrc(reader.result);
        setNewImage(file);
      };
    }
  };

  return (
    <div>
      <input
        type="file"
        accept="image/*"
        onChange={onSelectFile}
        id="listItemImage"
        name="listItemImage"
        className="imageCropperInput"
        style={{ marginBottom: imageSrc ? "20px" : "15px" }}
      />
      {imageSrc && (
        <>
          <div className="imageCropperContainer">
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={3 / 4}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </div>
          <div className="imageCropperSliderContainer">
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              size="small"
              aria-labelledby="Zoom"
              onChange={(e, zoom) => setZoom(zoom)}
              sx={{ color: '#e54afae0' }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ImageCropper;