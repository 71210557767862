import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { db, auth } from '../../../firebase.js';
import { query, getDocs, collection, where, getDoc, doc, setDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { Helmet } from 'react-helmet';
import { updateUserStreak } from '../../../Utils';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DSContainer from '../../../DSSystem/DSContainer';
import DSNavbar from '../../../DSSystem/DSNavbar';
import DSText from '../../../DSSystem/DSText';
import DSButton from '../../../DSSystem/DSButton';
import CircularProgress from '@mui/material/CircularProgress';

const CreateChallengePage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [userData, setUserData] = useState(null);
    const [challengePrompt, setChallengePrompt] = useState("");
    const [challengeCategory, setChallengeCategory] = useState("Movies");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchUserData = async (userId) => {
            setIsLoading(true);
            try {
                const q = query(collection(db, 'members'), where('id', '==', userId));
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const userDoc = querySnapshot.docs[0];
                    const userDataFromDB = userDoc.data();
                    setUserData(userDataFromDB);
                    await updateUserStreak(userDataFromDB);
                } else {
                    console.error('User not found');
                    navigate("/500");
                }
            } catch (error) {
                console.error('Error fetching user data:', error.message);
                navigate("/500");
            }
            setIsLoading(false);
        };

        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                fetchUserData(user.uid);
            } else {
                navigate("/login?redirectTo=" + location.pathname);
                setUserData(null);
            }
        });

        return () => {
            unsubscribe();
        };
    }, [navigate, location.pathname]);

    const handleChallengeCategoryChange = (event) => {
        setChallengeCategory(event.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const nextIdsDocRef = doc(db, 'nextIds', 'challenge');
        await getDoc(nextIdsDocRef).then(async (nextIdsDoc) => {
            if (nextIdsDoc.exists()) {
                const nextIds = nextIdsDoc.data();
                const newChallenge = {
                    id: nextIds.nextId,
                    category: challengeCategory,
                    title: challengePrompt,
                    live: true,
                    createdTime: Date.now(),
                    createdBy: userData.id
                };
                await setDoc(doc(db, 'Challenge', nextIds.nextId), newChallenge);
                await setDoc(nextIdsDocRef, { nextId: String(Number(nextIds.nextId) + 1) });
                navigate(`/challenge/${nextIds.nextId}`);
            }
        }).catch((error) => {
            console.error('Error fetching nextIds:', error);
        });
    };

    return (
        <>
            <Helmet>
                <title>Create Challenge - Palette</title>
                <meta property="og:title" content="Create Challenge - Palette" />
                <link rel="canonical" href={"https://palette.build" + location.pathname} />
                <meta property="og:url" content={"https://palette.build" + location.pathname} />
                <meta property="og:image" content="https://palette.build/landing.png" />
                <meta property="og:description" content="Curate, share, and discover movies, TV shows, books, and more!" />
                <meta name="keywords" content={`palette, lists, social media, challenge, create`} />
            </Helmet>
            {userData && <DSNavbar isLoggedIn={true} currentPage={"Home"} userData={userData} />}
            <DSContainer>
                <div className="card">
                    <DSText fontSize="1.65em" color="#1e1e1e" boldVar="600" marginBottom="20px">Create Challenge</DSText>
                    {!isLoading ?
                        (<form onSubmit={handleSubmit}>
                            <label>Challenge Prompt</label>
                            <input
                                type="text"
                                value={challengePrompt}
                                onChange={(e) => setChallengePrompt(e.target.value)}
                                required
                            />
                            <label>Category</label>
                            <Select
                                id="challengeCategory"
                                name="challengeCategory"
                                value={challengeCategory}
                                onChange={handleChallengeCategoryChange}
                                size='small'
                                sx={{
                                    display: "block",
                                    width: "100%",
                                    boxSizing: "border-box",
                                    padding: 0,
                                    marginTop: "7px",
                                    marginBottom: "20px",
                                    fontFamily: "'Outfit', sans-serif !important",
                                    fontSize: "0.95em",
                                    borderRadius: "7px",
                                    backgroundColor: "#fafafa",
                                    color: "#1e1e1e",
                                    '.MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid #1e1e1e6d',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid #1e1e1e6d',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        border: '1.5px solid #645dfc',
                                    },
                                }}
                                MenuProps={{
                                    sx: {
                                        "& .MuiMenuItem-root": {
                                            fontFamily: "'Outfit', sans-serif !important",
                                        },
                                        "& .MuiMenuItem-root:hover": {
                                            backgroundColor: "#655dfc15"
                                        },
                                        "&& .Mui-selected": {
                                            backgroundColor: "#655dfc25"
                                        },
                                    }
                                }}
                            >
                                <MenuItem value="Movies">Movies</MenuItem>
                                <MenuItem value="TV Shows">TV Shows</MenuItem>
                                <MenuItem value="Books">Books</MenuItem>
                                <MenuItem value="Video Games">Video Games</MenuItem>
                                {/*
                                <MenuItem value="Web Pages">Web Pages</MenuItem>
                                <MenuItem value="Freestyle">Freestyle</MenuItem>
                                */}
                            </Select>
                            <div style={{ marginTop: "20px" }}>
                                <DSButton buttonText="Start Curating"></DSButton>
                            </div>
                        </form>) : (

                            <div className="circularProgress">
                                <CircularProgress />
                                <DSText fontSize="1.2em" color="white" boldVar="400" marginBottom="15px">
                                    Loading...
                                </DSText>
                            </div>

                        )}
                </div>
            </DSContainer >
        </>
    );
};

export default CreateChallengePage;
