import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { db, storage } from "../../../../firebase";
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getListImageFromEntryData } from '../../../../Utils';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RenderDate from '../CardComponents/RenderDate';
import TagSelectionDialog from '../CardComponents/TagSelectionDialog';
import EditArticleDialog from '../CardComponents/EditArticleDialog';
import CardCollapsible from '../CardComponents/CardCollapsible';
import DSText from '../../../../DSSystem/DSText';
import DSListModal from '../../../../DSSystem/DSModal/DSListModal';
import MoveCardsUpDown from '../../MoveCardsUpDown';
import './styles.css';

const ArticleCard = ({ index, entryData, userData, ownerIds, handleRemoveEntry, showAnnotations, handleCategoriesUpdate, setSelectedEntriesData, listId, selectedEntriesData, isCurrentlyFiltered }) => {
    const userExists = userData && ownerIds;
    const userOwnsList = userExists && ownerIds.includes(userData.id);
    const userDoesNotOwnList = userExists && !ownerIds.includes(userData.id);
    const [isTagPopupOpen, setIsTagPopupOpen] = useState(false);
    const [isAddElementModalOpen, setIsAddElementModalOpen] = useState(false);
    const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);

    const toggleTagPopup = () => {
        setIsTagPopupOpen(!isTagPopupOpen);
    };

    const handleTagSubmit = (newTagLabels) => {
        handleCategoriesUpdate(index, newTagLabels);
        toggleTagPopup();
    };

    const handleTagTextClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        toggleTagPopup();
    };

    const addToOtherList = async (e) => {
        e.preventDefault();
        setIsAddElementModalOpen(true);
    }

    const handleCloseModal = () => {
        setIsAddElementModalOpen(false);
    };

    const handleEditClick = (e) => {
        e.preventDefault();
        setIsEditPopupOpen(true);
    };

    const handleEditSubmit = async (updatedData) => {
        try {
            const listDocRef = doc(db, 'lists', listId);
            const listDocSnapshot = await getDoc(listDocRef);
            if (listDocSnapshot.exists()) {
                let originalData = listDocSnapshot.data().data;
                originalData[originalData.length - index - 1].articleName = updatedData.articleName;
                originalData[originalData.length - index - 1].author = updatedData.author;
                if (updatedData.newImage) {
                    const storageRef = ref(storage, `cardImages/${listId}/${entryData.url}`);
                    const snapshot = await uploadBytes(storageRef, updatedData.newImage);
                    originalData[originalData.length - index - 1].imagePath = await getDownloadURL(snapshot.ref);
                }
                originalData[originalData.length - index - 1].lastUpdated = Date.now();
                const reversedData = [...originalData].reverse();
                await updateDoc(listDocRef, {
                    data: originalData,
                    lastUpdated: Date.now()
                });
                setSelectedEntriesData(reversedData);
            } else {
                console.error('Error updating article card');
            }
        } catch (error) {
            console.error('Error updating article card:', error);
        };
        setIsEditPopupOpen(false);
    }

    return (
        <>
            <Link to={entryData.url} target="_blank" className="link">
                <div>
                    <Card className="articleCard">
                        <CardMedia
                            className="articleCardMedia"
                            component="img"
                            alt={entryData.articleName}
                            image={getListImageFromEntryData(entryData, "Web Pages")}
                        />
                        <CardContent className="articleCardContent">
                            <DSText fontSize="0.95em" color="#1e1e1e" boldVar="600" lineHeight="1.15em">
                                <div style={{ paddingRight: userOwnsList ? '15px' : '0px' }}>{entryData.articleName}</div>
                            </DSText>
                            <DSText fontSize="0.85em" color="#1e1e1e" marginTop="4px" marginBottom="2px">{entryData.author}</DSText>
                            {((entryData.genres && entryData.genres.length > 0) || userOwnsList) &&
                                <div className="articleGenreContainer">
                                    {entryData.genres && entryData.genres.length > 0 &&
                                        <div className="articleGenreChips">
                                            {entryData.genres.map((genre) => (
                                                <Chip key={genre} label={genre} size='small'
                                                    sx={{
                                                        marginTop: '4px',
                                                        marginRight: '4px',
                                                        background: '#fafafa',
                                                        color: 'black',
                                                        border: '1px solid black',
                                                        fontFamily: 'Outfit, sans-serif',
                                                        fontSize: '0.78em',
                                                        padding: '-20px'
                                                    }}
                                                />
                                            ))}
                                        </div>
                                    }
                                    {userOwnsList && (
                                        <div className="articleGenreIconContainer" onClick={handleTagTextClick}>
                                            {
                                                entryData.genres && entryData.genres.length > 0 ? (
                                                    <LocalOfferIcon className="articleGenreIconUpdate" />
                                                ) : (
                                                    <>
                                                        <div className="articleGenreIconText">Add Tags</div>
                                                        <ControlPointIcon className="articleGenreIconAdd" />
                                                    </>
                                                )
                                            }
                                        </div>
                                    )}
                                </div>
                            }
                            <RenderDate createdTime={entryData.createdTime} addedBy={entryData.addedBy} />
                            {userDoesNotOwnList && <AddIcon className="addIcon" onClick={addToOtherList} />}
                        </CardContent>
                        {userOwnsList && (
                            <>
                                <AddIcon className="addIconOnOwnerList" onClick={addToOtherList} />
                                <ClearIcon
                                    className="deleteIcon"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        handleRemoveEntry(index)
                                    }}
                                />
                                {!isCurrentlyFiltered && 
                                <MoveCardsUpDown
                                userOwnsList={userOwnsList}
                                setSelectedEntriesData={setSelectedEntriesData}
                                index={index}
                                selectedEntriesData={selectedEntriesData}
                                listId={listId}
                                />}
                                <EditIcon className="editIcon" onClick={handleEditClick} />
                            </>
                        )}
                    </Card>
                </div>
            </Link>
            <CardCollapsible
                entryData={entryData}
                index={index}
                isEditable={userOwnsList}
                hasRating={entryData.rating && entryData.rating !== 0}
                hasAnnotation={entryData.annotation && entryData.annotation !== ""}
                showAnnotations={showAnnotations}
                setSelectedEntriesData={setSelectedEntriesData}
                listId={listId}
            />
            <TagSelectionDialog
                open={isTagPopupOpen}
                onClose={toggleTagPopup}
                onTagSubmit={handleTagSubmit}
                initialTags={entryData.genres}
            />
            {isAddElementModalOpen && (
                <DSListModal
                    onClose={handleCloseModal}
                    listOfLists={userData.lists}
                    modalType={"Add to Your List"}
                    entryData={entryData}
                    entryCategory={"Web Pages"}
                />
            )}
            {isEditPopupOpen && (
                <EditArticleDialog
                    open={isEditPopupOpen}
                    onClose={() => setIsEditPopupOpen(false)}
                    onSubmit={handleEditSubmit}
                    entryData={entryData}
                />
            )}
        </>
    );
};

export default ArticleCard;