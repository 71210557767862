import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import './styles.css';

const SingleEntryField = ({ category, handleSingleEntryAdd }) => {
    const [value, setValue] = useState('');

    return (
        <div>
            <TextField
                autoComplete="off"
                id="singleEntryField"
                label={category === "freestyle" ? "Type title and press enter" : `Paste ${category} link and press enter`}
                value={value}
                onChange={(event) => setValue(event.target.value)}
                InputLabelProps={{
                    sx: {
                        fontFamily: "'Outfit', sans-serif !important",
                        marginTop: "-4px !important",
                        display: value ? "none" : "block",
                        "&.Mui-focused": {
                            display: "none",
                        },
                        "&.MuiInputLabel-outlined": {
                            color: "#1e1e1e",
                        }
                    }
                }}
                InputProps={{
                    sx: {
                        fontFamily: "'Outfit', sans-serif !important",
                        height: "3em",
                        border: "none",
                        "& fieldset": {
                            border: "none",
                        }
                    },
                }}
                onKeyDown={(event) => {
                    if (event.key === "Enter") {
                        handleSingleEntryAdd(event, event.target.value);
                        setValue('');
                    }
                }}
            />
        </div>
    );
};

export default SingleEntryField;
