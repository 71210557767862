import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { auth } from '../../../firebase.js';
import { onAuthStateChanged, verifyPasswordResetCode, confirmPasswordReset, applyActionCode, signOut } from "firebase/auth";
import { Helmet } from 'react-helmet';
import { getUsernameFromUserId } from '../../../Utils/index.js';
import DSContainer from '../../../DSSystem/DSContainer/index.js';
import DSText from '../../../DSSystem/DSText/index.js';
import DSButton from '../../../DSSystem/DSButton/index.js';
import DSNavbar from '../../../DSSystem/DSNavbar/index.js';
import './styles.css';

const AccountUpdate = () => {
    const requireEmailVerification = process.env.REACT_APP_REQUIRE_EMAIL_VERIFICATION;
    const location = useLocation();
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const mode = queryParams.get('mode');
    const authCode = queryParams.get('oobCode');
    const navigate = useNavigate();
    const [newPassword, setNewPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [emailMessage, setEmailMessage] = useState('');

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                if (user.emailVerified || requireEmailVerification === "false") {
                    let username = await getUsernameFromUserId(user.uid)
                    navigate(`/${username}`);
                } else {
                    await signOut(auth);
                    navigate("/login");
                }
            } else {
                switch (mode) {
                    case "resetPassword":
                        verifyPasswordResetCode(auth, authCode)
                            .catch(function () {
                                navigate("/login");
                            });
                        break;
                    case "verifyEmail":
                        applyActionCode(auth, authCode).then(() => {
                            setEmailMessage("Thanks for verifying your email.");
                        }).catch(() => {
                            setEmailMessage("This link has expired.")
                        });
                        break;
                    default:
                        navigate("/");
                }
            }
        });

        return () => unsubscribe();
    }, [navigate, mode, authCode, requireEmailVerification]);

    const handleResetPassword = async (e) => {
        e.preventDefault();
        setErrorMessage('');

        try {
            await confirmPasswordReset(auth, authCode, newPassword);
            navigate("/login");
        } catch (error) {
            setErrorMessage('Error resetting password.');
        }
    };

    return (
        <>
            <Helmet>
                <title>{mode === "resetPassword" ? "Reset Password" : "Verify Email"} - Palette</title>
                <meta property="og:title" content={mode === "resetPassword" ? "Reset Password - Palette" : "Verify Email - Palette"} />
                <link rel="canonical" href={"https://palette.build" + location.pathname} />
                <meta property="og:url" content={"https://palette.build" + location.pathname} />
                <meta property="og:image" content="https://palette.build/landing.png" />
                <meta property="og:description" content="Curate, share, and discover movies, TV shows, books, and more!" />
                <meta name="keywords" content={`palette, lists, social media, account`} />
            </Helmet>
            <DSNavbar isLoggedIn={false} />
            <DSContainer>
                <div className="card">
                    {mode === "resetPassword" ? (
                        <form onSubmit={handleResetPassword}>
                            <DSText fontSize="1.65em" color="#1e1e1e" boldVar="600" marginBottom="20px">Reset Password</DSText>
                            <label>New Password</label>
                            <input
                                type="password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                            />
                            {errorMessage && <div className="invalid">{errorMessage} <Link to="/forgot-password" className="link">Resend Email</Link>.</div>}
                            <div style={{ marginTop: "20px" }}><DSButton buttonText="Confirm" /></div>
                        </form>
                    ) : (
                        <div className="emailMessage">
                            <DSText fontSize="1.2em" color="#645dfc" boldVar="400">
                                {emailMessage} Please <Link to="/login" className="color-link">login</Link> to {emailMessage.includes("expired") ? "generate a new link." : "continue."}
                            </DSText>
                        </div>
                    )}
                </div>
            </DSContainer>
        </>
    );
};

export default AccountUpdate;
