import React, { useState, useEffect, useRef } from 'react';
import { db } from "../../../firebase";
import { collection, query, where, getDocs } from 'firebase/firestore';
import { updateListOnSort } from '../../../Utils';
import DSText from '../../../DSSystem/DSText';
import ListCard from '../../ListCard';

const ChallengeListsTab = ({ challengeId, challengeCategory, userData, likedLists, setLikedLists, allListData, setAllListData }) => {
  const [privateListData, setPrivateListData] = useState([]);
  const [showPrivate, setShowPrivate] = useState(false);

  const observer = useRef();
  const sentinelRef = useRef();
  const batchSize = 10; // Number of lists to load each time
  const [isLoading, setIsLoading] = useState(true);
  const [loadedListData, setLoadedListData] = useState([]);
  const [lastLoadedIndex, setLastLoadedIndex] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const [hoveredId, setHoveredId] = useState(null);
  const [flagArray, setFlagArray] = useState([]);
  const [isHoverArray, setIsHoverArray] = useState([]);

  useEffect(() => {
    const fetchPublicLists = async () => {
      const adminEmails = ["mehulrastogi2002@gmail.com", "mittalprakhar57@gmail.com", "mehulprakharprojects@gmail.com"]
      setIsLoading(true);
      try {
        let lists = [];
        const q = query(collection(db, 'lists'), where('groupId', '==', challengeId));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          lists = querySnapshot.docs.map(doc => doc.data());
        }
        if (userData && adminEmails.includes(userData.email)) {
          let privateLists = lists.filter(list => !list.isPublic);
          setPrivateListData(privateLists);
          setShowPrivate(privateLists.length > 0);
        }
        lists = lists.filter(list => list.isPublic);
        lists = updateListOnSort(lists, "Popular");
        setAllListData(lists);
        setFlagArray(Array(lists.length).fill(false));
        setIsHoverArray(Array(lists.length).fill(false));
        setLoadedListData(lists.slice(0, batchSize));
        setLastLoadedIndex(batchSize);
        setHasMore(lists.length > batchSize);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching lists for challenge:', error.message);
      }
    };

    fetchPublicLists();
    return () => observer.current?.disconnect();
  }, [challengeId, userData, setAllListData]);

  useEffect(() => {
    const options = {
      root: null,
      threshold: 0.1,
    };

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setLoadedListData(allListData.slice(0, lastLoadedIndex + batchSize));
        setLastLoadedIndex(lastLoadedIndex + batchSize);
        setHasMore(allListData.length > lastLoadedIndex + batchSize);
      }
    }, options);

    if (sentinelRef.current) {
      observer.current.observe(sentinelRef.current);
    }

    return () => observer.current?.disconnect();
  }, [allListData, hasMore, lastLoadedIndex]);

  return (
    <>
      {isLoading
        ? <DSText marginBottom="40px">Loading...</DSText>
        : (
          <>
            {loadedListData.length > 0 ?
              <>
                <div className="listCardContainerWithSentinel" style={{ marginTop: '-25px' }}>
                  {loadedListData.map((listData, index) => (
                    <ListCard
                      key={listData.id}
                      listData={listData}
                      userData={userData}
                      likedLists={likedLists}
                      setLikedLists={setLikedLists}
                      setAllListData={setLoadedListData}
                      index={index}
                      hoveredId={hoveredId}
                      setHoveredId={setHoveredId}
                      flagArray={flagArray}
                      setFlagArray={setFlagArray}
                      isHoverArray={isHoverArray}
                      setIsHoverArray={setIsHoverArray}
                      isGroupList={true}
                    />
                  ))}
                </div>
                <div ref={sentinelRef} style={{ height: '20px' }}></div>
              </>
              : <DSText marginBottom="40px">No lists created yet. You can be the first one to take on the challenge!</DSText>
            }
            {showPrivate &&
              <div className="listCardContainer" style={{ marginTop: '-10px' }}>
                <DSText fontSize="1.3em" boldVar="600">Private (Admin Only)</DSText>
                {privateListData.map((listData, index) => (
                  <ListCard
                    key={listData.id}
                    listData={listData}
                    userData={userData}
                    likedLists={likedLists}
                    setLikedLists={setLikedLists}
                    setAllListData={setAllListData}
                    index={index}
                    hoveredId={hoveredId}
                    setHoveredId={setHoveredId}
                    flagArray={flagArray}
                    setFlagArray={setFlagArray}
                    isHoverArray={isHoverArray}
                    setIsHoverArray={setIsHoverArray}
                  />
                ))}
              </div>
            }
          </>
        )
      }
    </>
  );
};

export default ChallengeListsTab;
