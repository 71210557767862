import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { getProfileImageFromProfileData } from '../../../Utils';
import { CircularProgress } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import DSText from '../../DSText';
import '../styles.css';

const DSUserModal = ({ onClose, listOfUsers, listType, isViewerOwner, boostedLikes }) => {
  const [usersData, setUsersData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isListEmpty, setIsListEmpty] = useState(false);

  const fetchListNames = useCallback(async () => {
    try {
      const userDataPromises = listOfUsers.map(async (userId) => {
        const listQuery = query(collection(db, 'members'), where('id', '==', userId));
        const querySnapshot = await getDocs(listQuery);
        if (!querySnapshot.empty) {
          const userData = querySnapshot.docs[0].data();
          const fullName = `${userData.firstName} ${userData.lastName}`;
          const profileImage = getProfileImageFromProfileData(userData);
          return { fullName, userId: userData.id, username: userData.username, profileImage };
        }
        return null;
      });

      const userDataArray = (await Promise.all(userDataPromises)).filter(Boolean);
      setUsersData(userDataArray);
      setIsListEmpty(userDataArray.length === 0);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setIsListEmpty(true);
    } finally {
      setIsLoading(false);
    }
  }, [listOfUsers]);

  useEffect(() => {
    if (listOfUsers.length === 0) {
      setIsListEmpty(true);
      setIsLoading(false);
    } else {
      fetchListNames();
    }
  }, [listOfUsers, fetchListNames]);

  return (
    <div className="modal-overlay" onClick={onClose}>
      {!isLoading ? (
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div>
            <div className="modal-content-padding">
              <DSText fontSize="1.8em" boldVar="600" marginBottom="15px">
                {listType}
              </DSText>
            </div>
            {isListEmpty ? (
              <div className="noFollowersText modal-content-padding">
                <DSText fontSize="1em">
                  {listType === "Likes"
                    ? "This list does not have any likes yet. Share it with others to get likes!"
                    : listType === "Collaborators"
                      ? "This list does not have any collaborators."
                      : isViewerOwner
                        ? `You don't have any ${String(listType).toLowerCase()} yet. Interact with other users to discover new palettes and get recommendations!`
                        : `This user does not have any ${String(listType).toLowerCase()}.`}
                </DSText>
              </div>
            ) : (
              <ul>
                {usersData.map((listItem, index) => (
                  <li key={index}>
                    <Link
                      to={`/${listItem.username}`}
                      onClick={() => {
                        onClose();
                      }}
                    >
                      <img src={listItem.profileImage} alt={listItem.username} className="modalProfileImg" />
                      <div style={{ fontFamily: "'Outfit', sans-serif" }}>
                        <span style={{ fontWeight: "500" }}> {listItem.username}<br /></span>
                        <span style={{ fontSize: "0.92em", }}>{listItem.fullName}</span>
                      </div>
                    </Link>
                  </li>
                ))}
                {boostedLikes && boostedLikes > 0
                  ? (
                    <div className="modal-content-padding">
                      <li key={"boosted"} className="boostedLikesText">and {boostedLikes} more...</li>
                    </div>
                  ) : (
                    <></>
                  )
                }
              </ul>
            )}
          </div>
          <ClearIcon
            sx={{
              width: '0.75em',
              position: 'absolute',
              top: '8px',
              right: '8px',
              color: 'white',
              cursor: 'pointer',
              transition: 'all 0.15s ease-in-out',
              '&:hover': {
                backgroundColor: 'transparent !important',
                color: '#ef00e7 !important',
              },
            }}
            onClick={onClose}
          />
        </div>
      ) : (
        <>
          <CircularProgress style={{ color: 'white', marginRight: '5px' }} />
          <div>Loading...</div>
        </>
      )}
    </div>
  );
};

export default DSUserModal;