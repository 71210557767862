import React from 'react';
import DSText from '../../../../../DSSystem/DSText';

const RenderChallengeInfo = ({ entryData }) => {
  return (
    <DSText fontSize="0.75em" color="#808080" marginTop="12px">
      Added {entryData.frequency} time{entryData.frequency > 1 ? "s" : ""} with an average elo of {entryData.score} pts
    </DSText>
  );
};

export default RenderChallengeInfo;