import React, { useState } from 'react';
import DSUserModal from '../../DSSystem/DSModal/DSUserModal';
import OwnerLink from './OwnerLink';
import './styles.css';

const OwnerStringWithLink = ({ ownerData }) => {
    let ownerString;
    let collabList;
    const [isCollabPopupOpen, setIsCollabPopupOpen] = useState(false);

    const handlePopupClose = () => {
        setIsCollabPopupOpen(false);
    };

    const handlePopupOpen = () => {
        setIsCollabPopupOpen(true);
    };

    if (ownerData.length > 0) {
        ownerString = (
            <OwnerLink
                firstName={ownerData[0]?.firstName}
                lastName={ownerData[0]?.lastName}
                uid={ownerData[0]?.id}
            />
        );
        if (ownerData.length === 2) {
            ownerString = (
                <>
                    {ownerString}
                    <span> and </span>
                    <OwnerLink
                        firstName={ownerData[1]?.firstName}
                        lastName={ownerData[1]?.lastName}
                        uid={ownerData[1]?.id}
                    />
                </>
            );
        } else if (ownerData.length > 2) {
            collabList = ownerData.slice(1).map(item => item.id);
            ownerString = (
                <>
                    {ownerString}{' '}
                    and <span onClick={(e) => { e.preventDefault(); handlePopupOpen(); }} className="hoverSpan">
                        {ownerData.length - 1} others
                    </span>
                </>
            );
        }
    } else {
        ownerString = <span>Loading...</span>;
    }

    return (
        <>
            {ownerString}
            {isCollabPopupOpen && (
                <DSUserModal onClose={(e) => { e.preventDefault(); handlePopupClose(); }} listOfUsers={collabList} listType="Collaborators" />
            )}
        </>
    );
};

export default OwnerStringWithLink;