import React from 'react';
import {
  FacebookShareButton, FacebookIcon,
  TwitterShareButton, TwitterIcon,
  LinkedinShareButton, LinkedinIcon,
  TelegramShareButton, TelegramIcon,
  WhatsappShareButton, WhatsappIcon,
} from 'react-share';
import './styles.css'

const ShareIcons = ({ username }) => {
  const shareUrl = `https://www.palette.build/${username}`;
  const title = 'Join me on Palette!';
  const body = 'I have been curating my interests on Palette. Join me to collaborate and let\'s stay updated with each other!';

  return (
    <div className='sharingIcon'>
      <FacebookShareButton url={shareUrl} quote={body} hashtag='Palette'>
        <FacebookIcon size={30} round />
      </FacebookShareButton>
      <TwitterShareButton url={shareUrl} title={body} hashtags={['Palette', 'curation', 'socialcuration']}>
        <TwitterIcon size={30} round />
      </TwitterShareButton>
      <LinkedinShareButton url={shareUrl} summary={body} title={title} source={shareUrl}>
        <LinkedinIcon size={30} round />
      </LinkedinShareButton>
      <WhatsappShareButton url={shareUrl} title={body}>
        <WhatsappIcon size={30} round />
      </WhatsappShareButton>
      <TelegramShareButton url={shareUrl} title={body}>
        <TelegramIcon size={30} round />
      </TelegramShareButton>
    </div>
  );
};

export default ShareIcons;