import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ExploreIcon from '@mui/icons-material/Explore';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import Badge from '@mui/material/Badge';
import "./styles.css";

const DSNavbar = ({ isLoggedIn, currentPage, userData }) => {
  const location = useLocation();
  const [activityNumber, setActivityNumber] = useState(0);

  useEffect(() => {
    if (userData && userData.hasOwnProperty('notificationsUnseen')) {
      setActivityNumber(userData.notificationsUnseen < 0 ? 0 : userData.notificationsUnseen);
    } else {
      setActivityNumber(0);
    }
  }, [userData]);

  return (
    <nav className="navbar">
      <div className="navbar-links">
        {isLoggedIn ? (
          <>
            <Link to={`/${userData.username}`} className={currentPage === "Home" && "navbar-link-highlight"}>
              {currentPage === "Home"
                ? <HomeIcon className="navbar-link-icon" />
                : <HomeOutlinedIcon className="navbar-link-icon" />}
              Home
            </Link>
            <Link to="/create" className={currentPage === "Create" && "navbar-link-highlight"}>
              {currentPage === "Create"
                ? <AddCircleIcon className="navbar-link-icon" />
                : <AddCircleOutlineOutlinedIcon className="navbar-link-icon" />}
              Create
            </Link>
            <Link to="/explore" className={currentPage === "Explore" && "navbar-link-highlight"}>
              {currentPage === "Explore"
                ? <ExploreIcon className="navbar-link-icon" />
                : <ExploreOutlinedIcon className="navbar-link-icon" />}
              Explore
            </Link>
            <Link to="/activity" className={currentPage === "Activity" && "navbar-link-highlight"}>
              {currentPage === "Activity"
                ? <NotificationsIcon className="navbar-link-icon" />
                : activityNumber > 0
                  ? (
                    <Badge badgeContent={activityNumber} color="secondary" className="navbar-link-activity-icon"
                      sx={{
                        "& .MuiBadge-badge": {
                          color: "white",
                          fontFamily: "'Outfit', sans-serif !important",
                        }
                      }}>
                      <NotificationsOutlinedIcon />
                    </Badge>
                  ) : (
                    <NotificationsOutlinedIcon className="navbar-link-icon" />
                  )
              }
              {currentPage !== "Activity" && activityNumber > 0 ? (<div className="navbar-link-activity-text">Activity</div>) : ("Activity")}
            </Link>
          </>
        ) : (
          <>
            <Link to="/" className={currentPage === "Home" && "navbar-link-highlight"}>
              {currentPage === "Home"
                ? <HomeIcon className="navbar-link-icon" />
                : <HomeOutlinedIcon className="navbar-link-icon" />}
              Home
            </Link>
            <Link to="/explore" className={currentPage === "Explore" && "navbar-link-highlight"}>
              {currentPage === "Explore"
                ? <ExploreIcon className="navbar-link-icon" />
                : <ExploreOutlinedIcon className="navbar-link-icon" />}
              Explore
            </Link>
            <Link to={`/login${location.pathname.includes("list") || location.pathname.includes("home")
              ? `?redirectTo=${location.pathname}`
              : ""}`}
              className={currentPage === "Login" && "navbar-link-highlight"}>
              <LoginOutlinedIcon className="navbar-link-icon" />
              Login
            </Link>
            <Link to={`/signup${location.pathname.includes("list") || location.pathname.includes("home")
              ? `?redirectTo=${location.pathname}`
              : ""}`}
              className={currentPage === "Signup" && "navbar-link-highlight"}>
              <PersonAddAltOutlinedIcon className="navbar-link-icon" />
              Signup
            </Link>
          </>
        )}
      </div>
    </nav>
  );
};

export default DSNavbar;