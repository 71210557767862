import React, { useRef } from 'react';
import { autocompleteClasses } from "@mui/material/Autocomplete";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

const AutocompleteField = ({ searchResults, handleSearchChange, handleSearchSelect, renderOption, listCategory, displayListCategory }) => {
    const autoComplete = useRef(null);
    displayListCategory = displayListCategory ? displayListCategory : listCategory.toLowerCase();

    return (
        <>
            <Autocomplete
                ref={autoComplete}
                options={searchResults}
                filterOptions={(options) => options}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => {
                    if (listCategory === "Books" && option.volumeInfo && option.volumeInfo.title) {
                        return option.volumeInfo.title;
                    } else {
                        return option.title || "";
                    }
                }}
                getOptionKey={(option) => option.id}
                onInputChange={(event, value) => handleSearchChange(event, value)}
                onChange={handleSearchSelect}
                renderOption={renderOption}
                noOptionsText={"No " + displayListCategory + " found"}
                sx={{
                    [`& .${autocompleteClasses.popupIndicator}`]: {
                        display: "none"
                    },
                    [`& .${autocompleteClasses.clearIndicator}`]: {
                        display: "none"
                    },
                    "& .MuiOutlinedInput-root": {
                        padding: "0px",
                        height: "3em",
                    },
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                        border: "none"
                    },
                    "& .MuiAutocomplete-inputRoot": {
                        border: "none",
                        paddingLeft: "5px",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                    },
                    "& .MuiAutocomplete-input": {
                        fontFamily: "'Outfit', sans-serif !important",
                        border: "none",
                    },
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={"Search " + displayListCategory}
                        InputProps={{
                            ...params.InputProps,
                            sx: {
                                border: "none",
                            },
                            endAdornment: (
                                <React.Fragment>
                                    {params.InputProps.endAdornment}
                                    <IconButton
                                        sx={{
                                            color: "#1e1e1e",
                                            position: "absolute",
                                            p: "0px",
                                            right: "10px",
                                            top: "calc(50% - 12px)",
                                            cursor: "default",
                                            "&:hover": {
                                                background: "transparent"
                                            }
                                        }}>
                                        <AddIcon />
                                    </IconButton>
                                </React.Fragment>
                            ),
                        }}
                        InputLabelProps={{
                            sx: {
                                fontFamily: "'Outfit', sans-serif !important",
                                marginTop: "-4px",
                                "&.Mui-focused": {
                                    display: "none",
                                },
                                "&.MuiInputLabel-outlined": {
                                    marginTop: "-5px",
                                    color: "#1e1e1e"
                                }
                            }
                        }}
                    />
                )}
            />
        </>
    );
};

export default AutocompleteField;
