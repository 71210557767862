import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import { db, auth, storage } from "../../firebase";
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { collection, query, where, getDocs, doc, updateDoc, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { formatDate, addListEntry, removeListEntry, getOwnerDataFromOwnerIds, getOwnerStringFromOwnerData, getVisualListFromArray, deleteList, getListImageFromEntryData, updateShareImage, sendNewListCreationNotifications, deleteListNotificationsAndUpdateUnseenCount, getListScoreFromListData, scrollToTargetDiv, updateUserStreak, getTextForListOfCategory } from '../../Utils';
import { v4 as uuidv4 } from 'uuid';
import { Helmet } from 'react-helmet';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ExpandCircleDownOutlined from '@mui/icons-material/ExpandCircleDownOutlined';
import UploadIcon from '@mui/icons-material/Upload';
import CommentIcon from '@mui/icons-material/Comment';
import AssistantIcon from '@mui/icons-material/Assistant';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import DSContainer from '../../DSSystem/DSContainer';
import DSText from '../../DSSystem/DSText';
import DSNavbar from '../../DSSystem/DSNavbar';
import DSConfirmDialog from '../../DSSystem/DSConfirmDialog';
import AutocompleteField from './InputFields/AutocompleteField';
import SingleEntryField from './InputFields/SingleEntryField';
import MovieCard from './CategoryCards/MovieCard';
import ShowCard from './CategoryCards/ShowCard';
import BookCard from './CategoryCards/BookCard';
import FreestyleCard from './CategoryCards/FreestyleCard';
import ArticleCard from './CategoryCards/ArticleCard';
import VideoGameCard from './CategoryCards/VideoGameCard';
import FeatureRequestForm from '../FeatureRequestForm';
import ListShareRectangle from '../ShareImages/ListShareRectangle';
import ListShareSquare from '../ShareImages/ListShareSquare';
import OwnerStringWithLink from '../../Utils/OwnerStringWithLength';
import ListButtons from './ListButtons';
import ListComments from './ListComments';
import ListRecommendations from './ListRecommendations';
import './styles.css';

const ListPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { listId } = useParams();

  const requireEmailVerification = process.env.REACT_APP_REQUIRE_EMAIL_VERIFICATION;
  const moviesApiKey = process.env.REACT_APP_MOVIE_API_KEY;
  const booksApiKey = process.env.REACT_APP_BOOK_API_KEY;
  const gamesApiKey = process.env.REACT_APP_GAME_API_KEY;
  const restaurantsApiKey = process.env.REACT_APP_RESTAURANTS_API_KEY;

  const adminEmails = ["mehulrastogi2002@gmail.com", "mittalprakhar57@gmail.com"]
  const shareImageRectURL = `https://firebasestorage.googleapis.com/v0/b/list-curation.appspot.com/o/shareImages%2Flist%2F${listId}-rect.png?alt=media&token=208a6fc5-51a1-4b83-a054-ea065ea217e4`;
  const shareImageSquareURL = `https://firebasestorage.googleapis.com/v0/b/list-curation.appspot.com/o/shareImages%2Flist%2F${listId}-square.png?alt=media&token=208a6fc5-51a1-4b83-a054-ea065ea217e4`;

  const itemsDivRef = useRef(null);
  const commentsDivRef = useRef(null);
  const recommendationsDivRef = useRef(null);
  const abortController = useRef(new AbortController());

  const [userData, setUserData] = useState(null);
  const [userLikedLists, setUserLikedLists] = useState(null);
  const [listName, setListName] = useState('');
  const [listImage, setListImage] = useState(null);
  const [listScore, setListScore] = useState(0);
  const [listLikedBy, setListLikedBy] = useState([]);
  const [listCategory, setListCategory] = useState('');
  const [genreFilter, setGenreFilter] = useState('All');
  const [selectedEntriesData, setSelectedEntriesData] = useState([]);
  const [ownerIds, setOwnerIds] = useState([]);
  const [ownerData, setOwnerData] = useState([]);
  const [imageHover, setImageHover] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isProcessingLink, setIsProcessingLink] = useState(false);
  const [invalidLinkAlertMessage, setInvalidLinkAlertMessage] = useState(false);
  const [duplicacyAlertMessage, setDuplicacyAlertMessage] = useState(false);
  const [failureAlertMessage, setFailureAlertMessage] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedListTitle, setEditedListTitle] = useState(listName);
  const [isListPublic, setIsListPublic] = useState(false);
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [showAnnotations, setShowAnnotations] = useState(true);
  const [genreFiltersAvailable, setGenreFiltersAvailable] = useState([]);
  const [filteredByGenreData, setFilteredByGenreData] = useState([])
  const [shareImageNeedsUpdate, setShareImageNeedsUpdate] = useState(false);
  const [selectionCurrentDropdown, setSelectionCurrentDropdown] = useState('')
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [commentSectionId, setCommentSectionId] = useState(null);
  const [commentCount, setCommentCount] = useState(0);
  const [deleteEntryDialogOpen, setDeleteEntryDialogOpen] = useState(false);
  const [deleteEntryStatus, setDeleteEntryStatus] = useState(false);
  const [deleteEntryIndex, setDeleteEntryIndex] = useState(false);
  const [deleteListDialogOpen, setDeleteListDialogOpen] = useState(false);
  const [deleteListStatus, setDeleteListStatus] = useState(false);
  const [confirmButtonText, setConfirmButtonText] = useState("Confirm");
  const [groupId, setGroupId] = useState(null);

  // User data
  useEffect(() => {
    const fetchUserData = async (userId) => {
      try {
        const q = query(collection(db, 'members'), where('id', '==', userId));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const userDoc = querySnapshot.docs[0];
          const userDataFromDB = userDoc.data();
          setUserData(userDataFromDB);
          setUserLikedLists(userDataFromDB.likedLists || []);
          await updateUserStreak(userDataFromDB)
        } else {
          console.error('User not found');
          navigate("/500");
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        if (user.emailVerified || requireEmailVerification === "false") {
          fetchUserData(user.uid);
        } else {
          await signOut(auth);
          navigate("/login");
        }
      }
    });

    return () => {
      unsubscribe();
    };
  }, [navigate, requireEmailVerification]);

  // List data
  useEffect(() => {
    const fetchListData = async () => {
      try {
        const q = query(collection(db, 'lists'), where('id', '==', listId));
        const docRef = doc(db, 'lists', listId);
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0];
          setIsListPublic(doc.data().isPublic)
          setListName(doc.data().title);
          setListImage(doc.data().listImage);
          setListScore(doc.data().score);
          setListLikedBy(doc.data().likedBy || []);
          setListCategory(doc.data().category);
          setGroupId(doc.data().groupId ? doc.data().groupId : null)
          const originalData = doc.data().data;
          const reversedData = [...originalData].reverse();
          setSelectedEntriesData(reversedData);
          const memberIds = doc.data().memberId;
          setOwnerIds(memberIds);
          setOwnerData(await getOwnerDataFromOwnerIds(memberIds));
          if (doc.data().commentId) {
            setCommentSectionId(doc.data().commentId);
          } else {
            let localCommentId = uuidv4();
            setCommentSectionId(localCommentId);
            await updateDoc(docRef, { commentId: localCommentId });
          }
        } else {
          console.error("List not found");
          navigate('/');
        }
      } catch (error) {
        console.error('Error fetching list data:', error);
      } finally {
        setPageIsLoading(false);
      }
    };

    fetchListData();
  }, [listId, navigate]);

  // Cleanup function
  useEffect(() => {
    return () => {
      abortController.current.abort();
      abortController.current = new AbortController();
    };
  }, [searchTerm]);

  // Title editing
  const handleEditTitleStart = () => {
    setEditedListTitle(listName);
    setIsEditingTitle(true);
  };

  const handleEditTitleEnd = async () => {
    try {
      const listRef = doc(db, 'lists', listId);
      await updateDoc(listRef, {
        title: editedListTitle,
        lastUpdated: Date.now()
      });
      setListName(editedListTitle);
    } catch (error) {
      console.error('Error updating list title:', error.message);
    }
    setIsEditingTitle(false);
  };

  // List deletion
  useEffect(() => {
    const deleteListAfterConfirmation = async () => {
      const stillExists = await deleteList(listId);
      if (!stillExists) {
        navigate("/user");
      } else {
        setConfirmButtonText("Retry");
      }
    }

    if (deleteListStatus) {
      deleteListAfterConfirmation();
    }
  }, [deleteListStatus, listId, navigate])

  // Share image
  useEffect(() => {
    const handleUpdateShareImage = async () => {
      if (ownerIds && userData && ownerIds.includes(userData.id)) {
        await updateShareImage(listId);
      }
    }

    handleUpdateShareImage();
  }, [listId, listName, listCategory, listImage, ownerData, ownerIds, userData])

  useEffect(() => {
    const handleUpdateShareImage = async () => {
      if (shareImageNeedsUpdate || selectedEntriesData.length < 3) {
        if (ownerIds && userData && ownerIds.includes(userData.id)) {
          await updateShareImage(listId);
        }
        setShareImageNeedsUpdate(false);
      }
    }

    handleUpdateShareImage();
  }, [listId, shareImageNeedsUpdate, selectedEntriesData, ownerIds, userData])

  // List-level buttons
  const handleListImageChange = async (event) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      const storageRef = ref(storage, `listImages/${listId}`);
      const snapshot = await uploadBytes(storageRef, selectedImage);
      const downloadURL = await getDownloadURL(snapshot.ref);
      setListImage(downloadURL);
      setImageHover(false);
      try {
        const listRef = doc(db, 'lists', listId);
        await updateDoc(listRef, {
          listImage: downloadURL,
          lastUpdated: Date.now()
        });
      } catch (error) {
        console.error('Error updating list image:', error.message);
      }
    }
  }

  const handleVisibilityToggle = async () => {
    try {
      const listRef = doc(db, 'lists', listId);
      await updateDoc(listRef, {
        isPublic: !isListPublic,
        lastUpdated: Date.now()
      });
      let isListPublicCopy = isListPublic;
      setIsListPublic(!isListPublic);
      if (!isListPublicCopy) {
        await sendNewListCreationNotifications(listId, userData)
      } else {
        await deleteListNotificationsAndUpdateUnseenCount(listId);
      }
    } catch (error) {
      console.error('Error updating switch toggle:', error.message);
    }
  };

  // Searchable categories
  useEffect(() => {
    const fetchMovies = async () => {
      try {
        const response = await fetch(`https://api.themoviedb.org/3/search/movie?query=${searchTerm}&api_key=${moviesApiKey}`);
        const data = await response.json();
        setSearchResults(data.results);
      } catch (error) {
        console.error('Error fetching movies:', error);
      }
    };

    const fetchTVShows = async () => {
      try {
        const response = await fetch(`https://api.themoviedb.org/3/search/tv?query=${searchTerm}&api_key=${moviesApiKey}`);
        let data = await response.json();
        data.results = data.results.map(result => ({
          ...result,
          title: result.name,
        }));
        setSearchResults(data.results);
      } catch (error) {
        console.error('Error fetching shows:', error);
      }
    };

    const fetchBooks = async () => {
      try {
        const controller = abortController.current;

        // Fetch books by title
        const titleResponse = await fetch(
          `https://www.googleapis.com/books/v1/volumes?q=intitle:${searchTerm}&maxResults=30`,
          { signal: controller.signal }
        );

        // Fetch books by author
        const authorResponse = await fetch(
          `https://www.googleapis.com/books/v1/volumes?q=inauthor:${searchTerm}&maxResults=30`,
          { signal: controller.signal }
        );

        if (!controller.signal.aborted) {
          const titleData = await titleResponse.json();
          const authorData = await authorResponse.json();
          let combinedResults = [];
          if (titleData.items && authorData.items) {
            combinedResults = [
              ...titleData.items,
              ...authorData.items
            ];
          } else {
            combinedResults = titleData.items || authorData.items || [];
          }

          const filteredResults = combinedResults.filter((item, index, self) => {
            const currentIndex = self.findIndex((otherItem) => (
              item.volumeInfo && item.volumeInfo.title && otherItem.volumeInfo && otherItem.volumeInfo.title && item.volumeInfo.title === otherItem.volumeInfo.title &&
              item.volumeInfo.authors && otherItem.volumeInfo.authors &&
              areAuthorsEqual(item.volumeInfo.authors, otherItem.volumeInfo.authors)
            ));
            return currentIndex === index;
          });

          // Freestyle sorting based on averageRating * ratingsCount
          const sortedResults = filteredResults.sort((a, b) => {
            const aScore = (a.volumeInfo.averageRating || 0) * (a.volumeInfo.ratingsCount || 0);
            const bScore = (b.volumeInfo.averageRating || 0) * (b.volumeInfo.ratingsCount || 0);

            // Sort in descending order of the calculated score
            return bScore - aScore;
          });

          // Get the top 10 results
          const topResults = sortedResults.slice(0, 10);
          if (topResults.length !== 0) {
            setSearchResults(topResults);
          }
        }
      } catch (error) {
        console.error('Error fetching books:', error);
      }
    };

    const areAuthorsEqual = (authors1, authors2) => {
      if (authors1.length !== authors2.length) {
        return false;
      }
      const sortedAuthors1 = authors1.slice().sort();
      const sortedAuthors2 = authors2.slice().sort();
      return sortedAuthors1.every((author, index) => author === sortedAuthors2[index]);
    };

    const fetchVideoGames = async () => {
      try {
        const controller = abortController.current;
        const response = await fetch(
          `https://api.rawg.io/api/games?key=${gamesApiKey}&search=${searchTerm}`,
          { signal: controller.signal }
        );
        if (!controller.signal.aborted) {
          const data = await response.json();
          setSearchResults(data.results);
        }
      } catch (error) {
        console.error('Error fetching video games:', error);
      }
    }

    if (searchTerm) {
      switch (listCategory) {
        case "Movies":
          fetchMovies();
          break;
        case "TV Shows":
          fetchTVShows();
          break;
        case "Books":
          fetchBooks();
          break;
        case "Video Games":
          fetchVideoGames();
          break;
        default:
          console.error("Unsupported category: " + listCategory);
          navigate("/500");
      }
    }
  }, [searchTerm, moviesApiKey, listCategory, navigate, booksApiKey, gamesApiKey]);

  const handleSearchChange = (event, value) => {
    setSearchTerm(value);
  };

  const handleGenreFilter = (event) => {
    setGenreFilter(event.target.value);
  }

  const parseGameGenresAndTags = (value) => {
    let genres = [];
    if (value.tags && value.tags.length !== 0) {
      genres.push.apply(genres, value.tags.filter(value => value.name === "Singleplayer" || value.name === "Multiplayer").map(value => value.name));
    }
    if (value.genres && value.genres.length !== 0) {
      genres.push.apply(genres, value.genres.map(value => value.name));
    }
    if (genres.length === 0) {
      return null;
    }
    return genres;
  }

  const handleSearchSelect = async (event, value) => {
    if (value !== null) {
      let mapToAdd = {};
      switch (listCategory) {
        case "Movies":
          try {
            const movieDetailsResponse = await fetch(`https://api.themoviedb.org/3/movie/${value.id}?api_key=${moviesApiKey}`);
            const movieDetailsData = await movieDetailsResponse.json();
            mapToAdd = {
              movieName: value.title,
              posterpath: value.poster_path,
              runtime: movieDetailsData.runtime,
              genres: movieDetailsData.genres,
              releaseDate: value.release_date,
              synopsis: value?.overview || null,
              id: value.id,
              original_language: value.original_language,
              annotation: "",
              rating: null,
              checked: false,
              checkedTime: null,
              createdTime: Date.now(),
              lastUpdated: Date.now(),
              addedBy: ownerIds.length > 1 ? userData.id : null
            };
            let addStatus = await addListEntry(listId, mapToAdd);
            if (addStatus === "success") {
              setSelectedEntriesData((prevSelectedEntriesData) => [mapToAdd, ...prevSelectedEntriesData]);
            }
            setDuplicacyAlertMessage(addStatus === "duplicate");
            setFailureAlertMessage(addStatus === "failure");
          } catch (error) {
            console.error("Error fetching movie details:", error);
          }
          break;
        case "TV Shows":
          try {
            const showDetailsResponse = await fetch(`https://api.themoviedb.org/3/tv/${value.id}?api_key=${moviesApiKey}`);
            const showDetailsData = await showDetailsResponse.json();
            mapToAdd = {
              showName: value.title,
              posterpath: value.poster_path,
              numberEpisodes: showDetailsData.number_of_episodes,
              numberSeasons: showDetailsData.number_of_seasons,
              episodeRuntime: showDetailsData.episode_run_time,
              genres: showDetailsData.genres,
              original_language: value.original_language,
              synopsis: showDetailsData?.overview || null,
              id: value.id,
              annotation: "",
              rating: null,
              checked: false,
              checkedTime: null,
              createdTime: Date.now(),
              lastUpdated: Date.now(),
              addedBy: ownerIds.length > 1 ? userData.id : null
            };
            let addStatus = await addListEntry(listId, mapToAdd);
            if (addStatus === "success") {
              setSelectedEntriesData((prevSelectedEntriesData) => [mapToAdd, ...prevSelectedEntriesData]);
            }
            setDuplicacyAlertMessage(addStatus === "duplicate");
            setFailureAlertMessage(addStatus === "failure");
          } catch (error) {
            console.error("Error fetching show details:", error);
          }
          break;
        case "Books":
          try {
            const mapToAdd = {
              bookName: value.volumeInfo?.title || null,
              posterpath: value.volumeInfo?.imageLinks?.thumbnail || null,
              authors: value.volumeInfo?.authors || null,
              publishDate: value.volumeInfo?.publishedDate || null,
              pageCount: value.volumeInfo?.pageCount || null,
              genres: value.volumeInfo?.categories || null,
              synopsis: value.volumeInfo?.description || null,
              id: value.id || null,
              annotation: "",
              rating: null,
              checked: false,
              checkedTime: null,
              createdTime: Date.now(),
              lastUpdated: Date.now(),
              addedBy: ownerIds.length > 1 ? userData.id : null
            };
            let addStatus = await addListEntry(listId, mapToAdd);
            if (addStatus === "success") {
              setSelectedEntriesData((prevSelectedEntriesData) => [mapToAdd, ...prevSelectedEntriesData]);
            }
            setDuplicacyAlertMessage(addStatus === "duplicate");
            setFailureAlertMessage(addStatus === "failure");
          } catch (error) {
            console.error("Error updating book details:", error);
          }
          break;
        case "Video Games":
          try {
            const mapToAdd = {
              gameName: value.name || "Unknown",
              posterpath: value.background_image || null,
              platforms: value.platforms && value.platforms.length !== 0 ? value.platforms.map(value => value.platform.name) : null,
              genres: parseGameGenresAndTags(value),
              slug: value.slug || null,
              id: value.id || null,
              annotation: "",
              rating: null,
              checked: false,
              checkedTime: null,
              createdTime: Date.now(),
              lastUpdated: Date.now(),
              addedBy: ownerIds.length > 1 ? userData.id : null
            };
            let addStatus = await addListEntry(listId, mapToAdd);
            if (addStatus === "success") {
              setSelectedEntriesData((prevSelectedEntriesData) => [mapToAdd, ...prevSelectedEntriesData]);
            }
            setDuplicacyAlertMessage(addStatus === "duplicate");
            setFailureAlertMessage(addStatus === "failure");
          } catch (error) {
            console.error("Error updating game details:", error);
          }
          break;
        default:
          console.error("Unsupported category: " + listCategory);
          navigate("/500");
      }
    }
    setSearchTerm('');
    const ele = document.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
    if (ele) ele.click();
  };

  // Single entry categories
  const handleSingleEntryAdd = async (event, value) => {
    if (typeof value !== "undefined" && value !== null) {
      document.getElementById("singleEntryField").value = '';
      let mapToAdd = {};
      switch (listCategory) {
        case "Web Pages":
          try {
            setIsProcessingLink(true);
            const formData = new FormData();
            formData.append("page-url", value);
            const options = {
              method: 'POST',
              body: formData
            };
            const articleDetailsResponse = await fetch(`https://curation-engine-585e445f0947.herokuapp.com/extract/webpage`, options);
            const articleDetailsData = await articleDetailsResponse.json();
            if ("error" in articleDetailsData) {
              setIsProcessingLink(false);
              setInvalidLinkAlertMessage(true);
              return;
            }
            if (articleDetailsData.image_url === "Unknown") {
              const randomImageNumber = Math.floor(Math.random() * 1000);
              articleDetailsData.image_url = `https://picsum.photos/200/200?random=${randomImageNumber}`
            }
            mapToAdd = {
              articleName: articleDetailsData.main_title,
              author: articleDetailsData.author,
              imagePath: articleDetailsData.image_url,
              url: articleDetailsData.updated_link,
              id: uuidv4(),
              annotation: "",
              rating: null,
              checked: false,
              checkedTime: null,
              createdTime: Date.now(),
              lastUpdated: Date.now(),
              addedBy: ownerIds.length > 1 ? userData.id : null
            }
            let addStatus = await addListEntry(listId, mapToAdd);
            setIsProcessingLink(false);
            if (addStatus === "success") {
              setSelectedEntriesData((prevSelectedEntriesData) => [mapToAdd, ...prevSelectedEntriesData]);
            }
            setDuplicacyAlertMessage(addStatus === "duplicate");
            setFailureAlertMessage(addStatus === "failure");
            setInvalidLinkAlertMessage(false);
          } catch (error) {
            console.error("Article API call failed: " + error);
            setIsProcessingLink(false);
            setInvalidLinkAlertMessage(true);
            return;
          }
          break;
        case "Freestyle":
          try {
            mapToAdd = {
              title: value,
              subtitle: "",
              imagePath: `https://picsum.photos/200/200?random=${Math.floor(Math.random() * 1000)}`,
              url: "",
              id: uuidv4(),
              annotation: "",
              rating: null,
              checked: false,
              checkedTime: null,
              createdTime: Date.now(),
              lastUpdated: Date.now(),
              addedBy: ownerIds.length > 1 ? userData.id : null
            }
            let addStatus = await addListEntry(listId, mapToAdd);
            if (addStatus === "success") {
              setSelectedEntriesData((prevSelectedEntriesData) => [mapToAdd, ...prevSelectedEntriesData]);
            }
            setDuplicacyAlertMessage(addStatus === "duplicate");
            setFailureAlertMessage(addStatus === "failure");
          } catch (error) {
            console.error("Error adding to freestyle list:" + error);
            return;
          }
          break;
        default:
          console.error("Unsupported category: " + listCategory);
          navigate("/500");
      }
    }
  }

  // Cards
  useEffect(() => {
    const deleteEntryAfterConfirmation = async (index) => {
      try {
        const previousLength = selectedEntriesData.length;
        await removeListEntry(listId, selectedEntriesData[index]);
        setSelectedEntriesData((prevSelectedEntryData) => {
          const updatedEntriesData = [...prevSelectedEntryData];
          updatedEntriesData.splice(index, 1);
          return updatedEntriesData;
        });
        if (index === 0 || index === 1 || index === previousLength - 1 || index === previousLength - 2) {
          setShareImageNeedsUpdate(true);
        }
      } finally {
        setDeleteEntryStatus(false);
        setDeleteEntryDialogOpen(false);
      }
    }

    if (deleteEntryStatus) {
      deleteEntryAfterConfirmation(deleteEntryIndex);
    }
  }, [deleteEntryStatus, deleteEntryIndex, listId, selectedEntriesData])

  const handleRemoveEntry = async (index) => {
    setConfirmButtonText("Confirm");
    setDeleteEntryIndex(index);
    setDeleteEntryDialogOpen(true);
  };

  const handleCategoriesUpdate = async (index, tags) => {
    try {
      const listDocRef = doc(db, 'lists', listId);
      const listDocSnapshot = await getDoc(listDocRef);
      if (listDocSnapshot.exists()) {
        let originalData = listDocSnapshot.data().data;
        originalData[originalData.length - index - 1].genres = tags;
        originalData[originalData.length - index - 1].lastUpdated = Date.now();
        const reversedData = [...originalData].reverse();
        await updateDoc(listDocRef, {
          data: originalData,
          lastUpdated: Date.now()
        });
        setSelectedEntriesData(reversedData);
      } else {
        console.error('Error updating categories');
      }
    } catch (error) {
      console.error('Error updating categories:', error);
    }
  }

  useEffect(() => {
    let allGenresSet = new Set();
    switch (listCategory) {
      case "Movies":
      case "TV Shows":
        for (let i = 0; i < selectedEntriesData.length; i++) {
          const entryGenres = selectedEntriesData[i].genres;
          if (entryGenres) {
            entryGenres.forEach((genre) => {
              allGenresSet.add(genre.name);
            });
          }
        }
        break;
      case "Books":
      case "Web Pages":
      case "Video Games":
      case "Freestyle":
        for (let i = 0; i < selectedEntriesData.length; i++) {
          const entryGenres = selectedEntriesData[i].genres;
          if (entryGenres) {
            entryGenres.forEach((genre) => {
              allGenresSet.add(genre);
            });
          }
        }
        break;
      default:
        break;
    }
    allGenresSet.add("All");
    const uniqueGenresArray = Array.from(allGenresSet);
    const allIndex = uniqueGenresArray.indexOf("All");
    if (allIndex !== -1) {
      uniqueGenresArray.splice(allIndex, 1);
      uniqueGenresArray.unshift("All");
    }
    setGenreFiltersAvailable(uniqueGenresArray);
  }, [selectedEntriesData, listCategory]);

  useEffect(() => {
    let updatedArr = [];
    if (genreFilter === "All") {
      setFilteredByGenreData(selectedEntriesData);
    } else {
      for (let i = 0; i < selectedEntriesData.length; i++) {
        const genres = selectedEntriesData[i].genres;
        if (genres) {
          let isGenrePresent;
          switch (listCategory) {
            case "Movies":
            case "TV Shows":
              isGenrePresent = genres.some((genre) => genre.name === genreFilter);
              break;
            case "Books":
            case "Web Pages":
            case "Video Games":
            case "Freestyle":
              isGenrePresent = genres.some((genre) => genre === genreFilter);
              break;
            default:
              break;
          }
          if (isGenrePresent) {
            updatedArr.push(selectedEntriesData[i]);
          }
        }
      }
      setFilteredByGenreData(updatedArr);
    }
  }, [genreFilter, selectedEntriesData, listCategory]);

  const handleKeyPress = async (event) => {
    if (event.key === 'Enter') {
      setIsDropdownOpen(true);
      let fetchUrl = `https://api.content.tripadvisor.com/api/v1/location/search?language=en&key=${restaurantsApiKey}&searchQuery=${searchQuery}&category=restaurants`;
      try {
        const response = await fetch(fetchUrl, {
          method: 'GET', // Method is GET
          headers: {
            'Content-Type': 'application/json', // Indicates that your client expects to receive JSON
          },
          Referer: 'http://www.palette.build'
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        const finalData = data.data;
        const finalResults = finalData.splice(0, 5);
        setSearchResults(finalResults)
      } catch (error) {
        console.error("Failed to fetch the restaurants data: ", error);
      }
    }
  };

  const handleQueryChange = (event) => {
    setSearchQuery(event.target.value)
  }

  const handleSingleDropdownSelect = (event) => {
    setSelectionCurrentDropdown(event.target.value);
    setIsDropdownOpen(false);
    setSearchQuery('');
  };

  return (
    <>
      <Helmet>
        <title>{listName} - Palette</title>
        <meta property="og:title" content={`${listName} - Palette`} />
        <link rel="canonical" href={"https://palette.build" + location.pathname} />
        <meta property="og:url" content={"https://palette.build" + location.pathname} />
        <meta property="twitter:image" content={shareImageSquareURL} />
        <meta property="og:image" content={shareImageSquareURL} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="400" />*
        <meta property="og:image" content={shareImageRectURL} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:description" content={`Check out this ${getTextForListOfCategory(listCategory)} curated by ${getOwnerStringFromOwnerData(ownerData)}!`} />
        <meta name="keywords" content={`palette, list, social media, curated list, ${listCategory.toLowerCase()}, ${listName}`} />
      </Helmet>
      {userData ? (
        <DSNavbar isLoggedIn={true} userData={userData} />
      ) : (
        <DSNavbar isLoggedIn={false} />
      )}
      {pageIsLoading ? (
        <DSContainer>
          <div className="circularProgress">
            <CircularProgress />
            <DSText fontSize="1.2em" color="white" boldVar="400" marginBottom="15px">
              Loading...
            </DSText>
          </div>
        </DSContainer>
      ) : (isListPublic || (ownerIds && userData && ownerIds.includes(userData.id))) || (userData && adminEmails.includes(userData.email)) ? (
        <DSContainer justifyContent="start">
          <div className="cardContainer" style={{ marginBottom: "15px" }}>
            <div className="listDetails">
              <div className="listDetailsContainer">
                <div className="listDetailsText">
                  {isEditingTitle ? (
                    <TextField
                      className="editTitleField"
                      variant="standard"
                      value={editedListTitle}
                      onChange={(e) => setEditedListTitle(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleEditTitleEnd();
                        }
                      }}
                      onBlur={handleEditTitleEnd}
                      autoFocus
                    />
                  ) : (
                    <DSText fontSize="1.8em" boldVar="600" marginBottom="8px">
                      {listName}
                    </DSText>
                  )}
                  <DSText fontSize="1.05em" marginBottom="17px">
                    by <OwnerStringWithLink ownerData={ownerData} />
                  </DSText>
                  {ownerIds && userData && ownerIds.includes(userData.id)
                    ? (
                      <>
                        {!groupId &&
                          <div className="listUpdateButton" onClick={handleEditTitleStart}>
                            <EditIcon className="listUpdateButtonIcon" />
                            <div className='listUpdateButtonText'>Edit Title</div>
                          </div>
                        }
                        <div className="listUpdateButton" onClick={handleVisibilityToggle}>
                          {isListPublic ? (
                            <VisibilityIcon className="listUpdateButtonIcon" />
                          ) : (
                            <VisibilityOffIcon className="listUpdateButtonIcon" />
                          )}
                          <div className='listUpdateButtonText'>{isListPublic ? 'Public' : 'Private'}</div>
                        </div>
                        <div className="listUpdateButton" onClick={() => setDeleteListDialogOpen(true)}>
                          <ClearIcon className="listUpdateButtonIcon" />
                          <div className='listUpdateButtonText'>Delete</div>
                        </div>
                      </>
                    ) : (
                      <ListButtons
                        listId={listId}
                        listName={listName}
                        listCategory={listCategory}
                        isListPublic={isListPublic}
                        selectedEntriesData={selectedEntriesData}
                        ownerIds={ownerIds}
                        ownerData={ownerData}
                        userData={userData}
                        userLikedLists={userLikedLists}
                        setUserLikedLists={setUserLikedLists}
                        listLikedBy={listLikedBy}
                        setListLikedBy={setListLikedBy}
                        listScore={listScore}
                        getListScoreFromListData={getListScoreFromListData}
                        itemsDivRef={itemsDivRef}
                        commentsDivRef={commentsDivRef}
                        commentCount={commentCount}
                      />
                    )
                  }
                </div>
                <div
                  onClick={() => {
                    if (ownerIds && userData && ownerIds.includes(userData.id)) {
                      document.getElementById('listImage').click()
                    }
                  }}
                  onMouseOver={() => {
                    if (ownerIds && userData && ownerIds.includes(userData.id)) {
                      setImageHover(true);
                    }
                  }}
                  onMouseOut={() => setImageHover(false)}
                  className="listImageContainer">
                  <img
                    className="listImage"
                    src={listImage ? listImage : getListImageFromEntryData(selectedEntriesData[0], listCategory)}
                    alt="list"
                    style={{ WebkitFilter: imageHover ? "brightness(50%)" : "brightness(100%)" }}
                  />
                  <input
                    type="file"
                    id="listImage"
                    name="listImage"
                    accept="image/*"
                    onChange={handleListImageChange}
                    style={{ display: "none" }}
                  />
                  {imageHover && <UploadIcon className="imageUploadIcon" />}
                </div>
              </div>
              {ownerIds && userData && ownerIds.includes(userData.id) &&
                <ListButtons
                  listId={listId}
                  listName={listName}
                  listCategory={listCategory}
                  isListPublic={isListPublic}
                  selectedEntriesData={selectedEntriesData}
                  ownerIds={ownerIds}
                  ownerData={ownerData}
                  userData={userData}
                  userLikedLists={userLikedLists}
                  setUserLikedLists={setUserLikedLists}
                  listLikedBy={listLikedBy}
                  setListLikedBy={setListLikedBy}
                  listScore={listScore}
                  getListScoreFromListData={getListScoreFromListData}
                  itemsDivRef={itemsDivRef}
                  commentsDivRef={commentsDivRef}
                  commentCount={commentCount}
                />
              }
              {groupId &&
                <Link to={`/challenge/${groupId}`} className="challengeBacklink">
                  <div className="challengeBacklinkText">
                    <ArrowCircleLeftIcon className="challengeBacklinkIcon" />
                    This list is part of a challenge. Check it out!
                  </div>
                </Link>
              }
            </div>
            {ownerIds && userData && ownerIds.includes(userData.id) &&
              <>
                <div className="edit">
                  {listCategory === "Movies" ? (
                    <div className='inputContainer'>
                      <AutocompleteField
                        searchResults={searchResults}
                        handleSearchChange={handleSearchChange}
                        handleSearchSelect={handleSearchSelect}
                        listCategory={listCategory}
                        renderOption={(props, option) => (
                          <li {...props}>
                            <img src={option.poster_path ? `https://image.tmdb.org/t/p/w300/${option.poster_path}` : 'https://firebasestorage.googleapis.com/v0/b/list-curation.appspot.com/o/unavailable-300-400.png?alt=media&token=2b642515-9ca0-40a6-b93f-5171b747c67e'} alt={option.title} className="dropdownImg" />
                            <div style={{ fontFamily: "'Outfit', sans-serif" }}>
                              <span style={{ fontWeight: "500" }}>{option.title} <br /></span>
                              <span style={{ fontSize: "0.92em", }}>{formatDate(option.release_date)}</span>
                            </div>
                          </li>
                        )} />
                      {duplicacyAlertMessage && <DSText fontSize="0.85em" color="#cfcfcf" marginTop="7px">This movie is already present in the list!</DSText>}
                      {failureAlertMessage && <DSText fontSize="0.85em" color="#cfcfcf" marginTop="7px">There was an unexpected error in adding this movie!</DSText>}
                    </div>
                  ) : listCategory === "TV Shows" ? (
                    <div className='inputContainer'>
                      <AutocompleteField
                        searchResults={searchResults}
                        handleSearchChange={handleSearchChange}
                        handleSearchSelect={handleSearchSelect}
                        listCategory={listCategory}
                        displayListCategory={"TV shows"}
                        renderOption={(props, option) => (
                          <li {...props}>
                            <img src={option.poster_path ? `https://image.tmdb.org/t/p/w300/${option.poster_path}` : 'https://firebasestorage.googleapis.com/v0/b/list-curation.appspot.com/o/unavailable-300-400.png?alt=media&token=2b642515-9ca0-40a6-b93f-5171b747c67e'} alt={option.title} className="dropdownImg" />
                            <div style={{ fontFamily: "'Outfit', sans-serif" }}>
                              <span style={{ fontWeight: "500" }}>{option.title} <br /></span>
                              {option.first_air_date && <span style={{ fontSize: "0.92em", }}>First Aired: {option.first_air_date.slice(0, 4)}</span>}
                            </div>
                          </li>
                        )} />
                      {duplicacyAlertMessage && <DSText fontSize="0.85em" color="#cfcfcf" marginTop="7px">This TV show is already present in the list!</DSText>}
                      {failureAlertMessage && <DSText fontSize="0.85em" color="#cfcfcf" marginTop="7px">There was an unexpected error in adding this TV show!</DSText>}
                    </div>
                  ) : listCategory === "Books" ? (
                    <div className='inputContainer'>
                      <div>
                        <AutocompleteField
                          searchResults={searchResults}
                          handleSearchChange={handleSearchChange}
                          handleSearchSelect={handleSearchSelect}
                          listCategory={listCategory}
                          renderOption={(props, option) => (
                            <li {...props}>
                              <img
                                src={option.volumeInfo && option.volumeInfo.imageLinks && option.volumeInfo.imageLinks.thumbnail ? option.volumeInfo.imageLinks.thumbnail : 'https://firebasestorage.googleapis.com/v0/b/list-curation.appspot.com/o/unavailable-300-400.png?alt=media&token=2b642515-9ca0-40a6-b93f-5171b747c67e'}
                                alt={option.volumeInfo && option.volumeInfo.title ? option.volumeInfo.title : "Unknown book"}
                                className="dropdownImg"
                              />
                              <div style={{ fontFamily: "'Outfit', sans-serif" }}>
                                <span style={{ fontWeight: "500" }}>{option.volumeInfo.title} <br /></span>
                                <span style={{ fontSize: "0.92em" }}>
                                  {option.volumeInfo && option.volumeInfo.authors
                                    ? getVisualListFromArray(option.volumeInfo.authors)
                                    : null}
                                </span>
                              </div>
                            </li>
                          )} />
                        {duplicacyAlertMessage && <DSText fontSize="0.85em" color="#cfcfcf" marginTop="7px">This book is already present in the list!</DSText>}
                        {failureAlertMessage && <DSText fontSize="0.85em" color="#cfcfcf" marginTop="7px">There was an unexpected error in adding this book!</DSText>}
                      </div>
                    </div>
                  ) : listCategory === "Video Games" ? (
                    <div className='inputContainer'>
                      <AutocompleteField
                        searchResults={searchResults}
                        handleSearchChange={handleSearchChange}
                        handleSearchSelect={handleSearchSelect}
                        listCategory={listCategory}
                        renderOption={(props, option) => (
                          <li {...props}>
                            <img src={option.background_image ? `${option.background_image}` : 'https://firebasestorage.googleapis.com/v0/b/list-curation.appspot.com/o/unavailable-300-400.png?alt=media&token=2b642515-9ca0-40a6-b93f-5171b747c67e'} alt={option.title} className="dropdownImgGames" />
                            <div style={{ fontFamily: "'Outfit', sans-serif" }}>
                              <div style={{ fontWeight: "500" }}>{option.name}</div>
                              {option.platforms && option.platforms.length !== 0 && <div style={{ fontSize: "0.8em", }}>Platforms: {getVisualListFromArray(option.platforms.map(value => value.platform.name))}</div>}
                              {option.genres && option.genres.length !== 0 && <div style={{ fontSize: "0.8em", }}>Genres: {getVisualListFromArray(option.genres.map(value => value.name))}</div>}
                            </div>
                          </li>
                        )} />
                      {duplicacyAlertMessage && <DSText fontSize="0.85em" color="#cfcfcf" marginTop="7px">This video game is already present in the list!</DSText>}
                      {failureAlertMessage && <DSText fontSize="0.85em" color="#cfcfcf" marginTop="7px">There was an unexpected error in adding this video game!</DSText>}
                    </div>
                  ) : listCategory === "Restaurants" ? (
                    <div>
                      <TextField
                        label="Press Enter"
                        onKeyDown={handleKeyPress}
                        value={searchQuery}
                        onChange={handleQueryChange}
                      />
                      {isDropdownOpen && (
                        <FormControl fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectionCurrentDropdown}
                            label="Options"
                            onChange={handleSingleDropdownSelect}
                            open={isDropdownOpen}
                          >
                            {searchResults.map((result, index) => (
                              <MenuItem key={index} value={result.location_id}>
                                {result.name}
                                <br />
                                {`${result.address_obj.city}, ${result.address_obj.country}`}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </div>
                  ) : listCategory === "Web Pages" ? (
                    <div className='inputContainer'>
                      <SingleEntryField category="web page" handleSingleEntryAdd={handleSingleEntryAdd} />
                      {isProcessingLink && <DSText fontSize="0.85em" color="#cfcfcf" marginTop="7px">Processing link...</DSText>}
                      {!isProcessingLink && invalidLinkAlertMessage && <DSText fontSize="0.85em" color="#cfcfcf" marginTop="7px">Invalid link.</DSText>}
                      {!isProcessingLink && !invalidLinkAlertMessage && duplicacyAlertMessage && <DSText fontSize="0.85em" color="#cfcfcf" marginTop="7px">This web page is already present in the list!</DSText>}
                      {!isProcessingLink && !invalidLinkAlertMessage && failureAlertMessage && <DSText fontSize="0.85em" color="#cfcfcf" marginTop="7px">There was an unexpected error in adding this web page!</DSText>}
                    </div>
                  ) : (
                    <div className='inputContainer'>
                      <SingleEntryField category="freestyle" handleSingleEntryAdd={handleSingleEntryAdd} />
                      {!isProcessingLink && !invalidLinkAlertMessage && duplicacyAlertMessage && <DSText fontSize="0.85em" color="#cfcfcf" marginTop="7px">This title is already present in the list!</DSText>}
                    </div>
                  )}
                </div>
              </>
            }
            <div className="middleLayer">
              {!(filteredByGenreData.length === 0) && (listCategory === "Movies" || listCategory === "TV Shows") && (
                <button className="recButton" onClick={() => scrollToTargetDiv(recommendationsDivRef)}>
                  <AssistantIcon className="recIcon" />
                  Rec<span className="hideMobile">ommendation</span>s
                </button>
              )}
              {(filteredByGenreData.some(map => (map.annotation && map.annotation !== "") || (map.rating && map.rating !== 0)) || (ownerIds && userData && ownerIds.includes(userData.id))) &&
                <button
                  onClick={() => {
                    setShowAnnotations(!showAnnotations);
                  }}
                  className='toggleButton'>
                  <ExpandCircleDownOutlined
                    className='toggleIcon'
                    style={{
                      cursor: showAnnotations ? "default" : "pointer",
                      transform: showAnnotations ? "rotate(180deg)" : "rotate(0deg)"
                    }} />
                  {showAnnotations ? "Collapse" : "Expand"}
                </button>
              }
              {!(!(filteredByGenreData.length === 0) && (listCategory === "Movies" || listCategory === "TV Shows")) && !(filteredByGenreData.some(map => (map.annotation && map.annotation !== "") || (map.rating && map.rating !== 0))) && <div></div>}
              <div className='filterFunctionality'>
                <FormControl
                  className='filterComponent'
                  fullWidth
                  sx={{
                    backgroundColor: 'white',
                    height: '40px',
                    borderRadius: '5px',
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none'
                    }
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={genreFilter}
                    label="Filter by Genre"
                    onChange={handleGenreFilter}
                    sx={{
                      height: '100%',
                      borderRadius: 'inherit',
                      fontFamily: "'Outfit', sans-serif !important"
                    }}
                  >
                    {genreFiltersAvailable.map((genreValue) => (
                      <MenuItem key={genreValue} value={genreValue} sx={{ fontFamily: "'Outfit', sans-serif !important" }}>
                        {genreValue}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div ref={itemsDivRef}>
              <>
                {filteredByGenreData.map((entryData, index) => {
                  if (listCategory === "Movies") {
                    return (
                      <MovieCard
                        key={entryData.id || uuidv4()}
                        index={index}
                        entryData={entryData}
                        userData={userData}
                        ownerIds={ownerIds}
                        handleRemoveEntry={handleRemoveEntry}
                        showAnnotations={showAnnotations}
                        setSelectedEntriesData={setSelectedEntriesData}
                        listId={listId}
                        isInGroupList={groupId ? true : false}
                        selectedEntriesData={selectedEntriesData}
                        isCurrentlyFiltered={!(selectedEntriesData === filteredByGenreData)}
                      />
                    );
                  } else if (listCategory === "TV Shows") {
                    return (
                      <ShowCard
                        key={entryData.id || uuidv4()}
                        index={index}
                        entryData={entryData}
                        userData={userData}
                        ownerIds={ownerIds}
                        handleRemoveEntry={handleRemoveEntry}
                        showAnnotations={showAnnotations}
                        setSelectedEntriesData={setSelectedEntriesData}
                        selectedEntriesData={selectedEntriesData}
                        listId={listId}
                        isInGroupList={groupId ? true : false}
                        isCurrentlyFiltered={!(selectedEntriesData === filteredByGenreData)}
                      />
                    );
                  } else if (listCategory === "Video Games") {
                    return (
                      <VideoGameCard
                        key={entryData.id || uuidv4()}
                        index={index}
                        entryData={entryData}
                        userData={userData}
                        ownerIds={ownerIds}
                        handleRemoveEntry={handleRemoveEntry}
                        showAnnotations={showAnnotations}
                        setSelectedEntriesData={setSelectedEntriesData}
                        selectedEntriesData={selectedEntriesData}
                        listId={listId}
                        isInGroupList={groupId ? true : false}
                        isCurrentlyFiltered={!(selectedEntriesData === filteredByGenreData)}
                      />
                    );
                  } else if (listCategory === "Web Pages") {
                    return (
                      <ArticleCard
                        key={entryData.id || uuidv4()}
                        index={index}
                        entryData={entryData}
                        userData={userData}
                        ownerIds={ownerIds}
                        handleRemoveEntry={handleRemoveEntry}
                        showAnnotations={showAnnotations}
                        handleCategoriesUpdate={handleCategoriesUpdate}
                        setSelectedEntriesData={setSelectedEntriesData}
                        selectedEntriesData={selectedEntriesData}
                        listId={listId}
                        isCurrentlyFiltered={!(selectedEntriesData === filteredByGenreData)}
                      />
                    );
                  } else if (listCategory === "Books") {
                    return (
                      <BookCard
                        key={entryData.id || uuidv4()}
                        index={index}
                        entryData={entryData}
                        userData={userData}
                        ownerIds={ownerIds}
                        handleRemoveEntry={handleRemoveEntry}
                        showAnnotations={showAnnotations}
                        setSelectedEntriesData={setSelectedEntriesData}
                        listId={listId}
                        isInGroupList={groupId ? true : false}
                        selectedEntriesData={selectedEntriesData}
                        isCurrentlyFiltered={!(selectedEntriesData === filteredByGenreData)}
                      />
                    );
                  } else if (listCategory === "Freestyle") {
                    return (
                      <FreestyleCard
                        key={entryData.id || uuidv4()}
                        index={index}
                        entryData={entryData}
                        userData={userData}
                        ownerIds={ownerIds}
                        handleRemoveEntry={handleRemoveEntry}
                        showAnnotations={showAnnotations}
                        handleCategoriesUpdate={handleCategoriesUpdate}
                        setSelectedEntriesData={setSelectedEntriesData}
                        listId={listId}
                        selectedEntriesData={selectedEntriesData}
                        isCurrentlyFiltered={!(selectedEntriesData === filteredByGenreData)}
                      />
                    );
                  }
                  return null;
                })}
              </>
            </div>
            <div>
              <div className="listCommentSection" ref={commentsDivRef}>
                <div className="listSectionTitleContainer">
                  <CommentIcon className="listSectionTitleIcon" />
                  <DSText fontSize="1.5em" boldVar="800">Comments</DSText>
                </div>
                <ListComments userData={userData} commentSectionId={commentSectionId} setCommentCount={setCommentCount} listId={listId} ownerData={ownerData} />
              </div>
            </div>
            {!(filteredByGenreData.length === 0) && (listCategory === "Movies" || listCategory === "TV Shows")
              ? (
                <div className="listBottomRecsSection" ref={recommendationsDivRef}>
                  <div className="listSectionTitleContainer">
                    <AssistantIcon className="listSectionTitleIcon" />
                    <DSText fontSize="1.5em" boldVar="800">Recommendations</DSText>
                  </div>
                  <ListRecommendations entryData={filteredByGenreData} userData={userData} ownerIds={ownerIds} listCategory={listCategory} moviesApiKey={moviesApiKey} setSelectedEntriesData={setSelectedEntriesData} listId={listId} />
                </div>
              ) : (
                <div style={{ height: '20px' }}></div>
              )
            }
            <ListShareRectangle listId={listId} listName={listName} listCategory={listCategory} listImage={listImage} ownerData={ownerData} selectedEntriesData={selectedEntriesData} />
            <ListShareSquare listId={listId} listName={listName} listCategory={listCategory} ownerData={ownerData} selectedEntriesData={selectedEntriesData} />
          </div>
          {userData && <FeatureRequestForm />}
          <DSConfirmDialog
            title={"Delete List"}
            text={"Are you sure you want to delete this list?"}
            open={deleteListDialogOpen}
            setOpen={setDeleteListDialogOpen}
            setStatus={setDeleteListStatus}
            confirmButtonText={confirmButtonText}
            setConfirmButtonText={setConfirmButtonText}
          />
          <DSConfirmDialog
            title={"Delete Card"}
            text={"Are you sure you want to delete this card?"}
            open={deleteEntryDialogOpen}
            setOpen={setDeleteEntryDialogOpen}
            setStatus={setDeleteEntryStatus}
            confirmButtonText={confirmButtonText}
            setConfirmButtonText={setConfirmButtonText}
          />
        </DSContainer>
      ) : (
        <DSContainer>
          <div className="card">
            <div className="privateListMessage">
              <DSText fontSize="1.2em" color="#645dfc" boldVar="400" marginBottom="15px">
                This list is currently private. Request access from {ownerIds && ownerIds.length === 1 ? " the owner: " : " an editor: "}
              </DSText>
              <DSText fontSize="1.1em" color="#645dfc" boldVar="500"><OwnerStringWithLink ownerData={ownerData} /></DSText>
            </div>
          </div>
        </DSContainer>
      )}
    </>
  );
};

export default ListPage;