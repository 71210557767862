import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { getUserDataFromUserId, formatTimeDifference } from '../../Utils';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import DSText from '../../DSSystem/DSText';
import DSConfirmDialog from '../../DSSystem/DSConfirmDialog';
import "./styles.css"

const DSComment = ({ userData, commentData, handleCommentDeletion, handleCommentEditing, scrollTo, deleted }) => {
  const [commenterData, setCommenterData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [newMessage, setNewMessage] = useState(commentData.message);
  const [deleteCommentDialogOpen, setDeleteCommentDialogOpen] = useState(false);
  const [deleteCommentStatus, setDeleteCommentStatus] = useState(false);
  const [confirmButtonText, setConfirmButtonText] = useState("Confirm");
  const textFieldRef = useRef(null);

  useEffect(() => {
    const fetchCommenterData = async () => {
      try {
        if (commentData.userId) {
          setCommenterData(await getUserDataFromUserId(commentData.userId));
        }
      } catch (error) {
        console.error('Error fetching commenter data:', error);
      }
    };

    fetchCommenterData();
  }, [commentData.userId]);

  useEffect(() => {
    const deleteListAfterConfirmation = async () => {
      try {
        await handleCommentDeletion(commentData.id);
      } catch (error) {
        console.error("Error deleting comment:", error);
        setConfirmButtonText("Retry");
      } finally {
        setDeleteCommentStatus(false);
        setDeleteCommentDialogOpen(false);
      }
    }

    if (deleteCommentStatus) {
      deleteListAfterConfirmation();
    }
  }, [deleteCommentStatus, handleCommentDeletion, commentData.id])

  useEffect(() => {
    if (isEditing && textFieldRef.current) {
      textFieldRef.current.focus();
      textFieldRef.current.selectionStart = textFieldRef.current.selectionEnd = textFieldRef.current.value.length;
    }
  }, [isEditing]);

  const card = (ref) => {
    if (ref && scrollTo) {
      ref.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  return (
    (commenterData || deleted)
      ? (
        <>
          <Card className="commentCard" ref={card}>
            {commenterData &&
              <Link to={`/${commenterData.username}`} style={{ alignSelf: 'flex-start' }}>
                <Avatar alt={commenterData.username} src={commenterData.profileImage} sx={{ width: 55, height: 55 }} />
              </Link>
            }
            {deleted && <Avatar alt={'Unknown User'} src={commenterData?.profileImage} sx={{ width: 55, height: 55 }} />}
            <div style={{ width: '100%' }}>
              <DSText fontSize="1.03em" color="#1e1e1e" boldVar="500">
                {commenterData &&
                  <>
                    <Link to={`/${commenterData.username}`} className="commentUserName">
                      {commenterData.firstName} {commenterData.lastName}
                    </Link>
                    <span className="commentTimeDiff">
                      {commentData.edited ? 'Edited ' : ''}
                      {formatTimeDifference(commentData.timestamp)}
                    </span>
                  </>
                }
                {deleted && <div className="commentUserName">Unknown User</div>}
              </DSText>
              {isEditing
                ? (
                  <>
                    <TextField
                      id="standard-multiline-static"
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                      multiline
                      variant="standard"
                      inputRef={textFieldRef}
                      sx={{
                        width: '100% !important',
                        marginTop: '7px !important',
                        padding: '0px !important'
                      }}
                      InputProps={{
                        spellCheck: 'false',
                        disableUnderline: true,
                        sx: {
                          fontFamily: "'Outfit', sans-serif !important",
                          fontSize: '0.93em',
                          padding: '6px 10px !important',
                          backgroundColor: 'transparent !important',
                          borderRadius: '5px'
                        },
                      }}
                    />
                    <div style={{ textAlign: 'right', marginTop: '10px' }}>
                      <button
                        className="cancelUpdateCommentButton"
                        onClick={() => {
                          setNewMessage(commentData.message);
                          setIsEditing(false);
                        }}
                      >
                        <ClearIcon className="cancelUpdateCommentIcon" />
                        Cancel
                      </button>
                      <button
                        className="updateCommentButton"
                        onClick={() => {
                          if (newMessage !== commentData.message) {
                            handleCommentEditing(commentData.id, newMessage);
                          }
                          setIsEditing(false);
                        }}
                      >
                        <CheckIcon className="updateCommentIcon" />
                        Update
                      </button>
                    </div>
                  </>
                ) : (
                  <DSText fontSize="0.93em" color="#1e1e1e" marginTop="5px">
                    <span style={{ whiteSpace: "pre-wrap" }}>{commentData.message}</span>
                  </DSText>
                )
              }
            </div>
            {commenterData && userData && userData.id === commenterData.id && (
              <>
                <ClearIcon
                  className="deleteCommentIcon"
                  onClick={(event) => {
                    event.preventDefault();
                    setDeleteCommentDialogOpen(true);
                  }}
                />
                {!isEditing &&
                  <EditIcon className="editCommentIcon" onClick={(event) => {
                    event.preventDefault();
                    setIsEditing(true);
                  }} />
                }
              </>
            )}
          </Card>
          <DSConfirmDialog
            title={"Delete Comment"}
            text={"Are you sure you want to delete this comment?"}
            open={deleteCommentDialogOpen}
            setOpen={setDeleteCommentDialogOpen}
            setStatus={setDeleteCommentStatus}
            confirmButtonText={confirmButtonText}
            setConfirmButtonText={setConfirmButtonText}
          />
        </>
      ) : (
        <></>
      )
  );
};

export default DSComment;