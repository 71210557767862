import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { db, auth } from "../../firebase";
import { collection, query, where, getDocs } from 'firebase/firestore';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { Helmet } from 'react-helmet';
import DSContainer from '../../DSSystem/DSContainer';
import DSText from '../../DSSystem/DSText';
import DSNavbar from '../../DSSystem/DSNavbar';
import DiscoverTab from './Discover';
import FollowingListsTab from './FollowingLists';
import FeatureRequestForm from '../FeatureRequestForm';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { CircularProgress } from '@mui/material';
import UserAutoComplete from './UserAutoComplete';
import { updateUserStreak } from '../../Utils';
import './styles.css';

const ExplorePage = () => {
    const requireEmailVerification = process.env.REACT_APP_REQUIRE_EMAIL_VERIFICATION;
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [userData, setUserData] = useState(null);
    const [likedLists, setLikedLists] = useState([]);
    const [followingData, setFollowingData] = useState(null);
    const [isFollowingLoading, setIsFollowingLoading] = useState(true);
    const [isFollowingTab, setIsFollowingTab] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            const fetchUserData = async (userId) => {
                try {
                    const q = query(collection(db, 'members'), where('id', '==', userId));
                    const querySnapshot = await getDocs(q);
                    if (!querySnapshot.empty) {
                        const userDoc = querySnapshot.docs[0];
                        const userDataFromDB = userDoc.data();
                        setUserData(userDataFromDB);
                        setLikedLists(userDataFromDB.likedLists || []);
                        if (userDataFromDB.following) {
                            setFollowingData(userDataFromDB.following);
                        } else {
                            setIsFollowingLoading(false);
                        }
                        await updateUserStreak(userDataFromDB)
                    } else {
                        console.error('User not found');
                        navigate("/500");
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error.message);
                    navigate("/500");
                } finally {
                    setIsLoading(false);
                }
            };

            if (user) {
                if (user.emailVerified || requireEmailVerification === "false") {
                    fetchUserData(user.uid);
                } else {
                    await signOut(auth);
                    navigate("/login");
                }
            } else {
                setIsLoading(false);
            }
        });

        return () => {
            unsubscribe();
        };
    }, [navigate, requireEmailVerification]);

    return (
        <>
            <Helmet>
                <title>Explore - Palette</title>
                <meta property="og:title" content="Explore - Palette" />
                <link rel="canonical" href={"https://palette.build" + location.pathname} />
                <meta property="og:url" content={"https://palette.build" + location.pathname} />
                <meta property="og:image" content="https://palette.build/landing.png" />
                <meta property="og:description" content="Curate, share, and discover movies, TV shows, books, and more!" />
                <meta name="keywords" content={`palette, lists, social media, explore`} />
            </Helmet>
            {isLoading ? (
                <DSContainer>
                    <div className="circularProgress">
                        <CircularProgress />
                        <DSText fontSize="1.2em" color="white" boldVar="400" marginBottom="15px">
                            Loading...
                        </DSText>
                    </div>
                </DSContainer>
            ) : (
                <>
                    {userData ? (
                        <DSNavbar isLoggedIn={true} currentPage={"Explore"} userData={userData} />
                    ) : (
                        <DSNavbar isLoggedIn={false} currentPage={"Explore"} />
                    )}
                    <DSContainer justifyContent="start">
                        <div className="cardContainer">
                            {userData && <UserAutoComplete />}
                            <div
                                className={`tab-heading ${!isFollowingTab && "tab-heading-active"}`}
                                onClick={() => setIsFollowingTab(false)}
                                style={{
                                    "--hover-color": isFollowingTab ? "#e5e5e5" : "fff",
                                    "--hover-cursor": userData ? "pointer" : "default"
                                }}>
                                Trending <RocketLaunchIcon className="tab-icon" />
                            </div>
                            {userData && <div
                                className={`tab-heading ${isFollowingTab && "tab-heading-active"}`}
                                onClick={() => setIsFollowingTab(true)}
                                style={{
                                    "--hover-color": !isFollowingTab ? "#e5e5e5" : "fff",
                                    "--hover-cursor": "pointer"
                                }}>
                                Following <PeopleAltIcon className="tab-icon" />
                            </div>}
                            {!isFollowingTab
                                ? (
                                    <DiscoverTab
                                        userData={userData}
                                        likedLists={likedLists}
                                        setLikedLists={setLikedLists} />
                                ) : (
                                    userData &&
                                    <FollowingListsTab
                                        userData={userData}
                                        likedLists={likedLists}
                                        setLikedLists={setLikedLists}
                                        followingData={followingData}
                                        isFollowingLoading={isFollowingLoading}
                                        setIsFollowingLoading={setIsFollowingLoading} />
                                )
                            }
                        </div>
                        {userData && <FeatureRequestForm />}
                    </DSContainer>
                </>
            )}
        </>
    )
}

export default ExplorePage;
