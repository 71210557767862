import React from 'react';
import { FormControl, Select, MenuItem } from '@mui/material';

const DSListFilterSelect = ({ sortOptions, handleSortOptions }) => {
  const listFiltersAvailable = ["Latest", "Popular", "Relevant"]
  return (
    <FormControl fullWidth sx={{ width: '125px', backgroundColor: 'white', height: '40px', borderRadius: '8px', '& .MuiOutlinedInput-notchedOutline': { border: 'none' } }}>
      <Select
        labelId="list-level-filter"
        id="list-level-select"
        value={sortOptions}
        label="Sort by"
        onChange={handleSortOptions}
        sx={{ height: '100%', borderRadius: 'inherit', fontFamily: "'Outfit', sans-serif !important" }}
      >
        {listFiltersAvailable.map((filterValue) => (
          <MenuItem key={filterValue} value={filterValue} sx={{ fontFamily: "'Outfit', sans-serif !important" }}>
            {filterValue}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DSListFilterSelect;