import React from 'react';
import { Helmet } from 'react-helmet';
import DSContainer from '../../DSSystem/DSContainer';
import DSText from '../../DSSystem/DSText';
import DSButton from '../../DSSystem/DSButton';
import FeatureRequestForm from '../FeatureRequestForm';
import './styles.css';

const ErrorPage = ({ code = "404" }) => {
  const errorMessages = {
    "404": ["Page Not Found", "Oops! The page you are looking for does not exist."],
    "500": ["Internal Server Error", "Oops! There is an internal error."]
  };

  return (
    <>
      <Helmet>
        <title>Palette - Curation made easy!</title>
        <meta property="og:title" content="Palette" />
        <meta property="og:url" content="https://palette.build" />
        <meta property="og:image" content="https://palette.build/landing.png" />
        <meta property="og:description" content="Curate, share, and discover movies, TV shows, books, and more!" />
        <meta name="keywords" content={`palette, lists, social media`} />
      </Helmet>
      <DSContainer textAlign="center" hasNoNav={true}>
        <DSText fontSize="3em" boldVar="600" marginBottom="18px">{code} - {errorMessages[code][0]}</DSText>
        <DSText fontSize="1.5em" color="#cfcfcf" boldVar="600" marginBottom="28px">{errorMessages[code][1]}</DSText>
        <div className="button-container button-container-error">
          <DSButton buttonText="Go Home" link="/" />
          <FeatureRequestForm id="inlineFeatureRequest" />
        </div>
      </DSContainer>
    </>
  );
};

export default ErrorPage;
