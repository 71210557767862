import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from '../../firebase.js';
import { Helmet } from 'react-helmet';
import DSButton from '../../DSSystem/DSButton';
import DSText from '../../DSSystem/DSText';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FeatureSet from './FeatureSet/index.js';
import CategoriesChip from './CategoriesChip/index.js';
import LandingPageExplore from './LandingPageExplore/index.js';
import './styles.css';

const LandingPage = () => {
  const requireEmailVerification = process.env.REACT_APP_REQUIRE_EMAIL_VERIFICATION;
  const waitlistOn = process.env.REACT_APP_WAITLIST;
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        if (user.emailVerified || requireEmailVerification === "false") {
          navigate("/user");
        } else {
          await signOut(auth);
        }
      }
    });

    return () => unsubscribe();
  }, [navigate, requireEmailVerification]);

  return (
    <>
      <Helmet>
        <title>Palette - Curation made easy!</title>
        <meta property="og:title" content="Palette" />
        <meta property="og:url" content="https://palette.build" />
        <meta property="og:image" content="https://palette.build/landing.png" />
        <meta property="og:description" content="Curate, share, and discover movies, TV shows, books, and more!" />
        <meta name="keywords" content={`palette, lists, social media, home`} />
      </Helmet>
      <nav className="landing-navbar">
        <div className="landing-navbar-logo">
          <img src="/logo192.png" alt="logo" />
          <h2>Palette</h2>
        </div>
        <div className="landing-navbar-links">
          <Link to="/login" className="landing-navbar-login">
            <LoginOutlinedIcon className="landing-navbar-link-icon" />
            Login
          </Link>
        </div>
      </nav>
      <div className="outer-container">
        <div className="mission-container">
          <DSText fontSize="2.6em" boldVar="500" marginTop="30px" lineHeight="1.1em" color="#eee">
            Craft <span className="text-highlight-1">playlists</span> for all your interests.
          </DSText>
          <DSText fontSize="2.6em" boldVar="500" marginTop="10px" marginBottom="10px" lineHeight="1.1em" color="#eee">
            Connect, share, and <span className="text-highlight-2">spark</span> conversations.
          </DSText>
          <DSText fontSize="2.6em" boldVar="500" marginBottom="50px" lineHeight="1.1em" color="#eee">
            Find your next <span className="text-highlight-3">obsession</span>.
          </DSText>
          <DSText fontSize="1.5em" boldVar="500" marginBottom="25px" color="#aaa">
            Join today to build your palette of <CategoriesChip />!
          </DSText>
          <div className="mission-button">
            <DSButton buttonText="Get Started" link={waitlistOn === "true" ? "/waitlist" : "/signup"} size="large" />
          </div>
          <picture>
            <source media="(max-width: 1200px)" srcSet="/base-graphic-center.png" className='mission-graphic' />
            <img src="/base-graphic-full.png" alt="demo" className='mission-graphic' />
          </picture>
        </div>
        <div className="feature-container">
          <div className="container-title">
            <DSText fontSize="2.6em" boldVar="500" marginBottom="30px" lineHeight="1.1em" color="#eee">
              Why <span className="text-highlight-1">Palette?</span>
            </DSText>
          </div>
          <div className="feature-container-cards">
            <FeatureSet />
          </div>
        </div>
        <div className="trending-container">
          <div className="container-title">
            <DSText fontSize="2.6em" boldVar="500" marginBottom="20px" lineHeight="1.1em" color="#eee">
              What's <span className="text-highlight-1">Trending?</span>
            </DSText>
          </div>
          <LandingPageExplore />
        </div>
        <div className="join-container">
          <div className="container-title">
            <DSText fontSize="2.6em" boldVar="500" marginBottom="35px" lineHeight="1.1em" color="#eee">
              Ready to <span className="text-highlight-1">Join?</span>
            </DSText>
          </div>
          <div className="email-container">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autocomplete="email"
              placeholder="Enter email"
              className="email-input"
              required
            />
            <Link to={waitlistOn === "true" ? "/waitlist" : `/signup?type=email&id=${email}`}>
              <button className="email-button">Sign Up</button>
            </Link>
          </div>
        </div>
        <div className="footer">
          <div>
            &copy; 2024 | Team Palette.
          </div>
          <div className="footer-social">
            <Link to="https://twitter.com/buildpalette" target='_blank'>
              <TwitterIcon />
            </Link>
            <Link to="https://www.linkedin.com/company/buildpalette" target='_blank'>
              <LinkedInIcon />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
