import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { db, auth } from '../../../firebase.js';
import { query, getDocs, collection, where, doc, updateDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { Helmet } from 'react-helmet';
import { handleUsernameChange, updateUserStreak } from '../../../Utils/index.js';
import DSContainer from '../../../DSSystem/DSContainer';
import DSNavbar from '../../../DSSystem/DSNavbar';
import DSText from '../../../DSSystem/DSText/index.js';
import CircularProgress from '@mui/material/CircularProgress';

const EditProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [userData, setUserData] = useState(null);
  const [fullName, setFullName] = useState("");
  const [username, setUsername] = useState("");
  const [usernameErrorMessage, setUsernameErrorMessage] = useState("");
  const [duplicateUsername, setDuplicateUsername] = useState(false);
  const [initialUserName, setInitialUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchUserData = async (userId) => {
      try {
        setIsLoading(true);
        const q = query(collection(db, 'members'), where('id', '==', userId));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const userDoc = querySnapshot.docs[0];
          const userDataFromDB = userDoc.data();
          setUserData(userDataFromDB);
          setFullName(userDataFromDB.firstName + " " + userDataFromDB.lastName);
          setUsername(userDataFromDB.username)
          setInitialUsername(userDataFromDB.username)
          await updateUserStreak(userDataFromDB)
        } else {
          navigate('/404')
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
        navigate("/500");
      } finally {
        setIsLoading(false);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) { 
          fetchUserData(user.uid);
      } else {
        navigate(`/login?redirectTo=${location.pathname}`);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [navigate, location.pathname]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (duplicateUsername && initialUserName !== username) {
        setUsernameErrorMessage("Username is already taken.");
        return;
    }
    const userRef = doc(db, 'members', userData.email);

    try {
        const nameArr = fullName.trim().split(" ");
        const firstName = nameArr.shift();  
        const lastName = nameArr.join(" ");

        await updateDoc(userRef, {
          username: username,
          firstName: firstName,
          lastName: lastName
        });

        navigate(`/${username}`)
    } catch (error) {
        console.error("Error updating user profile:", error);
        alert("Failed to update profile.");
    }
};

  return (
    <>
      <Helmet>
        <title>Edit Profile - Palette</title>
        <meta property="og:title" content="Edit Profile - Palette" />
        <link rel="canonical" href={"https://palette.build" + location.pathname} />
        <meta property="og:url" content={"https://palette.build" + location.pathname} />
        <meta property="og:image" content="https://palette.build/landing.png" />
        <meta property="og:description" content="Curate, share, and discover movies, TV shows, books, and more!" />
        <meta name="keywords" content={`palette, lists, social media, login`} />
      </Helmet>
      {userData && <DSNavbar isLoggedIn={true} currentPage={"Home"} userData={userData} />}
      <DSContainer>
        <div className="card">
        <DSText fontSize="1.65em" color="#1e1e1e" boldVar="600" marginBottom="20px">Edit Profile</DSText>
        {!isLoading ?
        (<form onSubmit={handleSubmit}>
            <label>Name</label>
            <input
                type="text"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                autoComplete="name"
                required
            />
            <label>Username</label>
            <input
                type="text"
                value={username}
                onChange={(e) => handleUsernameChange(e, setUsername, setUsernameErrorMessage, setDuplicateUsername)}
                required
            />
            {duplicateUsername && initialUserName !== username && <div className="invalid">{usernameErrorMessage}</div>}
            <div style={{ marginTop: "20px" }}>
                <button className="continue-email-button" type="submit">
                    Update
                </button>
            </div>
        </form>): (

            <div className="circularProgress">
                <CircularProgress />
                <DSText fontSize="1.2em" color="white" boldVar="400" marginBottom="15px">
                    Loading...
                </DSText>
            </div>

        )}
        </div>
      </DSContainer >
    </>
  );
};

export default EditProfile;
