import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { db } from '../../../firebase';
import { collection, query, where, getDocs, getDoc, updateDoc, doc, arrayUnion, arrayRemove, setDoc, deleteDoc, runTransaction, increment } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { formatTimeDifference, getProfileImageFromProfileData } from '../../../Utils';
import axios from "axios";
import CardMedia from '@mui/material/CardMedia';
import DSText from '../../../DSSystem/DSText';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
import "./styles.css";

const ActivityCard = ({ key, listName, listImage, listId, viewerData, userId, commentId, comment, createdAt, type }) => {
    const navigate = useNavigate();
    const [profileData, setProfileData] = useState(null);

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const userQuery = query(collection(db, 'members'), where('id', '==', userId));
                const querySnapshot = await getDocs(userQuery);
                if (!querySnapshot.empty) {
                    const userDoc = querySnapshot.docs[0];
                    setProfileData(userDoc.data());
                } else {
                    console.error('Some user was not found');
                }
            } catch (error) {
                console.error('Error fetching user UID:', error);
                navigate("/500");
            }
        };

        if (userId) {
            fetchProfileData();
        }
    }, [userId, navigate]);

    const handleFollowUnfollowClick = async () => {
        if (viewerData) {
            try {
                if (profileData && profileData.followers !== undefined) {
                    const memberRef = doc(db, 'members', profileData.email);
                    const followingUserRef = doc(db, 'members', viewerData.email);
                    const isFollowing = profileData.followers.includes(viewerData.id);
                    let memberData;

                    if (isFollowing) {
                        // User is in followers list, remove them
                        await updateDoc(memberRef, {
                            followers: arrayRemove(viewerData.id),
                        });
                        await updateDoc(followingUserRef, {
                            following: arrayRemove(profileData.id),
                        });

                        const updatedProfileDataSnapshot = await getDoc(memberRef);
                        const updatedProfileData = updatedProfileDataSnapshot.data();
                        setProfileData(updatedProfileData);

                        // Notification logic
                        const notificationRef = collection(db, "notifications");
                        const deleteQuery = query(notificationRef,
                            where("userId", "==", viewerData.id),
                            where("profileId", "==", profileData.id),
                            where("type", "==", "follow")
                        );
                        getDocs(deleteQuery).then(async (querySnapshot) => {
                            if (!querySnapshot.empty) {
                                const firstDoc = querySnapshot.docs[0];
                                if (profileData && profileData.hasOwnProperty("notificationsTabLastSeenAt") && firstDoc.data().createdAt > profileData.notificationsTabLastSeenAt) {
                                    await updateDoc(memberRef, {
                                        notificationsUnseen: increment(-1),
                                    });
                                }
                                deleteDoc(doc(db, "notifications", firstDoc.id))
                                    .catch((error) => {
                                        console.error("Error removing document: ", error);
                                    });
                                const memberSnapshot = await getDoc(memberRef);
                                if (!memberSnapshot.empty) {
                                    const memberDataFromDB = memberSnapshot.data();
                                    if (memberDataFromDB.hasOwnProperty("notifications")) {
                                        const profileNotification = memberDataFromDB.notifications;
                                        const notificationDocRef = doc(db, 'profileNotifications', profileNotification);
                                        await updateDoc(notificationDocRef, {
                                            events: arrayRemove(firstDoc.id),
                                        });
                                    }
                                }
                            }
                        }).catch((error) => {
                            console.error("Error getting documents: ", error);
                        });

                    } else {
                        // User is not in followers list, add them
                        await updateDoc(memberRef, {
                            followers: arrayUnion(viewerData.id),
                        });
                        await updateDoc(followingUserRef, {
                            following: arrayUnion(profileData.id),
                        });

                        const updatedProfileDataSnapshot = await getDoc(memberRef);
                        const updatedProfileData = updatedProfileDataSnapshot.data();
                        setProfileData(updatedProfileData);

                        // Notification logic
                        const memberSnap = await getDoc(memberRef);
                        if (memberSnap.exists()) {
                            memberData = memberSnap.data()
                            let profileNotification;
                            if (!memberData.hasOwnProperty("notifications")) {
                                profileNotification = uuidv4();
                                await updateDoc(memberRef, {
                                    notifications: profileNotification
                                })
                                await setDoc(doc(db, "profileNotifications", profileNotification), {
                                    id: profileNotification,
                                    events: []
                                });
                            } else {
                                profileNotification = memberData.notifications
                            }
                            const profileNotificationsRef = doc(db, 'profileNotifications', profileNotification)
                            const eventUUID = uuidv4();
                            const newNotificationEvent = {
                                id: eventUUID,
                                type: "follow",
                                createdAt: Date.now(),
                                userId: viewerData.id,
                                profileId: profileData.id
                            }
                            await setDoc(doc(db, "notifications", eventUUID), newNotificationEvent)
                            await updateDoc(profileNotificationsRef, {
                                events: arrayUnion(eventUUID),
                            });
                        }

                        // Unseen Notifications Count
                        await runTransaction(db, async (transaction) => {
                            const ownerDoc = await transaction.get(memberRef);
                            const notificationsUnseen = ownerDoc.data()?.notificationsUnseen;
                            if (notificationsUnseen !== undefined) {
                                // If notificationsUnseen exists, increment it by 1
                                transaction.update(memberRef, { notificationsUnseen: notificationsUnseen + 1 });
                            } else {
                                // If notificationsUnseen does not exist, set it to 1
                                transaction.set(memberRef, { notificationsUnseen: 1 }, { merge: true });
                            }
                        });
                    }

                    const updatedProfileDataSnapshot = await getDoc(memberRef);
                    const updatedProfileData = updatedProfileDataSnapshot.data();
                    setProfileData(updatedProfileData);

                    // Send email to member
                    if (!isFollowing && memberData) {
                        const followingUserSnap = await getDoc(followingUserRef);
                        if (followingUserSnap.exists()) {
                            const followingUserData = followingUserSnap.data()
                            let email = memberData.email;
                            let subject = "You have a new follower on Palette!";
                            let template = "New Follower";
                            let variables = {
                                userFirstName: memberData.firstName,
                                followerFirstName: followingUserData.firstName,
                                followerFirstNameWithPossessive: `${followingUserData.firstName}'${!followingUserData.firstName.endsWith('s') ? 's' : ''}`,
                                link: window.location.origin + "/" + followingUserData.username
                            };
                            try {
                                await axios.post(`/api/email`, { email, subject, template, variables });
                            } catch (error) {
                                console.error('Error sending email:', error.message);
                            }
                        }
                    }
                }
            } catch (error) {
                console.error('Error updating followers:', error.message);
            }
        }
    };

    return (
        <div key={key} className="notifications-container">
            {type === "like" && profileData && (
                <>
                    <div className="notifications-text">
                        <Link to={`/${profileData.username}`}>
                            <CardMedia
                                className="notifications-profile-image"
                                component="img"
                                alt="Profile Picture"
                                image={getProfileImageFromProfileData(profileData)}
                            />
                        </Link>
                        <DSText fontSize="1.1em" color="#c0c0c0">
                            <Link to={`/${profileData.username}`} className="notifications-link">{profileData.firstName + " " + profileData.lastName}</Link>
                            {" liked your list "}
                            <Link to={`/list/${listId}`} className="notifications-link">{listName}</Link>
                            {". "}
                            <span className="notifications-time">{formatTimeDifference(createdAt)}</span>
                        </DSText>
                    </div>
                    <Link to={`/list/${listId}`}>
                        <CardMedia
                            className="notifications-list-image"
                            component="img"
                            alt="List Cover"
                            image={listImage}
                        />
                    </Link>
                </>
            )}
            {type === "listComment" && profileData && (
                <>
                    <div className="notifications-text">
                        <Link to={`/${profileData.username}`}>
                            <CardMedia
                                className="notifications-profile-image"
                                component="img"
                                alt="Profile Picture"
                                image={getProfileImageFromProfileData(profileData)}
                            />
                        </Link>
                        <DSText fontSize="1.1em" color="#c0c0c0">
                            <Link to={`/${profileData.username}`} className="notifications-link">{profileData.firstName + " " + profileData.lastName}</Link>
                            {" commented on "}
                            <Link to={`/list/${listId}`} className="notifications-link">{listName}</Link>
                            {": "}
                            <Link to={`/list/${listId}?commentId=${commentId}`} className="notifications-comment">{comment.length > 30 ? '"' + comment.substring(0, 30) + '..."' : '"' + comment + '"'}</Link>
                            <span className="notifications-time">{formatTimeDifference(createdAt)}</span>
                        </DSText>
                    </div>
                    <Link to={`/list/${listId}`}>
                        <CardMedia
                            className="notifications-list-image"
                            component="img"
                            alt="List Cover"
                            image={listImage}
                        />
                    </Link>
                </>
            )}
            {type === "follow" && profileData && (
                <>
                    <div className="notifications-text">
                        <Link to={`/${profileData.username}`}>
                            <CardMedia
                                className="notifications-profile-image"
                                component="img"
                                alt="Profile Picture"
                                image={getProfileImageFromProfileData(profileData)}
                            />
                        </Link>
                        <DSText fontSize="1.1em" color="#c0c0c0">
                            <Link to={`/${profileData.username}`} className="notifications-link">{profileData.firstName + " " + profileData.lastName}</Link>
                            {" started following you! "}
                            <span className="notifications-time">{formatTimeDifference(createdAt)}</span>
                        </DSText>
                    </div>
                    {viewerData &&
                        <button className="notifications-followButton" onClick={handleFollowUnfollowClick}>
                            {profileData.followers && profileData.followers.includes(viewerData.id) ? (
                                <PersonRemoveAlt1Icon className="notifications-followIcon" />
                            ) : (
                                <PersonAddAlt1Icon className="notifications-followIcon" />
                            )}
                            {" "} {profileData.followers && profileData.followers.includes(viewerData.id) ? 'Unfollow' : 'Follow'}
                        </button>
                    }
                </>
            )}
            {type === "newList" && profileData && (
                <>
                    <div className="notifications-text">
                        <Link to={`/${profileData.username}`}>
                            <CardMedia
                                className="notifications-profile-image"
                                component="img"
                                alt="Profile Picture"
                                image={getProfileImageFromProfileData(profileData)}
                            />
                        </Link>
                        <DSText fontSize="1.1em" color="#c0c0c0">
                            <Link to={`/${profileData.username}`} className="notifications-link">{profileData.firstName + " " + profileData.lastName}</Link>
                            {" created a new list "}
                            <Link to={`/list/${listId}`} className="notifications-link">{listName}</Link>
                            {". "}
                            <span className="notifications-time">{formatTimeDifference(createdAt)}</span>
                        </DSText>
                    </div>
                    <Link to={`/list/${listId}`}>
                        <CardMedia
                            className="notifications-list-image"
                            component="img"
                            alt="List Cover"
                            image={listImage}
                        />
                    </Link>
                </>
            )}
        </div>
    );
};

export default ActivityCard;