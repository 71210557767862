import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth, db } from "../../firebase.js";
import { onAuthStateChanged } from 'firebase/auth';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { Helmet } from 'react-helmet';
import axios from "axios";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DSContainer from '../../DSSystem/DSContainer';
import DSText from '../../DSSystem/DSText';
import { updateUserStreak } from '../../Utils/index.js';
import './styles.css'

const AdminPage = () => {
    const requireEmailVerification = process.env.REACT_APP_REQUIRE_EMAIL_VERIFICATION;
    const navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [template, setTemplate] = useState('sign up');
    const [message, setMessage] = useState('');
    const [message2, setMessage2] = useState('');
    const [buttonText, setButtonText] = useState('');
    const [buttonLink, setButtonLink] = useState('');
    const [adminEmail, setAdminEmail] = useState('');
    const adminEmails = useMemo(() => ["mehulrastogi2002@gmail.com", "mittalprakhar57@gmail.com"], []);

    useEffect(() => {
        const fetchUserData = async (userId) => {
            try {
                const q = query(collection(db, 'members'), where('id', '==', userId));
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const userDoc = querySnapshot.docs[0];
                    const userDataFromDB = userDoc.data();
                    if (!adminEmails.includes(userDataFromDB.email)) {
                        navigate("/404");
                    }
                    setAdminEmail(userDataFromDB.email);
                    await updateUserStreak(userDataFromDB)
                } else {
                    navigate("/404");
                }
            } catch (error) {
                navigate("/404");
            }
        };

        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user && (user.emailVerified || requireEmailVerification === "false")) {
                fetchUserData(user.uid);
            } else {
                navigate("/404");
            }
        });

        return () => {
            unsubscribe();
        };
    }, [navigate, requireEmailVerification, adminEmails]);

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    const sendEmail = async (userData) => {
        let email = userData.email;
        let variables = {
            firstName: userData.firstName,
            userFirstName: userData.firstName,
            message: '<p>' + message.replaceAll('\n\n', '</p><p>') + '</p>',
            buttonText: buttonText,
            buttonLink: buttonLink,
            message2: '<p>' + message2.replaceAll('\n\n', '</p><p>') + '</p>',
            link: window.location.origin + "/" + userData.username,
        };
        try {
            await axios.post(`/api/email`, { email, subject, template, variables });
        } catch (error) {
            console.error('Error sending email:', error.message);
        }
        alert(`Email sent to: ${email}`);
    }

    const sendTestEmail = async () => {
        if (subject && template) {
            const querySnapshot = await getDocs(query(collection(db, "members"), where("email", "==", adminEmail)));
            querySnapshot.forEach(async (doc) => {
                sendEmail(doc.data());
            });
        }
    }

    const sendRealEmail = async () => {
        if (subject && template) {
            const querySnapshot = await getDocs(query(collection(db, "members"), where("email", "==", email)));
            querySnapshot.forEach(async (doc) => {
                sendEmail(doc.data());
            });
        }
    }

    return (
        <>
            <Helmet>
                <title>Admin - Palette</title>
                <meta property="og:title" content="Admin - Palette" />
                <link rel="canonical" href={"https://palette.build" + location.pathname} />
                <meta property="og:url" content={"https://palette.build" + location.pathname} />
                <meta property="og:image" content="https://palette.build/landing.png" />
                <meta property="og:description" content="Curate, share, and discover movies, TV shows, books, and more!" />
                <meta name="keywords" content={`palette, lists, social media, create`} />
            </Helmet>
            <DSContainer hasNoNav={true}>
                <div className="card">
                    <form onSubmit={handleSubmit}>
                        <DSText fontSize="1.65em" color="#1e1e1e" boldVar="600" marginBottom="20px">Send Emails</DSText>
                        <label>Email</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <label>Subject</label>
                        <input
                            type="text"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            required
                        />
                        <label>Template</label>
                        <Select
                            id="template"
                            name="template"
                            value={template}
                            onChange={(event) => setTemplate(event.target.value)}
                            size='small'
                            sx={{
                                display: "block",
                                width: "100%",
                                boxSizing: "border-box",
                                padding: 0,
                                marginTop: "7px",
                                marginBottom: "20px",
                                fontFamily: "'Outfit', sans-serif !important",
                                fontSize: "0.95em",
                                borderRadius: "7px",
                                backgroundColor: "#fafafa",
                                color: "#1e1e1e",
                                '.MuiOutlinedInput-notchedOutline': {
                                    border: '1px solid #1e1e1e6d',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    border: '1px solid #1e1e1e6d',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    border: '1.5px solid #645dfc',
                                },
                            }}
                            MenuProps={{
                                sx: {
                                    "& .MuiMenuItem-root": {
                                        fontFamily: "'Outfit', sans-serif !important",
                                    },
                                    "& .MuiMenuItem-root:hover": {
                                        backgroundColor: "#655dfc15"
                                    },
                                    "&& .Mui-selected": {
                                        backgroundColor: "#655dfc25"
                                    },
                                }
                            }}
                        >
                            <MenuItem value="sign up">Sign Up</MenuItem>
                            <MenuItem value="broadcast 1">Message</MenuItem>
                            <MenuItem value="broadcast 2">Message + Button</MenuItem>
                        </Select>
                        {template === "broadcast 1"
                            ? (
                                <>
                                    <label>Message</label>
                                    <textarea
                                        rows="5"
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        required
                                    />
                                </>
                            ) : template === "broadcast 2"
                                ? (
                                    <>
                                        <label>Message Top Half</label>
                                        <textarea
                                            rows="5"
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            required
                                        />
                                        <label>Button Text</label>
                                        <input
                                            type="text"
                                            value={buttonText}
                                            onChange={(e) => setButtonText(e.target.value)}
                                        />
                                        <label>Button Link</label>
                                        <input
                                            type="text"
                                            value={buttonLink}
                                            onChange={(e) => setButtonLink(e.target.value)}
                                        />
                                        <label>Message Bottom Half</label>
                                        <textarea
                                            rows="5"
                                            value={message2}
                                            onChange={(e) => setMessage2(e.target.value)}
                                            required
                                        />
                                    </>
                                ) : (
                                    <></>
                                )
                        }
                        <div style={{ marginTop: "30px" }}>
                            <input type="submit" name="action" value="Send Test Email" onClick={sendTestEmail} />
                            <input type="submit" name="action" value="Send Real Email" onClick={sendRealEmail} />
                        </div>
                    </form>
                </div>
            </DSContainer>
        </>
    );
};

export default AdminPage;
