import React, { useState, useEffect } from 'react';
import { db } from '../../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { addListEntry, getListImageFromEntryData } from '../../../Utils';
import CircularProgress from '@mui/material/CircularProgress';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import DSText from '../../DSText';
import '../styles.css';

const DSListModal = ({ onClose, listOfLists, modalType, entryData, entryCategory }) => {
  const [listsData, setListsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isListEmpty, setIsListEmpty] = useState(false);

  useEffect(() => {
    const fetchAddListsForEntry = async () => {
      try {
        const listDataPromises = listOfLists.map(async (listId) => {
          const listDocRef = doc(db, 'lists', listId);
          const listDocSnap = await getDoc(listDocRef);
          if (listDocSnap.exists()) {
            const listData = listDocSnap.data();
            if (listData.category !== entryCategory) {
              return null;
            }
            let listHasEntry;
            switch (entryCategory) {
              case "Web Pages":
                listHasEntry = listData.data.some(entry => entry.url === entryData.url);
                break;
              case "Freestyle":
                listHasEntry = listData.data.some(entry => entry.title === entryData.title);
                break;
              default:
                listHasEntry = listData.data.some(entry => entry.id === entryData.id);
                break;
            }
            let listImage = listData.listImage ? listData.listImage : getListImageFromEntryData(listData.data[listData.data.length - 1], entryCategory);
            return { id: listData.id, title: listData.title, listImage, listHasEntry };
          }
          return null;
        });
        const listDataArray = (await Promise.all(listDataPromises)).filter(listData => listData !== null);
        setListsData(listDataArray);
        setIsListEmpty(listDataArray.length === 0);
      } catch (error) {
        console.error("Error fetching list data: ", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (listOfLists.length === 0) {
      setIsListEmpty(true);
      setIsLoading(false);
    } else if (modalType === "Add to Your List" && entryData && entryCategory) {
      fetchAddListsForEntry();
    } else {
      setIsListEmpty(true);
      setIsLoading(false);
    }
  }, [listOfLists, modalType, entryData, entryCategory]);

  const handleAddToList = async (index) => {
    delete entryData["vote_average"];
    delete entryData["vote_count"];
    const updatedEntryData = {
      ...entryData,
      annotation: "",
      rating: null,
      checked: false,
      checkedTime: null,
      createdTime: Date.now(),
      lastUpdated: Date.now()
    };
    let addStatus = await addListEntry(listsData[index].id, updatedEntryData);
    if (addStatus === "success" || addStatus === "duplicate") {
      let updatedListData = [...listsData];
      updatedListData[index].listHasEntry = true;
      setListsData(updatedListData);
    }
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      {!isLoading ? (
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div>
            <div className="modal-content-padding">
              <DSText fontSize="1.8em" boldVar="600" marginBottom="15px">
                {modalType}
              </DSText>
            </div>
            {isListEmpty ? (
              <div className="noFollowersText modal-content-padding">
                <DSText fontSize="1em">No lists found.</DSText>
              </div>
            ) : (
              <ul>
                {listsData.map((listData, index) => (
                  <li key={index}>
                    <div className="modalListContainer">
                      <div className="modalListLeftContainer">
                        <img src={listData.listImage} alt={listData.title} className="modalListImg" />
                        <div style={{ fontFamily: "'Outfit', sans-serif" }}>
                          <span style={{ fontSize: "0.95em" }}>{listData.title}</span>
                        </div>
                      </div>
                      {listData.listHasEntry
                        ? (
                          <div className="modalListButton">
                            <DoneIcon className="modalListButtonIcon modalListButtonIconNotClickable" />
                            Added
                          </div>
                        ) : (
                          <button className="modalListButton modalListButtonClickable" onClick={() => handleAddToList(index)}>
                            <AddIcon className="modalListButtonIcon" />
                            Add
                          </button>
                        )
                      }
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <ClearIcon
            sx={{
              width: '0.75em',
              position: 'absolute',
              top: '8px',
              right: '8px',
              color: 'white',
              cursor: 'pointer',
              transition: 'all 0.15s ease-in-out',
              '&:hover': {
                backgroundColor: 'transparent !important',
                color: '#ef00e7 !important',
              },
            }}
            onClick={onClose}
          />
        </div>
      ) : (
        <>
          <CircularProgress style={{ color: 'white', marginRight: '5px' }} />
          <div>Loading...</div>
        </>
      )}
    </div>
  );
};

export default DSListModal;