import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth, db } from '../../../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { Helmet } from 'react-helmet';
import { updateUserStreak } from '../../../Utils';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DSContainer from '../../../DSSystem/DSContainer';
import DSNavbar from '../../../DSSystem/DSNavbar';
import DSText from '../../../DSSystem/DSText';
import '../styles.css';
import './styles.css';

const AllChallengePage = () => {
    const navigate = useNavigate();
    const requireEmailVerification = process.env.REACT_APP_REQUIRE_EMAIL_VERIFICATION;
    const [userData, setUserData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const challengeCategories = ['All', 'Movies', 'TV Shows', 'Books'];
    const [selectedChallengeCategory, setSelectedChallengeCategory] = useState('All');
    const [challengesData, setChallengesData] = useState(null);
    const [filteredChallengesData, setFilteredChallengesData] = useState(null);

    const customChallengeCategories = ['All', 'Movies', 'TV Shows', 'Books', 'Video Games'];
    const [selectedCustomChallengeCategory, setSelectedCustomChallengeCategory] = useState('All');
    const [customChallengesData, setCustomChallengesData] = useState(null);
    const [filteredCustomChallengesData, setFilteredCustomChallengesData] = useState(null);

    useEffect(() => {
        const fetchChallengesData = async () => {
            try {
                const q = query(collection(db, 'Challenge'), where('live', '==', true));
                const querySnapshot = await getDocs(q);
                let challengesTemp = [], customChallengesTemp = [];
                querySnapshot.forEach((doc) => {
                    if (Number(doc.data().id) > 999) {
                        customChallengesTemp.push(doc.data());
                    } else {
                        challengesTemp.push(doc.data());
                    }
                });
                setChallengesData(challengesTemp);
                customChallengesTemp = customChallengesTemp.map(value => ({ value, sort: Math.random() }))
                    .sort((a, b) => a.sort - b.sort)
                    .map(({ value }) => value);
                setCustomChallengesData(customChallengesTemp);
            } catch (error) {
                console.error("Error fetching challenges:", error);
                navigate("/500");
            }
            setIsLoading(false);
        };

        fetchChallengesData();
    }, [navigate]);

    useEffect(() => {
        if (challengesData) {
            if (selectedChallengeCategory === 'All') {
                setFilteredChallengesData(challengesData);
            } else {
                setFilteredChallengesData(challengesData.filter(challengeData => challengeData.category === selectedChallengeCategory));
            }
        }
    }, [challengesData, selectedChallengeCategory]);

    useEffect(() => {
        if (customChallengesData) {
            if (selectedCustomChallengeCategory === 'All') {
                setFilteredCustomChallengesData(customChallengesData);
            } else {
                setFilteredCustomChallengesData(customChallengesData.filter(challengeData => challengeData.category === selectedCustomChallengeCategory));
            }
        }
    }, [customChallengesData, selectedCustomChallengeCategory]);

    useEffect(() => {
        const fetchUserData = async (userId) => {
            try {
                const q = query(collection(db, 'members'), where('id', '==', userId));
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const userDoc = querySnapshot.docs[0];
                    const userDataFromDB = userDoc.data();
                    setUserData(userDataFromDB);
                    await updateUserStreak(userDataFromDB);
                } else {
                    console.error('User not found');
                    navigate("/500");
                }
            } catch (error) {
                console.error('Error fetching user data:', error.message);
                navigate("/500");
            }
        };

        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                if (user.emailVerified || requireEmailVerification === "false") {
                    fetchUserData(user.uid);
                } else {
                    await signOut();
                    navigate("/");
                }
            }
        });

        return () => unsubscribe();
    }, [navigate, requireEmailVerification]);

    return (
        <>
            <Helmet>
                <title>All Challenge - Palette</title>
                <meta property="og:title" content="All Challenges - Palette" />
                <link rel="canonical" href={"https://palette.build" + window.location.pathname} />
                <meta property="og:url" content={"https://palette.build" + window.location.pathname} />
                <meta property="og:description" content="Curate, share, and discover movies, TV shows, books, and more!" />
                <meta name="keywords" content={`palette, lists, social media, challenge, archive, all`} />
            </Helmet>
            <>
                {userData ? (
                    <DSNavbar isLoggedIn={true} userData={userData} />
                ) : (
                    <DSNavbar isLoggedIn={false} />
                )}
                <DSContainer justifyContent="start">
                    <div className="cardContainer" style={{ marginBottom: "15px" }}>
                        <DSText fontSize="1.8em" boldVar="600">Challenges</DSText>
                        <div className="allChallengesButtonContainer">
                            <button className="createChallengeButton" onClick={() => navigate("/challenge/create")}>
                                <AddCircleIcon className="createChallengeIcon" />
                                {" "} Create Challenge
                            </button>
                        </div>
                        <DSText fontSize="1.4em" boldVar="600" marginBottom="15px">By Team Palette</DSText>
                        <div className="category-button-container" style={{ marginBottom: "10px" }}>
                            {challengeCategories.map(category => (
                                <button
                                    key={category}
                                    className={`category-button category-button-selected-${selectedChallengeCategory === category}`}
                                    onClick={() => setSelectedChallengeCategory(category)}
                                >
                                    {category}
                                </button>
                            ))}
                        </div>
                        {isLoading ? (
                            <DSText fontSize="1em" boldVar="400">Loading...</DSText>
                        ) : filteredChallengesData ? (
                            filteredChallengesData.map((challenge) => (
                                <Link to={`/challenge/${challenge.id}`} style={{ textDecoration: "none" }}>
                                    <div className="challengeCard" key={challenge.id}>
                                        <DSText fontSize="1.05em" boldVar="500">{challenge.title}</DSText>
                                    </div>
                                </Link>
                            ))
                        ) : (
                            <DSText fontSize="1.5em" color="#cfcfcf" boldVar="600">No challenges found.</DSText>
                        )}
                        <DSText fontSize="1.4em" boldVar="600" marginTop="35px" marginBottom="15px">By Palette Users</DSText>
                        <div className="category-button-container" style={{ marginBottom: "10px" }}>
                            {customChallengeCategories.map(category => (
                                <button
                                    key={category}
                                    className={`category-button category-button-selected-${selectedCustomChallengeCategory === category}`}
                                    onClick={() => setSelectedCustomChallengeCategory(category)}
                                >
                                    {category}
                                </button>
                            ))}
                        </div>
                        {isLoading ? (
                            <DSText fontSize="1em" boldVar="400">Loading...</DSText>
                        ) : filteredCustomChallengesData ? (
                            filteredCustomChallengesData.map((challenge) => (
                                <Link to={`/challenge/${challenge.id}`} style={{ textDecoration: "none" }}>
                                    <div className="challengeCard" key={challenge.id}>
                                        <DSText fontSize="1.05em" boldVar="500">{challenge.title}</DSText>
                                    </div>
                                </Link>
                            ))
                        ) : (
                            <DSText fontSize="1.5em" color="#cfcfcf" boldVar="600">No challenges found.</DSText>
                        )}
                    </div>
                </DSContainer>
            </>
        </>
    );
};

export default AllChallengePage;