import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DSButton from '../DSButton';
import DSText from '../DSText';

const DSConfirmDialog = ({ title, text, open, setOpen, setStatus, confirmButtonText, setConfirmButtonText }) => {
    const cancel = () => {
        setStatus(false);
        setOpen(false);
    }

    const confirm = () => {
        setStatus(true);
        if (setConfirmButtonText) {
            setConfirmButtonText("Processing...");
        }
    }

    return (
        <Dialog onClose={cancel} open={open} sx={{
            '& .MuiDialog-paper': {
                width: '80%',
                maxWidth: '400px',
                backgroundColor: '#0a0a0a !important',
                color: 'white'
            },
        }}>
            <DialogTitle>
                <DSText fontSize="1.5em" boldVar="600" marginBottom="-12px">{title}</DSText>
            </DialogTitle>
            <DialogContent sx={{ padding: '20px 24px' }}>{text}</DialogContent>
            <DialogActions sx={{ padding: "8px 24px 22px 8px !important" }}>
                <DSButton onClick={cancel} buttonText="Cancel" size="small" isFilled={true} />
                <DSButton onClick={confirm} buttonText={confirmButtonText} size="small" />
            </DialogActions>
        </Dialog>
    );
};

export default DSConfirmDialog;