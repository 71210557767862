import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth, db } from "../../firebase.js";
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { collection, query, where, getDocs, getDoc, doc, updateDoc } from 'firebase/firestore';
import { Helmet } from 'react-helmet';
import DSContainer from '../../DSSystem/DSContainer';
import DSNavbar from "../../DSSystem/DSNavbar";
import DSText from '../../DSSystem/DSText';
import FeatureRequestForm from '../FeatureRequestForm/index.js';
import { getListImageFromEntryData, getListTitleFromListId, getListImageFromListId, updateUserStreak } from '../../Utils'
import ActivityCard from './ActivityCard/index.js';

const ActivityPage = () => {
  const requireEmailVerification = process.env.REACT_APP_REQUIRE_EMAIL_VERIFICATION
  const navigate = useNavigate();
  const location = useLocation();
  const [userData, setUserData] = useState(null);
  const [userLists, setUserLists] = useState([]);
  const [events, setEvents] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    const fetchUserData = async (userId) => {
      try {
        const q = query(collection(db, 'members'), where('id', '==', userId));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const userDoc = querySnapshot.docs[0];
          const userDataFromDB = userDoc.data();
          setUserData(userDataFromDB);
          setUserLists(userDataFromDB.lists);
          await updateUserStreak(userDataFromDB)
        } else {
          console.error('User not found');
          navigate("/500");
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
        navigate("/500");
      }
    };

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        if (user.emailVerified || requireEmailVerification === "false") {
          fetchUserData(user.uid);
        } else {
          await signOut();
          navigate("/");
        }
      } else {
        navigate(`/login?redirectTo=${location.pathname}`);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [navigate, location.pathname, requireEmailVerification]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        // Fetch events from user lists
        const listEventsPromises = userLists.map(async (currList) => {
          const listRef = doc(db, 'lists', currList);
          const docSnap = await getDoc(listRef);
          if (docSnap.exists() && docSnap.data().notifications) {
            const listData = docSnap.data();
            const listNotification = listData.notifications;
            const listName = listData.title;
            const listImage = listData.listImage ? listData.listImage : getListImageFromEntryData(listData.data[listData.data.length - 1], listData.category);
            const listNotificationsRef = doc(db, 'listNotifications', listNotification);
            const newDocSnap = await getDoc(listNotificationsRef);
            if (newDocSnap.exists()) {
              return newDocSnap.data().events.map(event => [event, listName, listImage]);
            }
          }
          return [];
        });

        const listEventsResults = await Promise.all(listEventsPromises);
        const listEvents = listEventsResults.flat();

        // Fetch profile events
        let profileEvents = [];
        if (userData && userData.hasOwnProperty("notifications")) {
          const profileNotificationsRef = doc(db, 'profileNotifications', userData.notifications);
          const docSnap = await getDoc(profileNotificationsRef);
          if (docSnap.exists()) {
            profileEvents = docSnap.data().events.map(event => [event, "", ""]);
          }
        }

        // Combine and set all events
        const allEvents = [...listEvents, ...profileEvents];
        setEvents(allEvents);
        if (userData && userLists && allEvents.length === 0) {
          setIsEmpty(true);
        }
      } catch (error) {
        console.error("Error fetching event data: ", error);
      }
    };

    fetchEvents();
  }, [userLists, userData]);

  useEffect(() => {
    let mounted = true;

    const fetchData = async () => {
      if (isEmpty) {
        setIsLoading(false);
      }
      const eventsPromises = events.map(async ([currEvent, listName, listImage]) => {
        const eventRef = doc(db, 'notifications', currEvent);
        try {
          const docSnap = await getDoc(eventRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            if (data.type === "newList") {
              data["listName"] = await getListTitleFromListId(data.listId)
              data["listImage"] = await getListImageFromListId(data.listId)
            } else {
              data["listName"] = listName;
              data["listImage"] = listImage;
            }
            setIsLoading(false);
            return data;
          }
          setIsLoading(false);
          return null;
        } catch (error) {
          console.error("Error fetching document:", error);
          setIsLoading(false);
          return null;
        }
      });

      const appendList = (await Promise.all(eventsPromises)).filter(data => data !== null);
      if (mounted) {
        const sortedList = appendList.sort((a, b) => b.createdAt - a.createdAt);
        setEventsData(sortedList);
      }
    };

    fetchData();

    return () => {
      mounted = false;
    };
  }, [events, isEmpty]);

  useEffect(() => {
    const updateNotificationTimestamp = async () => {
      const userRef = doc(db, 'members', userData.email);
      try {
        await updateDoc(userRef, {
          notificationsTabLastSeenAt: Date.now()
        });
        await updateDoc(userRef, {
          notificationsUnseen: 0
        });
      } catch (error) {
        console.error("Failed to update document: ", error);
      }
    };

    if (userData && userData.email) {
      updateNotificationTimestamp();
    }
  }, [userData, eventsData]);

  return (
    <>
      <Helmet>
        <title>Activity - Palette</title>
        <meta property="og:title" content="Activity - Palette" />
        <link rel="canonical" href={"https://palette.build" + location.pathname} />
        <meta property="og:url" content={"https://palette.build" + location.pathname} />
        <meta property="og:image" content="https://palette.build/landing.png" />
        <meta property="og:description" content="Curate, share, and discover movies, TV shows, books, and more!" />
        <meta name="keywords" content={`palette, lists, social media, create`} />
      </Helmet>
      {userData && <DSNavbar isLoggedIn={true} currentPage={"Activity"} userData={userData} />}
      <DSContainer justifyContent="start">
        <div className='cardContainer'>
          <DSText fontSize="2.1em" boldVar="600" marginBottom="35px">Activity</DSText>
          {!isLoading ? (
            <>
              {eventsData.reduce((acc, eventData, index) => {
                if (eventData.userId !== userData.id) {
                  const currentEventSignature = `${eventData.userId}-${eventData.type}` + (eventData.listId ? `-${eventData.listId}` : '') + (eventData.commentId ? `-${eventData.commentId}` : '');
                  if (!index || currentEventSignature !== acc.lastEventSignature) {
                    let activityCard;
                    switch (eventData.type) {
                      case "like":
                        activityCard = (
                          <ActivityCard
                            key={eventData.id}
                            listName={eventData.listName}
                            listImage={eventData.listImage}
                            listId={eventData.listId}
                            userId={eventData.userId}
                            createdAt={eventData.createdAt}
                            type={eventData.type}
                          />
                        );
                        break;
                      case "follow":
                        activityCard = (
                          <ActivityCard
                            key={eventData.id}
                            viewerData={userData}
                            userId={eventData.userId}
                            createdAt={eventData.createdAt}
                            type={eventData.type}
                          />
                        );
                        break;
                      case "listComment":
                        activityCard = (
                          <ActivityCard
                            key={eventData.id}
                            listName={eventData.listName}
                            listImage={eventData.listImage}
                            listId={eventData.listId}
                            userId={eventData.userId}
                            commentId={eventData.commentId}
                            comment={eventData.comment}
                            createdAt={eventData.createdAt}
                            type={eventData.type}
                          />
                        );
                        break;
                      case "newList":
                        activityCard = (
                          <ActivityCard
                            key={eventData.id}
                            listName={eventData.listName}
                            listImage={eventData.listImage}
                            listId={eventData.listId}
                            userId={eventData.userId}
                            createdAt={eventData.createdAt}
                            type={eventData.type}
                          />
                        );
                        break;
                      default:
                        activityCard = null;
                    }
                    if (activityCard) {
                      acc.cards.push(activityCard);
                      acc.lastEventSignature = currentEventSignature;
                    }
                  }
                }
                return acc;
              }, { cards: [], lastEventSignature: '' }).cards}
              {isEmpty && <DSText>No activity found. Interact with other users to discover new palettes!</DSText>}
            </>
          ) : (
            <DSText>Loading...</DSText>
          )}
        </div>
        <FeatureRequestForm />
      </DSContainer>
    </>
  );
};

export default ActivityPage;
