import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { db, auth } from "../../../../firebase.js";
import { doc, getDoc } from "firebase/firestore";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { handleUsernameChange, createUserProfileAndSendEmail, scheduleRecentlyJoinedEmail } from '../../../../Utils/index.js';
import queryString from 'query-string';

const SignupGoogleForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { redirectTo } = queryString.parse(location.search);
    const [username, setUsername] = useState('');
    const [duplicateUsername, setDuplicateUsername] = useState(false);
    const [usernameErrorMessage, setUsernameErrorMessage] = useState('');
    const [accountCreated, setAccountCreated] = useState(false);

    useEffect(() => {
        if (accountCreated) {
            navigate(!redirectTo ? `/${username}` : redirectTo);
        }
    }, [navigate, username, accountCreated, redirectTo]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            const userRef = doc(db, "members", user.email);
            const docSnap = await getDoc(userRef);
            if (!docSnap.exists()) {
                await createUserProfileAndSendEmail(user.email, user.displayName, "", user.uid, username, user.photoURL, setAccountCreated);
                const sendDate = Date.now() + 5 * 24 * 60 * 60 * 1000; // 5 days in milliseconds
                await scheduleRecentlyJoinedEmail(user.email, username, user.displayName, sendDate);
                navigate(!redirectTo ? `/${username}` : redirectTo);
            } else {
                navigate(!redirectTo ? `/${docSnap.data().username}` : redirectTo);
            }
        } catch (error) {
            console.error("Error signing in with Google: ", error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <label>Username</label>
            <input
                id="username"
                type="text"
                value={username}
                onChange={(e) => handleUsernameChange(e, setUsername, setUsernameErrorMessage, setDuplicateUsername)}
                required
            />
            {duplicateUsername && <div className="invalid">{usernameErrorMessage}</div>}
            <div className="signup-checkbox-container">
                <input type="checkbox" className="signup-checkbox" checked />
                <div className="signup-text">I agree to receive product update and transactional emails.</div>
            </div>
            <div style={{ marginTop: "20px" }}>
                <button className="continue-google-button" disabled={duplicateUsername}>
                    <img src="/google-icon-logo.svg" className="continue-icon" alt="Google" />
                    Sign up with Google
                </button>
            </div>
        </form>
    );
};

export default SignupGoogleForm;