import React from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { moveCard } from '../../../Utils';

const MoveCardsUpDown = ({ userOwnsList, isRecommendation, setSelectedEntriesData, index, listId, selectedEntriesData }) => {
    const moveUp = (event) => {
        event.preventDefault();
        if (index !== 0) {
            moveCard(index, index - 1, selectedEntriesData, setSelectedEntriesData, listId);
        }
    };

    const moveDown = (event) => {
        event.preventDefault();
        moveCard(index, index + 1, selectedEntriesData, setSelectedEntriesData, listId);
    };

    return (
        <>
            {userOwnsList && !isRecommendation && setSelectedEntriesData && (
                <KeyboardArrowUpIcon className="upIcon" onClick={moveUp} />
            )}
            {userOwnsList && !isRecommendation && setSelectedEntriesData && (
                <KeyboardArrowDownIcon className="downIcon" onClick={moveDown} />
            )}
        </>
    );
};

export default MoveCardsUpDown;
