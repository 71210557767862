import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from "../../../../firebase.js";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { handleUsernameChange, createUserProfileAndSendEmail, scheduleRecentlyJoinedEmail } from '../../../../Utils/index.js';
import queryString from 'query-string';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const SignupEmailForm = ({ setSignupType }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { redirectTo, id } = queryString.parse(location.search);
    const [email, setEmail] = useState(!id ? '' : id);
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [fullName, setFullName] = useState('');
    const [errorMessage, setErrorMessage] = useState('')
    const [username, setUsername] = useState('');
    const [duplicateUsername, setDuplicateUsername] = useState(false);
    const [usernameErrorMessage, setUsernameErrorMessage] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('')
        if (!duplicateUsername) {
            try {
                const userCredential = await createUserWithEmailAndPassword(
                    auth,
                    email,
                    password
                );
                const user = userCredential.user;
                let nameArr = fullName.trim().split(" ", 2);
                let lastName = ""
                if (nameArr.length > 1) {
                    lastName = nameArr[1]
                }
                await createUserProfileAndSendEmail(email, nameArr[0], lastName, user.uid, username);
                const sendDate = Date.now() + 5 * 24 * 60 * 60 * 1000; // 5 days in milliseconds
                await scheduleRecentlyJoinedEmail(email, username, nameArr[0], sendDate);
            } catch (error) {
                if (error.code === 'auth/weak-password') {
                    setErrorMessage('The password is too weak.');
                } else if (error.code === 'auth/email-already-in-use') {
                    setErrorMessage('The email address is already in use by another account.');
                } else {
                    console.error('Error: ' + error.message);
                }
            }
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <label>Name</label>
            <input
                type="text"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                autoComplete="name"
                required
            />
            <label>Email</label>
            <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
                required
            />
            {errorMessage && errorMessage.includes("email") && <div className="invalid">{errorMessage}</div>}
            <label>Username</label>
            <input
                type="text"
                value={username}
                onChange={(e) => handleUsernameChange(e, setUsername, setUsernameErrorMessage, setDuplicateUsername)}
                required
            />
            {duplicateUsername && <div className="invalid">{usernameErrorMessage}</div>}
            <label>Password</label>
            <div className="passwordContainer">
                <input
                    className='passwordInput'
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="new-password"
                    required
                />
                <div onClick={() => setShowPassword(!showPassword)} className="passwordButton">
                    {showPassword
                        ? <VisibilityOffIcon className="passwordIcon" />
                        : <VisibilityIcon className='passwordIcon' />
                    }
                </div>
            </div>
            {errorMessage && errorMessage.includes("password") && <div className="invalid">{errorMessage}</div>}
            <div className="signup-checkbox-container">
                <input type="checkbox" className="signup-checkbox" checked />
                <div className="signup-text">I agree to receive product update and transactional emails.</div>
            </div>
            <div style={{ marginTop: "20px" }}>
                <button className="continue-email-button" type="submit">
                    <img src="/email-icon-logo.svg" className="continue-icon" alt="Email" />
                    Sign up
                </button>
                <span style={{ marginRight: "9px" }}>or</span>
                <button className="continue-google-button" type="button" onClick={() => {
                    setSignupType("google");
                    navigate(!redirectTo ? "/signup?type=google" : "/signup?type=google&redirectTo=" + redirectTo);
                }}>
                    <img src="/google-icon-logo.svg" className="continue-icon" alt="Google" />
                    <span className="hide-on-mobile">Sign up with Google</span>
                    <span className="show-on-mobile">Use Google</span>
                </button>
            </div>
        </form>
    );
};

export default SignupEmailForm;