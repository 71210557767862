import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from "../../../firebase";
import { collection, query, where, getDocs } from 'firebase/firestore';
import { chunk } from 'lodash';
import { getTextForListOfCategory, updateListOnSort } from '../../../Utils';
import SportsKabaddiIcon from '@mui/icons-material/SportsKabaddi';
import ListCard from '../../ListCard';
import DSText from '../../../DSSystem/DSText';
import DSListFilterSelect from '../../../DSSystem/DSListFilterSelect';

const FollowingListsTab = ({ userData, likedLists, setLikedLists, followingData, isFollowingLoading }) => {
  const [allListData, setAllListData] = useState([]);
  const navigate = useNavigate();
  const observer = useRef();
  const sentinelRef = useRef();
  const batchSize = 10; // Number of lists to load each time
  const [isLoading, setIsLoading] = useState(isFollowingLoading);
  const [loadedListData, setLoadedListData] = useState([]);
  const [lastLoadedIndex, setLastLoadedIndex] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const categories = ['All', 'Movies', 'TV Shows', 'Books', 'Video Games', 'Web Pages', 'Freestyle'];
  const [selectedCategory, setSelectedCategory] = useState('All');
  const defaultSortOption = 'Relevant';
  const [sortOptions, setSortOptions] = useState(defaultSortOption);
  const [filteredListData, setFilteredListData] = useState([]);

  const [hoveredId, setHoveredId] = useState(null);
  const [flagArray, setFlagArray] = useState([]);
  const [isHoverArray, setIsHoverArray] = useState([]);

  const handleSortOptions = (event) => {
    setSortOptions(event.target.value)
  }

  useEffect(() => {
    const fetchFollowingLists = async () => {
      setIsLoading(true);
      if (!followingData) {
        return;
      }
      try {
        const allListIds = [];
        for (let i = 0; i < followingData.length; i++) {
          const followingId = followingData[i];
          const q = query(collection(db, 'members'), where('id', '==', followingId));
          try {
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
              const followerDoc = querySnapshot.docs[0];
              const followerDocFromDB = followerDoc.data();
              if (followerDocFromDB.lists) {
                allListIds.push(...followerDocFromDB.lists);
              }
            }
          } catch (error) {
            console.error('Error querying database:', error);
          }
        };
        const querySnapshots = await Promise.all(
          chunk(allListIds, 30).map((allListIdsBatch) => {
            return getDocs(query(collection(db, 'lists'), where('id', 'in', allListIdsBatch), where('isPublic', '==', true)));
          })
        );
        let lists = querySnapshots.flatMap((querySnapshot) => querySnapshot.docs).slice().map(doc => doc.data());
        lists = updateListOnSort(lists, defaultSortOption);
        if (lists.length === 0) {
          setIsLoading(false);
        }
        setAllListData(lists);
        setFlagArray(Array(lists.length).fill(false));
        setIsHoverArray(Array(lists.length).fill(false));
      } catch (error) {
        console.error('Error fetching user lists:', error.message);
      }
    };

    fetchFollowingLists();
    return () => observer.current?.disconnect();
  }, [followingData])

  useEffect(() => {
    if (allListData.length === 0) {
      return;
    }
    const lists = updateListOnSort(allListData, sortOptions)
    setAllListData(lists);
    setLoadedListData(lists.slice(0, batchSize));
    setLastLoadedIndex(batchSize);
    setHasMore(lists.length > batchSize);
  }, [allListData, sortOptions])

  useEffect(() => {
    const filterListsByCategory = (lists) => {
      return lists.filter(list => selectedCategory === 'All' || list.category === selectedCategory);
    };

    if (loadedListData.length === 0) {
      return;
    }
    setFilteredListData(filterListsByCategory(loadedListData));
    setIsLoading(false);
  }, [loadedListData, selectedCategory])

  useEffect(() => {
    const options = {
      root: null,
      threshold: 0.1,
    };

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setLoadedListData(allListData.slice(0, lastLoadedIndex + batchSize));
        setLastLoadedIndex(lastLoadedIndex + batchSize);
        setHasMore(allListData.length > lastLoadedIndex + batchSize);
      }
    }, options);

    if (sentinelRef.current) {
      observer.current.observe(sentinelRef.current);
    }

    return () => observer.current?.disconnect();
  }, [allListData, hasMore, lastLoadedIndex]);

  return (
    <>
      <div style={{ marginBottom: '6px' }}>
        {categories.map(category => (
          <button
            key={category}
            className={`category-button category-button-selected-${selectedCategory === category}`}
            onClick={() => setSelectedCategory(category)}
          >
            {category}
          </button>
        ))}
      </div>
      <div className="exploreFilterContainer">
        <DSListFilterSelect sortOptions={sortOptions} handleSortOptions={handleSortOptions} />
        <button className="exploreChallengesButton" onClick={() => navigate('/challenge/all')}>
          <SportsKabaddiIcon className="exploreChallengesIcon" />
          Challenges
        </button>
      </div>
      {isLoading
        ? (
          <DSText marginTop="15px" marginBottom="40px">Loading...</DSText>
        ) : filteredListData.length > 0 ? (
          <div className="listCardContainerWithSentinel">
            {filteredListData.map((listData, index) => (
              <ListCard
                key={listData.id}
                listData={listData}
                userData={userData}
                likedLists={likedLists}
                setLikedLists={setLikedLists}
                setAllListData={setAllListData}
                index={index}
                hoveredId={hoveredId}
                setHoveredId={setHoveredId}
                flagArray={flagArray}
                setFlagArray={setFlagArray}
                isHoverArray={isHoverArray}
                setIsHoverArray={setIsHoverArray}
              />
            ))}
          </div>
        ) : (
          <DSText marginTop="15px" marginBottom="40px">{followingData && followingData.length > 0 ? `None of the people you follow have public ${getTextForListOfCategory(selectedCategory, true)}!` : "Start following people to see their lists!"}</DSText>
        )}
      <div ref={sentinelRef} style={{ height: '20px' }}></div>
    </>
  )
}

export default FollowingListsTab;