import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth, db, storage } from "../../firebase.js";
import { onAuthStateChanged } from 'firebase/auth';
import { collection, query, where, getDocs, updateDoc, doc, arrayUnion, setDoc, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { Helmet } from 'react-helmet';
import { ReactMultiEmail } from 'react-multi-email';
import { updateUserStreak } from '../../Utils';
import axios from "axios";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DSContainer from '../../DSSystem/DSContainer';
import DSButton from '../../DSSystem/DSButton';
import DSText from '../../DSSystem/DSText';
import DSNavbar from "../../DSSystem/DSNavbar";
import FeatureRequestForm from '../FeatureRequestForm';
import 'react-multi-email/dist/style.css';
import './styles.css';

const CreateListPage = () => {
  const requireEmailVerification = process.env.REACT_APP_REQUIRE_EMAIL_VERIFICATION;
  const navigate = useNavigate();
  const location = useLocation();
  const [userData, setUserData] = useState(null);
  const [listName, setListName] = useState('');
  const [collaboratorEmails, setCollaboratorEmails] = useState([]);
  const [errorMessage, setErrorMessage] = useState('')
  const [listCategory, setListCategory] = useState('Movies');
  const [listImage, setListImage] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isCreatingList, setIsCreatingList] = useState(false);

  useEffect(() => {
    const fetchUserData = async (userId) => {
      try {
        const q = query(collection(db, 'members'), where('id', '==', userId));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const userDoc = querySnapshot.docs[0];
          const userDataFromDB = userDoc.data();
          setUserData(userDataFromDB);
          await updateUserStreak(userDataFromDB)
        } else {
          console.error('User not found');
          navigate("/500");
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
        navigate("/500");
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && (user.emailVerified || requireEmailVerification === "false")) {
        fetchUserData(user.uid);
      } else {
        navigate("/login");
        setUserData(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [navigate, requireEmailVerification]);

  const handleListNameChange = (event) => {
    setListName(event.target.value);
  };

  const handleListCategoryChange = (event) => {
    setListCategory(event.target.value);
  };

  const sendCollaboratorEmail = async (listId, collaboratorDocRef) => {
    let collaboratorFirstName;
    const collaboratorDocSnap = await getDoc(collaboratorDocRef);
    if (collaboratorDocSnap.exists()) {
      const { firstName } = collaboratorDocSnap.data();
      collaboratorFirstName = firstName
    } else {
      console.error("Collaborator not found");
      return;
    }
    let email = collaboratorDocSnap.data().email;
    let subject = `${userData.firstName} invited you to collaborate on Palette!`;
    let template = "Invite Collaborator";
    let variables = {
      listOwner: userData.firstName,
      collaborator: collaboratorFirstName,
      listName: listName === "" ? "Untitled List" : listName,
      listCategory: listCategory.toLowerCase(),
      link: window.location.origin + "/list/" + listId
    };
    try {
      await axios.post(`/api/email`, { email, subject, template, variables });
    } catch (error) {
      console.error('Error sending email:', error.message);
    }
  }

  const createNewList = async (listId, collaboratorsData) => {
    try {
      let listImageURL = null;
      if (listImage) {
        listImageURL = await uploadListImage(listId);
      }
      const notifsUUID = uuidv4()
      // Create list document
      const newList = {
        data: [],
        id: listId,
        memberId: [userData.id, ...(collaboratorsData ? collaboratorsData.map(obj => obj.id) : [])].filter(Boolean),
        score: 0,
        likedBy: [],
        title: listName || "Untitled List",
        category: listCategory,
        isPublic: false,
        listImage: listImageURL,
        createdTime: Date.now(),
        lastUpdated: Date.now(),
        notifications: notifsUUID,
        commentId: uuidv4()
      };
      await setDoc(doc(db, "lists", listId), newList);

      // Add notifications document
      const newNotifs = {
        id: notifsUUID,
        events: []
      }
      await setDoc(doc(db, "listNotifications", notifsUUID), newNotifs)

      // Update owner data
      if (userData && userData.email) {
        const userDocRef = doc(db, 'members', userData.email);
        await updateDoc(userDocRef, {
          lists: arrayUnion(listId)
        });
      } else {
        console.error('User data or email not available');
        navigate("/500");
      }

      for (let i = 0; i < collaboratorsData.length; i++) {
        let currentCollabData = collaboratorsData[i]
        if (currentCollabData) {
          const collaboratorDocRef = doc(db, 'members', currentCollabData.email);
          await updateDoc(collaboratorDocRef, {
            lists: arrayUnion(listId)
          });
          await sendCollaboratorEmail(listId, collaboratorDocRef);
        }
      }
    } catch (error) {
      console.error('Error creating a new list:', error.message);
      navigate("/500");
    }
  }

  const handleListImageChange = (event) => {
    const selectedImage = event.target.files[0];
    setListImage(selectedImage);
  };

  const uploadListImage = async (listId) => {
    if (listImage) {
      const storageRef = ref(storage, `listImages/${listId}`);
      const snapshot = await uploadBytes(storageRef, listImage);
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL;
    }
    return null;
  };

  const handleCreateListSubmit = async (e) => {
    e.preventDefault();

    if (isCreatingList) {
      return;
    }
    setIsCreatingList(true);

    let collaboratorsData = [];

    for (let i = 0; i < collaboratorEmails.length; i++) {
      let currentCollabEmail = collaboratorEmails[i]
      if (currentCollabEmail === userData.email) {
        setErrorMessage(`${currentCollabEmail} is already a collaborator!`);
        setIsCreatingList(false);
        return;
      }

      const userDocRef = doc(db, 'members', currentCollabEmail);
      const userDocSnap = await getDoc(userDocRef);
      if (!userDocSnap.exists()) {
        setErrorMessage(`No account found for ${currentCollabEmail}. We have sent them an invitation to join Palette!`);
        // Growth Hack: Let's send an email to the user asking them to join
        let email = currentCollabEmail;
        let subject = "Someone is trying to add you as a collaborator on Palette!";
        let template = "Collaborator Not On Palette";
        let variables = {
          senderFirstName: userData.firstName,
          senderLink: window.location.origin + "/" + userData.username
        };
        try {
          await axios.post(`/api/email`, { email, subject, template, variables });
        } catch (error) {
          console.error('Error sending email:', error.message);
        }
        setIsCreatingList(false);
        return;
      }
      collaboratorsData.push(userDocSnap.data());
    }

    let listId = uuidv4();
    await createNewList(listId, collaboratorsData);
    navigate(`/list/${listId}`);
  };

  return (
    <>
      <Helmet>
        <title>Create - Palette</title>
        <meta property="og:title" content="Create - Palette" />
        <link rel="canonical" href={"https://palette.build" + location.pathname} />
        <meta property="og:url" content={"https://palette.build" + location.pathname} />
        <meta property="og:image" content="https://palette.build/landing.png" />
        <meta property="og:description" content="Curate, share, and discover movies, TV shows, books, and more!" />
        <meta name="keywords" content={`palette, lists, social media, create`} />
      </Helmet>
      {userData && <DSNavbar isLoggedIn={true} currentPage={"Create"} userData={userData} />}
      <DSContainer>
        {userData ? (
          <div className="card">
            <form onSubmit={handleCreateListSubmit}>
              <DSText fontSize="1.65em" color="#1e1e1e" boldVar="600" marginBottom="20px">Create New List</DSText>
              <label>Title</label>
              <input
                type="text"
                id="listName"
                name="listName"
                value={listName}
                onChange={handleListNameChange}
              />
              <label>Category</label>
              <Select
                id="listCategory"
                name="listCategory"
                value={listCategory}
                onChange={handleListCategoryChange}
                size='small'
                sx={{
                  display: "block",
                  width: "100%",
                  boxSizing: "border-box",
                  padding: 0,
                  marginTop: "7px",
                  marginBottom: "20px",
                  fontFamily: "'Outfit', sans-serif !important",
                  fontSize: "0.95em",
                  borderRadius: "7px",
                  backgroundColor: "#fafafa",
                  color: "#1e1e1e",
                  '.MuiOutlinedInput-notchedOutline': {
                    border: '1px solid #1e1e1e6d',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    border: '1px solid #1e1e1e6d',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: '1.5px solid #645dfc',
                  },
                }}
                MenuProps={{
                  sx: {
                    "& .MuiMenuItem-root": {
                      fontFamily: "'Outfit', sans-serif !important",
                    },
                    "& .MuiMenuItem-root:hover": {
                      backgroundColor: "#655dfc15"
                    },
                    "&& .Mui-selected": {
                      backgroundColor: "#655dfc25"
                    },
                  }
                }}
              >
                <MenuItem value="Movies">Movies</MenuItem>
                <MenuItem value="TV Shows">TV Shows</MenuItem>
                <MenuItem value="Books">Books</MenuItem>
                <MenuItem value="Video Games">Video Games</MenuItem>
                <MenuItem value="Web Pages">Web Pages</MenuItem>
                <MenuItem value="Freestyle">Freestyle</MenuItem>
              </Select>
              <label>Thumbnail <span style={{ marginLeft: "2px", color: "#bfbfbf" }}>(Optional)</span></label>
              <input
                type="file"
                id="listImage"
                name="listImage"
                accept="image/*"
                onChange={handleListImageChange}
              />
              <label>Collaborators <span style={{ marginLeft: "2px", color: "#bfbfbf" }}>(Optional)</span></label>
              <div className='multiEmail'>
                <ReactMultiEmail
                  placeholder='Type emails and press enter'
                  emails={collaboratorEmails}
                  onChange={(_emails) => {
                    setCollaboratorEmails(_emails);
                  }}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  style={{
                    border: isFocused ? '1.5px solid #645dfc' : '1px solid #1e1e1e6d',
                    backgroundColor: "#fafafa",
                    alignItems: "normal"
                  }}
                  autoFocus={true}
                  getLabel={(email, index, removeEmail) => {
                    return (
                      <div data-tag key={index}
                        style={{
                          fontFamily: "'Outfit', sans-serif",
                          maxHeight: "1em"
                        }}>
                        <div data-tag-item>{email}</div>
                        <span data-tag-handle onClick={() => removeEmail(index)}>
                          ×
                        </span>
                      </div>
                    );
                  }}
                />
              </div>
              {errorMessage && <div className="invalid">{errorMessage}</div>}
              <DSButton buttonText="Start Curating" />
            </form>
          </div>
        ) : (
          <DSText>Loading...</DSText>
        )}
        <FeatureRequestForm />
      </DSContainer>
    </>
  );
};

export default CreateListPage;
