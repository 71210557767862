import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { auth, db } from '../../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { Helmet } from 'react-helmet';
import { updateUserStreak, handleGroupListCreation } from '../../Utils';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SportsKabaddiIcon from '@mui/icons-material/SportsKabaddi';
import ListAltIcon from '@mui/icons-material/ListAlt';
import MovieIcon from '@mui/icons-material/Movie';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import ChallengeListsTab from './Lists';
import ChallengeItemsTab from './Items';
import DSContainer from '../../DSSystem/DSContainer';
import DSNavbar from '../../DSSystem/DSNavbar';
import DSText from '../../DSSystem/DSText';
import DSChallengeModal from '../../DSSystem/DSModal/DSChallengeModal';
import { Link } from 'react-router-dom';
import SmartButtonIcon from '@mui/icons-material/SmartButton';
import './styles.css';

const ChallengePage = () => {
  const navigate = useNavigate();
  const { challengeId } = useParams();
  const location = useLocation();
  const latestChallengeId = 1; // Manually update when we want to make a new challenge live
  const [challengeTitle, setChallengeTitle] = useState("");
  const [challengeCategory, setChallengeCategory] = useState("");
  const [userData, setUserData] = useState(null);
  const [likedLists, setLikedLists] = useState([]);
  const [allListData, setAllListData] = useState(null);
  const [challengeIdForItemsMap, setChallengeIdForItemsMap] = useState(null);
  const [itemsMap, setItemsMap] = useState(null);
  const [isListsTab, setIsListsTab] = useState(true);
  const [isCreatingList, setIsCreatingList] = useState(false);
  const [challengeModal, setChallengeModal] = useState(false);
  const requireEmailVerification = process.env.REACT_APP_REQUIRE_EMAIL_VERIFICATION;

  useEffect(() => {
    const fetchChallengeData = async () => {
      if (challengeId) {
        try {
          const userRef = doc(db, 'Challenge', challengeId);
          const docSnap = await getDoc(userRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            setChallengeTitle(data.title);
            setChallengeCategory(data.category);
          } else {
            console.error("Challenge ID is invalid.");
            navigate("/500");
          }
        } catch (error) {
          console.error("Error fetching challenge:", error);
          navigate("/500");
        }
      } else {
        navigate("/challenge/" + latestChallengeId);
      }
    };

    fetchChallengeData();
  }, [challengeId, navigate, latestChallengeId]);

  useEffect(() => {
    const fetchUserData = async (userId) => {
      try {
        const q = query(collection(db, 'members'), where('id', '==', userId));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const userDoc = querySnapshot.docs[0];
          const userDataFromDB = userDoc.data();
          setUserData(userDataFromDB);
          setLikedLists(userDataFromDB.likedLists || []);
          await updateUserStreak(userDataFromDB);
        } else {
          console.error('User not found');
          navigate("/500");
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
        navigate("/500");
      }
    };

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        if (user.emailVerified || requireEmailVerification === "false") {
          fetchUserData(user.uid);
        } else {
          await signOut();
          navigate("/");
        }
      }
    });

    return () => unsubscribe();
  }, [navigate, requireEmailVerification]);

  useEffect(() => {
    if (allListData === null || challengeIdForItemsMap === challengeId) {
      return;
    } else if (allListData.length === 0) {
      setItemsMap({});
      return;
    }

    const itemsMapTemp = {};

    const getWeightForIndex = (index) => {
      const indexWeights = [642, 541, 455, 370, 308, 247, 192, 156, 123, 105];
      if (index < 10) {
        return indexWeights[index];
      } else if (index < 20) {
        return (21 - index) * 3;
      }
      return 10;
    };

    const processCard = (card, index, likes) => {
      if (!itemsMapTemp[card.id]) {
        itemsMapTemp[card.id] = card;
        itemsMapTemp[card.id]['frequency'] = 0;
        itemsMapTemp[card.id]['score'] = 0;
      }
      itemsMapTemp[card.id]['frequency']++;
      itemsMapTemp[card.id]['score'] += getWeightForIndex(index) + likes * 5;
    };

    const populateItemsMap = async (lists) => {
      const promises = lists.reduce((acc, list, outerIndex) => {
        list.data.reverse().forEach((card, innerIndex) => {
          acc.push(new Promise((resolve) => {
            processCard(card, innerIndex, list.likedBy.length);
            resolve();
          }));
        });
        return acc;
      }, []);

      await Promise.all(promises);
      setItemsMap(itemsMapTemp);
      setChallengeIdForItemsMap(challengeId);
    };

    populateItemsMap(allListData);
  }, [allListData, challengeId, challengeIdForItemsMap]);

  const handleChallengeModalClose = () => {
    setChallengeModal(false);
  };

  return (
    <>
      {challengeId &&
        <>
          <Helmet>
            <title>
              {challengeId >= 2000
                ? `Custom Challenge #${Number(challengeId) - 2000 + 21} - Palette`
                : challengeId >= 1000
                  ? `Custom Challenge #${Number(challengeId) - 999} - Palette`
                  : `Challenge #${challengeId} - Palette`}
            </title>
            <meta property="og:title" content={
              challengeId >= 2000
                ? `Custom Challenge #${Number(challengeId) - 2000 + 21} - Palette`
                : challengeId >= 1000
                  ? `Custom Challenge #${Number(challengeId) - 999} - Palette`
                  : `Challenge #${challengeId} - Palette`
            } />
            <link rel="canonical" href={"https://palette.build" + window.location.pathname} />
            <meta property="og:url" content={"https://palette.build" + window.location.pathname} />
            <meta property="og:description" content="Curate, share, and discover movies, TV shows, books, and more!" />
            <meta name="keywords" content={`palette, lists, social media, challenge`} />
          </Helmet>
          <>
            {userData ? (
              <DSNavbar isLoggedIn={true} userData={userData} />
            ) : (
              <DSNavbar isLoggedIn={false} />
            )}
            <DSContainer justifyContent="start">
              <div className="cardContainer" style={{ marginBottom: "15px" }}>
                <div className="challengeDetails">
                  <DSText fontSize="1em" boldVar="500" color="#cfcfcf" marginTop="9px" marginBottom="3px">
                    {challengeId >= 2000
                      ? `Custom Challenge #${Number(challengeId) - 2000 + 21}`
                      : challengeId >= 1000
                        ? `Custom Challenge #${Number(challengeId) - 999}`
                        : `Challenge #${challengeId}`}
                  </DSText>
                  <DSText fontSize="1.9em" boldVar="600">
                    {challengeTitle}
                  </DSText>
                  <button
                    className="createChallengeListButton"
                    onClick={() => {
                      userData
                        ? handleGroupListCreation(challengeTitle, challengeCategory, userData, navigate, challengeId, isCreatingList, setIsCreatingList, true)
                        : navigate(`/login?redirectTo=${location.pathname}`)
                    }}
                  >
                    <AddCircleIcon className="createChallengeListIcon" />{" "}Create List
                  </button>
                  <button className="challengeFriendButton" onClick={() => setChallengeModal(true)}>
                    <SportsKabaddiIcon className="challengeFriendIcon" />
                    {" "} Challenge Friends
                  </button>
                  <Link to={`/challenge/all`} className="challengeBacklink">
                    <div className="challengeBacklinkText">
                      <SmartButtonIcon className="challengeBacklinkIcon" />
                      Wanna create a custom challenge or see other challenges?
                    </div>
                  </Link>
                </div>

                <div className="challengeContent">
                  <div className="challenge-tab-heading tab-heading-active">
                    How to play?
                  </div>
                  <div style={{ marginBottom: '35px' }}>
                    <DSText lineHeight="1.4em" marginBottom="4px">
                      1. <span style={{ cursor: userData ? "pointer" : "default" }} onClick={() => handleGroupListCreation(challengeTitle, challengeCategory, userData, navigate, challengeId, isCreatingList, setIsCreatingList, true)}>Create a list</span> to add your picks.
                    </DSText>
                    <DSText lineHeight="1.4em" marginBottom="4px">
                      2. Express your support for others' choices by liking their lists on the leaderboard.
                    </DSText>
                    <DSText lineHeight="1.4em" marginBottom="4px">
                      3. <span style={{ cursor: userData ? "pointer" : "default" }} onClick={() => setChallengeModal(true)}>Challenge your friends</span> by inviting them to participate.
                    </DSText>
                    <DSText lineHeight="1.4em">
                      4. <span style={{ cursor: userData ? "pointer" : "default" }} onClick={() => setIsListsTab(false)}>Check out the {challengeCategory} tab</span> to see the top picks.
                    </DSText>
                  </div>
                  <div
                    className={`challenge-tab-heading ${isListsTab && "tab-heading-active"}`}
                    onClick={() => setIsListsTab(true)}
                    style={{
                      "--hover-color": isListsTab ? "#e5e5e5" : "fff",
                      "--hover-cursor": userData ? "pointer" : "default"
                    }}>
                    Leaderboard <ListAltIcon className="challenge-tab-icon" />
                  </div>
                  <div
                    className={`challenge-tab-heading ${!isListsTab && "tab-heading-active"}`}
                    onClick={() => setIsListsTab(false)}
                    style={{
                      "--hover-color": !isListsTab ? "#e5e5e5" : "fff",
                      "--hover-cursor": "pointer"
                    }}>
                    {challengeCategory} {challengeCategory === "Movies"
                      ? <MovieIcon className="challenge-tab-icon" />
                      : challengeCategory === "TV Shows"
                        ? <LiveTvIcon className="challenge-tab-icon" />
                        : challengeCategory === "Books"
                          ? <MenuBookIcon className="challenge-tab-icon" />
                          : <SportsEsportsIcon className="challenge-tab-icon" />}
                  </div>
                  {isListsTab
                    ? (
                      <ChallengeListsTab
                        challengeId={challengeId}
                        challengeCategory={challengeCategory}
                        userData={userData}
                        likedLists={likedLists}
                        setLikedLists={setLikedLists}
                        allListData={allListData}
                        setAllListData={setAllListData}
                      />
                    ) : (
                      <ChallengeItemsTab
                        challengeId={challengeId}
                        challengeCategory={challengeCategory}
                        itemsMap={itemsMap}
                        userData={userData}
                      />
                    )
                  }
                </div>
              </div>
            </DSContainer>
          </>
        </>
      }
      {challengeModal && (
        <DSChallengeModal
          userData={userData}
          onClose={handleChallengeModalClose}
          challengeId={challengeId}
          challengeTitle={challengeTitle}
        />
      )}
    </>
  );
};

export default ChallengePage;