import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { auth, db } from "../../../firebase.js";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { Helmet } from 'react-helmet';
import { getUsernameFromUserId } from '../../../Utils/index.js';
import DSContainer from '../../../DSSystem/DSContainer';
import DSText from '../../../DSSystem/DSText';
import DSButton from '../../../DSSystem/DSButton';
import DSNavbar from '../../../DSSystem/DSNavbar';
import './styles.css';

const WaitlistPage = () => {
  const requireEmailVerification = process.env.REACT_APP_REQUIRE_EMAIL_VERIFICATION;
  const waitlistOn = process.env.REACT_APP_WAITLIST;
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (waitlistOn === "false") {
        navigate("/signup");
      }
      if (user) {
        if (user.emailVerified || requireEmailVerification === "false") {
          let username = await getUsernameFromUserId(user.uid)
          navigate(`/${username}`);
        } else {
          await signOut(auth);
          navigate("/login");
        }
      }
    });
    return () => unsubscribe();
  }, [navigate, waitlistOn, requireEmailVerification]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await setDoc(doc(db, "waitlist", email), {
        email: email,
        name: name,
        time: serverTimestamp(),
      });
      setFormSubmitted(true);
    } catch (error) {
      console.error('Error:', error);
      navigate("/500");
    }
  };

  return (
    <>
      <Helmet>
        <title>Join Waitlist - Palette</title>
        <meta property="og:title" content="Join Waitlist - Palette" />
        <link rel="canonical" href={"https://palette.build" + location.pathname} />
        <meta property="og:url" content={"https://palette.build" + location.pathname} />
        <meta property="og:image" content="https://palette.build/landing.png" />
        <meta property="og:description" content="Curate, share, and discover movies, TV shows, books, and more!" />
        <meta name="keywords" content={`palette, lists, social media, join waitlist`} />
      </Helmet>
      <DSNavbar isLoggedIn={false} />
      <DSContainer>
        <div className="card">
          {formSubmitted ? (
            <div className="success">
              <DSText fontSize="1.2em" color="#645dfc" boldVar="400">
                Thanks for joining the waitlist. We'll be in touch in less than 24 hours.
              </DSText>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <DSText fontSize="1.65em" color="#1e1e1e" boldVar="600">Wanna curate?</DSText>
              <div className="alternative">Have a waitlist code? <Link to="/signup" className="link">Sign Up</Link></div>
              <label style={{ marginTop: '10px' }}>Full Name</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <label>Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autocomplete="email"
                required
                style={{ marginBottom: "20px" }}
              />
              <DSButton buttonText="Join Waitlist" />
            </form>
          )}
        </div>
      </DSContainer>
    </>
  );
};

export default WaitlistPage;
