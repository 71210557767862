import React from 'react';
import { Card, CardMedia, CardContent, Chip } from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import DSText from '../../../../../DSSystem/DSText';
import RenderDate from '../../CardComponents/RenderDate';
import { getListImageFromEntryData } from '../../../../../Utils';
import MoveCardsUpDown from '../../../MoveCardsUpDown'

const CoreCard = ({
    entryData,
    userOwnsList,
    userDoesNotOwnList,
    handleTagTextClick,
    addToOtherList,
    handleRemoveEntry,
    handleEditClick,
    index,
    selectedEntriesData,
    setSelectedEntriesData,
    listId,
    isCurrentlyFiltered
}) => {
    return (
        <div>
            <Card className="freestyleCard">
                <CardMedia
                    className="freestyleCardMedia"
                    component="img"
                    alt={entryData.title}
                    image={getListImageFromEntryData(entryData, "Freestyle")}
                />
                <CardContent className="freestyleCardContent">
                    <DSText fontSize="0.95em" color="#1e1e1e" boldVar="600" lineHeight="1.15em">
                        <div style={{ paddingRight: userOwnsList ? '15px' : '0px' }}>{entryData.title}</div>
                    </DSText>
                    {entryData.subtitle && <DSText fontSize="0.85em" color="#1e1e1e" marginTop="4px" marginBottom="2px">{entryData.subtitle}</DSText>}
                    {((entryData.genres && entryData.genres.length > 0) || userOwnsList) &&
                        <div className="freestyleGenreContainer">
                            {entryData.genres && entryData.genres.length > 0 &&
                                <div className="freestyleGenreChips">
                                    {entryData.genres.map((genre) => (
                                        <Chip key={genre} label={genre} size='small'
                                            sx={{
                                                marginTop: '4px',
                                                marginRight: '4px',
                                                background: '#fafafa',
                                                color: 'black',
                                                border: '1px solid black',
                                                fontFamily: 'Outfit, sans-serif',
                                                fontSize: '0.78em',
                                                padding: '-20px'
                                            }}
                                        />
                                    ))}
                                </div>
                            }
                            {userOwnsList && (
                                <div className="freestyleGenreIconContainer" onClick={handleTagTextClick}>
                                    {
                                        entryData.genres && entryData.genres.length > 0 ? (
                                            <LocalOfferIcon className="freestyleGenreIconUpdate" />
                                        ) : (
                                            <>
                                                <div className="freestyleGenreIconText">Add Tags</div>
                                                <ControlPointIcon className="freestyleGenreIconAdd" />
                                            </>
                                        )
                                    }
                                </div>
                            )}
                        </div>
                    }
                    <RenderDate createdTime={entryData.createdTime} addedBy={entryData.addedBy} />
                    {userDoesNotOwnList && <AddIcon className="addIcon" onClick={addToOtherList} />}
                    {!isCurrentlyFiltered && 
                    <MoveCardsUpDown
                    userOwnsList={userOwnsList}
                    setSelectedEntriesData={setSelectedEntriesData}
                    index={index}
                    selectedEntriesData={selectedEntriesData}
                    listId={listId}
                />}
                </CardContent>
                {userOwnsList && (
                    <>
                        <AddIcon className="addIconOnOwnerList" onClick={addToOtherList} />
                        <ClearIcon
                            className="deleteIcon"
                            onClick={(event) => {
                                event.preventDefault();
                                handleRemoveEntry(index)
                            }}
                        />
                        <EditIcon className="editIcon" onClick={handleEditClick} />
                    </>
                )}
            </Card>
        </div>
    );
};

export default CoreCard;