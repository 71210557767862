import React, { useState, useEffect } from 'react';
import DSText from '../../../DSSystem/DSText';
import './styles.css';

const CategoriesChip = () => {
  const categories = ['movies', 'books', 'TV shows', 'anime', 'video games', 'websites', 'anything'];
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentCategoryIndex((prevIndex) => (prevIndex + 1) % categories.length);
    }, 1500);

    return () => clearInterval(intervalId);
  }, [categories.length]);

  return (
    <div className="chip">
      {categories.map((category, index) => (
        <div
          key={index}
          className={index === currentCategoryIndex ? 'visible category' : 'hidden category'}
        >
          <DSText fontSize="1em" color="#9a95fb" boldVar="600"> {category} </DSText>
        </div>
      ))}
    </div>
  );
};

export default CategoriesChip;
