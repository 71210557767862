import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { db, storage } from "../../../../firebase";
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import TagSelectionDialog from '../CardComponents/TagSelectionDialog';
import EditArticleDialog from '../CardComponents/EditArticleDialog';
import CardCollapsible from '../CardComponents/CardCollapsible';
import DSListModal from '../../../../DSSystem/DSModal/DSListModal';
import CoreCard from './CoreCard';
import './styles.css';

const FreestyleCard = ({ index, entryData, userData, ownerIds, handleRemoveEntry, showAnnotations, handleCategoriesUpdate, setSelectedEntriesData, listId, selectedEntriesData, isCurrentlyFiltered }) => {
    const userExists = userData && ownerIds;
    const userOwnsList = userExists && ownerIds.includes(userData.id);
    const userDoesNotOwnList = userExists && !ownerIds.includes(userData.id);
    const [isTagPopupOpen, setIsTagPopupOpen] = useState(false);
    const [isAddElementModalOpen, setIsAddElementModalOpen] = useState(false);
    const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);

    const toggleTagPopup = () => {
        setIsTagPopupOpen(!isTagPopupOpen);
    };

    const handleTagSubmit = (newTagLabels) => {
        handleCategoriesUpdate(index, newTagLabels);
        toggleTagPopup();
    };

    const handleTagTextClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        toggleTagPopup();
    };

    const addToOtherList = async (e) => {
        e.preventDefault();
        setIsAddElementModalOpen(true);
    }

    const handleCloseModal = () => {
        setIsAddElementModalOpen(false);
    };

    const handleEditClick = (e) => {
        e.preventDefault();
        setIsEditPopupOpen(true);
    };

    const handleEditSubmit = async (updatedData) => {
        try {
            const listDocRef = doc(db, 'lists', listId);
            const listDocSnapshot = await getDoc(listDocRef);
            if (listDocSnapshot.exists()) {
                let originalData = listDocSnapshot.data().data;
                originalData[originalData.length - index - 1].title = updatedData.title;
                originalData[originalData.length - index - 1].subtitle = updatedData.subtitle;
                if (updatedData.url && !updatedData.url.startsWith("http://") && !updatedData.url.startsWith("https://")) {
                    originalData[originalData.length - index - 1].url = "https://" + updatedData.url;
                } else {
                    originalData[originalData.length - index - 1].url = updatedData.url;
                }
                if (updatedData.newImage) {
                    const storageRef = ref(storage, `cardImages/${listId}/${entryData.id}`);
                    const snapshot = await uploadBytes(storageRef, updatedData.newImage);
                    originalData[originalData.length - index - 1].imagePath = await getDownloadURL(snapshot.ref);
                }
                originalData[originalData.length - index - 1].lastUpdated = Date.now();
                const reversedData = [...originalData].reverse();
                await updateDoc(listDocRef, {
                    data: originalData,
                    lastUpdated: Date.now()
                });
                setSelectedEntriesData(reversedData);
            } else {
                console.error('Error updating freestyle card');
            }
        } catch (error) {
            console.error('Error updating freestyle card:', error);
        };
        setIsEditPopupOpen(false);
    }

    return (
        <>
            {entryData.url ? (
                <Link to={entryData.url} target="_blank" className="link">
                    <CoreCard entryData={entryData}
                        userOwnsList={userOwnsList}
                        userDoesNotOwnList={userDoesNotOwnList}
                        handleTagTextClick={handleTagTextClick}
                        addToOtherList={addToOtherList}
                        handleRemoveEntry={handleRemoveEntry}
                        handleEditClick={handleEditClick}
                        selectedEntriesData={selectedEntriesData}
                        setSelectedEntriesData={setSelectedEntriesData}
                        listId={listId}
                        index={index}
                        isCurrentlyFiltered={isCurrentlyFiltered}
                    />
                </Link>
            ) : (
                <CoreCard entryData={entryData}
                    userOwnsList={userOwnsList}
                    userDoesNotOwnList={userDoesNotOwnList}
                    handleTagTextClick={handleTagTextClick}
                    addToOtherList={addToOtherList}
                    handleRemoveEntry={handleRemoveEntry}
                    handleEditClick={handleEditClick}
                    selectedEntriesData={selectedEntriesData}
                    setSelectedEntriesData={setSelectedEntriesData}
                    listId={listId}
                    index={index}
                    isCurrentlyFiltered={isCurrentlyFiltered}
                />
            )}
            <CardCollapsible
                entryData={entryData}
                index={index}
                isEditable={userOwnsList}
                hasRating={entryData.rating && entryData.rating !== 0}
                hasAnnotation={entryData.annotation && entryData.annotation !== ""}
                showAnnotations={showAnnotations}
                setSelectedEntriesData={setSelectedEntriesData}
                listId={listId}
            />
            <TagSelectionDialog
                open={isTagPopupOpen}
                onClose={toggleTagPopup}
                onTagSubmit={handleTagSubmit}
                initialTags={entryData.genres}
            />
            {isAddElementModalOpen && (
                <DSListModal
                    onClose={handleCloseModal}
                    listOfLists={userData.lists}
                    modalType={"Add to Your List"}
                    entryData={entryData}
                    entryCategory={"Freestyle"}
                />
            )}
            {isEditPopupOpen && (
                <EditArticleDialog
                    open={isEditPopupOpen}
                    onClose={() => setIsEditPopupOpen(false)}
                    onSubmit={handleEditSubmit}
                    entryData={entryData}
                    category="freestyle"
                />
            )}
        </>
    );
};

export default FreestyleCard;