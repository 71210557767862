import ClearIcon from '@mui/icons-material/Clear';
import DSText from '../../DSText';
import '../styles.css';

const DSSynopsisModal = ({ onClose, synopsis, name }) => {
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content modal-content-wide" onClick={(e) => e.stopPropagation()}>
        <div>
          <div className="modal-content-padding">
            <DSText fontSize="1.6em" boldVar="600" marginBottom="10px">
              Synopsis for {name}
            </DSText>
          </div>
          <div className="modalListContainer">
            <div dangerouslySetInnerHTML={{ __html: synopsis }} />
          </div>
        </div>
        <ClearIcon
          sx={{
            width: '0.75em',
            position: 'absolute',
            top: '8px',
            right: '8px',
            color: 'white',
            cursor: 'pointer',
            transition: 'all 0.15s ease-in-out',
            '&:hover': {
              backgroundColor: 'transparent !important',
              color: '#ef00e7 !important',
            },
          }}
          onClick={onClose}
        />
      </div>
    </div>
  );
};

export default DSSynopsisModal;