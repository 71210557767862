import React, { useState, useEffect } from 'react';
import { db, auth } from '../../../firebase'
import { collection, query, getDocs, where } from 'firebase/firestore';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import BookCard from '../../List/CategoryCards/BookCard';
import DSContainer from '../../../DSSystem/DSContainer';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import DSText from '../../../DSSystem/DSText';
import { useNavigate } from 'react-router-dom';
import DSNavbar from '../../../DSSystem/DSNavbar';
import { updateUserStreak } from '../../../Utils';
import './styles.css'


const BookExplorePage = () => {
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [genreFilter, setGenreFilter] = useState('All')
  const [genreFiltersAvailable, setGenreFiltersAvailable] = useState([])
  const [filteredBooks, setFilteredBooks] = useState([]);
  const [userData, setUserData] = useState(null);
  const requireEmailVerification = process.env.REACT_APP_REQUIRE_EMAIL_VERIFICATION;
  const navigate = useNavigate();

   // User data
   useEffect(() => {
    const fetchUserData = async (userId) => {
      try {
        const q = query(collection(db, 'members'), where('id', '==', userId));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const userDoc = querySnapshot.docs[0];
          const userDataFromDB = userDoc.data();
          setUserData(userDataFromDB);
          await updateUserStreak(userDataFromDB)
        } else {
          console.error('User not found');
          navigate("/500");
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        if (user.emailVerified || requireEmailVerification === "false") {
          fetchUserData(user.uid);
        } else {
          await signOut(auth);
          navigate("/login");
        }
      }
    });

    return () => {
      unsubscribe();
    };
  }, [navigate, requireEmailVerification]);
  
  const handleGenreFilter = (event) => {
    setGenreFilter(event.target.value);
  }

  useEffect(() => {
    const fetchBooks = async () => {
      const q = query(collection(db, 'Books'));
      const querySnapshot = await getDocs(q);
      const booksArray = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBooks(booksArray);
      setLoading(false);
    };

    fetchBooks();
  }, []);

  useEffect(() => {
    let allGenresSet = new Set();
    
    for (let i = 0; i < books.length; i++) {
      const entryGenres = books[i].genres;
      if (entryGenres) {
        entryGenres.forEach((genre) => {
          allGenresSet.add(genre);
        });
      }
    }
    allGenresSet.add("All");
    const uniqueGenresArray = Array.from(allGenresSet);
    const allIndex = uniqueGenresArray.indexOf("All");
    if (allIndex !== -1) {
      uniqueGenresArray.splice(allIndex, 1);
      uniqueGenresArray.unshift("All");
    }
    setGenreFiltersAvailable(uniqueGenresArray);
  }, [books]);

  useEffect(() => {
    let updatedArr = [];
    if (genreFilter === "All") {
      setFilteredBooks(books);
    } else {
      for (let i = 0; i < books.length; i++) {
        const genres = books[i].genres;
        if (genres) {
          let isGenrePresent;
          isGenrePresent = genres.some((genre) => genre === genreFilter);
          if (isGenrePresent) {
            updatedArr.push(books[i]);
          }
        }
      }
      setFilteredBooks(updatedArr);
    }
  }, [genreFilter, books])

  return (
    <>
      {loading ? (
        <DSContainer>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      </DSContainer>
      ) : (
        <>
        {userData ? (
          <DSNavbar isLoggedIn={true} currentPage={"Explore"} userData={userData} />
          ) : (
          <DSNavbar isLoggedIn={false} currentPage={"Explore"} />
      )}
        <DSContainer justifyContent="start">
        <DSText fontSize="1.8em" boldVar="600">
          Explore Books 📚
        </DSText>
        <div style={{marginTop: "30px", marginBottom:"15px"}}>
        <FormControl
                  className='booksFilter'
                  fullWidth
                  sx={{
                    backgroundColor: 'white',
                    height: '40px',
                    borderRadius: '5px',
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none'
                    }
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={genreFilter}
                    label="Filter by Genre"
                    onChange={handleGenreFilter}
                    sx={{
                      height: '100%',
                      borderRadius: 'inherit',
                      fontFamily: "'Outfit', sans-serif !important"
                    }}
                  >
                    {genreFiltersAvailable.map((genreValue) => (
                      <MenuItem key={genreValue} value={genreValue} sx={{ fontFamily: "'Outfit', sans-serif !important" }}>
                        {genreValue}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {filteredBooks.map(book => (
            <BookCard key={filteredBooks.id} entryData={book} userData={userData} isRecommendation={true} />
          ))}
        </div>
        </DSContainer>
        </>
      )}
      </>
    
  );
};

export default BookExplorePage;
