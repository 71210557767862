import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

const DSButton = ({ buttonText, link, onClick, size, id, isFilled, isLight, target }) => {
  const buttonClass = size === "large" ? "button button-large" : size === "small" ? "button button-small" : "button button-normal";
  const filledClass = isFilled ? "filled" : "regular";
  const lightClass = isLight ? "light" : "";

  if (link) {
    return (
      <div>
        <Link to={link} target={target}>
          <button className={`${buttonClass} ${filledClass} ${lightClass}`} id={id}>
            {buttonText}
          </button>
        </Link>
      </div>
    );
  }

  return (
    <div>
      <button className={`${buttonClass} ${filledClass} ${lightClass}`} onClick={onClick} id={id}>
        {buttonText}
      </button>
    </div>
  );
};

export default DSButton;