import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from "../../../firebase";
import { collection, query, where, getDocs } from 'firebase/firestore';
import { getTextForListOfCategory, updateListOnSort } from '../../../Utils';
import SportsKabaddiIcon from '@mui/icons-material/SportsKabaddi';
import ListCard from '../../ListCard';
import DSText from '../../../DSSystem/DSText';
import DSListFilterSelect from '../../../DSSystem/DSListFilterSelect';

const DiscoverTab = ({ userData, likedLists, setLikedLists }) => {
  const [allListData, setAllListData] = useState([]);
  const navigate = useNavigate();
  const observer = useRef();
  const sentinelRef = useRef();
  const batchSize = 10; // Number of lists to load each time
  const [isLoading, setIsLoading] = useState(true);
  const [loadedListData, setLoadedListData] = useState([]);
  const [lastLoadedIndex, setLastLoadedIndex] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const categories = ['All', 'Movies', 'TV Shows', 'Books', 'Video Games', 'Web Pages', 'Freestyle'];
  const [selectedCategory, setSelectedCategory] = useState('All');
  const defaultSortOption = 'Latest';
  const [sortOptions, setSortOptions] = useState(defaultSortOption);
  const [filteredListData, setFilteredListData] = useState([]);

  const [hoveredId, setHoveredId] = useState(null);
  const [flagArray, setFlagArray] = useState([]);
  const [isHoverArray, setIsHoverArray] = useState([]);

  const handleSortOptions = (event) => {
    setSortOptions(event.target.value)
  }

  useEffect(() => {
    const fetchPublicLists = async () => {
      setIsLoading(true);
      try {
        let q;
        const adminEmails = ["mehulrastogi2002@gmail.com", "mittalprakhar57@gmail.com", "mehulprakharprojects@gmail.com"]
        if (userData && adminEmails.includes(userData.email)) {
          q = query(collection(db, 'lists'))
        } else {
          q = query(collection(db, 'lists'), where('isPublic', '==', true));
        }
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          let lists = querySnapshot.docs.map(doc => doc.data());
          lists = lists.filter(list => list.data && (list.data.length >= 5 || (userData && adminEmails.includes(userData.email))))
          lists = updateListOnSort(lists, defaultSortOption);
          if (lists.length === 0) {
            setIsLoading(false);
          }
          setAllListData(lists);
          setFlagArray(Array(lists.length).fill(false));
          setIsHoverArray(Array(lists.length).fill(false));
        } else {
          setIsLoading(false);
          setAllListData([]);
        }
      } catch (error) {
        console.error('Error fetching public lists with at least 5 elements:', error.message);
      }
    };

    fetchPublicLists();
    return () => observer.current?.disconnect();
  }, [userData]);

  useEffect(() => {
    if (allListData.length === 0) {
      return;
    }
    const lists = updateListOnSort(allListData, sortOptions)
    setAllListData(lists);
    setLoadedListData(lists.slice(0, batchSize));
    setLastLoadedIndex(batchSize);
    setHasMore(lists.length > batchSize);
  }, [allListData, sortOptions])

  useEffect(() => {
    const filterListsByCategory = (lists) => {
      return lists.filter(list => selectedCategory === 'All' || list.category === selectedCategory);
    };

    if (loadedListData.length === 0) {
      return;
    }
    setFilteredListData(filterListsByCategory(loadedListData));
    setIsLoading(false);
  }, [loadedListData, selectedCategory])

  useEffect(() => {
    const options = {
      root: null,
      threshold: 0.1,
    };

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setLoadedListData(allListData.slice(0, lastLoadedIndex + batchSize));
        setLastLoadedIndex(lastLoadedIndex + batchSize);
        setHasMore(allListData.length > lastLoadedIndex + batchSize);
      }
    }, options);

    if (sentinelRef.current) {
      observer.current.observe(sentinelRef.current);
    }

    return () => observer.current?.disconnect();
  }, [allListData, hasMore, lastLoadedIndex]);

  return (
    <>
      <div style={{ marginBottom: '6px' }}>
        {categories.map(category => (
          <button
            key={category}
            className={`category-button category-button-selected-${selectedCategory === category}`}
            onClick={() => setSelectedCategory(category)}
          >
            {category}
          </button>
        ))}
      </div>
      <div className="exploreFilterContainer">
        <DSListFilterSelect sortOptions={sortOptions} handleSortOptions={handleSortOptions} />
        <button className="exploreChallengesButton" onClick={() => navigate('/challenge/all')}>
          <SportsKabaddiIcon className="exploreChallengesIcon" />
          Challenges
        </button>
      </div>
      {isLoading ? (
        <DSText marginTop="15px" marginBottom="40px">Loading...</DSText>
      ) : filteredListData.length > 0 ? (
        <div className="listCardContainerWithSentinel">
          {filteredListData.map((listData, index) => (
            <ListCard
              key={listData.id}
              listData={listData}
              userData={userData}
              likedLists={likedLists}
              setLikedLists={setLikedLists}
              setAllListData={setFilteredListData}
              index={index}
              hoveredId={hoveredId}
              setHoveredId={setHoveredId}
              flagArray={flagArray}
              setFlagArray={setFlagArray}
              isHoverArray={isHoverArray}
              setIsHoverArray={setIsHoverArray}
            />
          ))}
        </div>
      ) : (
        <DSText marginTop="15px" marginBottom="40px">No {getTextForListOfCategory(selectedCategory, true)} found.</DSText>
      )}
      <div ref={sentinelRef} style={{ height: '20px' }}></div>
    </>
  );
};

export default DiscoverTab;
