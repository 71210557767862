import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../../../../firebase.js';
import { signInWithEmailAndPassword } from 'firebase/auth';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const LoginEmailForm = ({ signInWithGoogle }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('')
        try {
            await signInWithEmailAndPassword(
                auth,
                email,
                password
            );
        } catch (error) {
            setErrorMessage('Email or Password is incorrect.')
            console.error(error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <label>Email</label>
            <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autocomplete="email"
                required
            />
            <label>Password</label>
            <div className="passwordContainer">
                <input
                    className='passwordInput'
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    autocomplete="current-password"
                    required
                />
                <div onClick={() => setShowPassword(!showPassword)} className="passwordButton">
                    {showPassword
                        ? <VisibilityOffIcon className="passwordIcon" />
                        : <VisibilityIcon className='passwordIcon' />
                    }
                </div>
            </div>
            <div className="forgotPassword"> <Link to="/forgot-password" className="link">Forgot Password?</Link></div>
            {errorMessage && <div className="invalid">{errorMessage}</div>}
            <button className="continue-email-button" type="submit">
                <img src="/email-icon-logo.svg" className="continue-icon" alt="Email" />
                Log in
            </button>
            <span style={{ marginRight: "9px" }}>or</span>
            <button className="continue-google-button" onClick={signInWithGoogle} type="button">
                <img src="/google-icon-logo.svg" className="continue-icon" alt="Google" />
                <span className="hide-on-mobile">Log in with Google</span>
                <span className="show-on-mobile">Use Google</span>
            </button>
        </form>
    );
};

export default LoginEmailForm;
