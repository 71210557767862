import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import DSText from '../../../../../DSSystem/DSText';
import DSButton from '../../../../../DSSystem/DSButton';
import './styles.css';

const TagSelectionDialog = ({ open, onClose, onTagSubmit, initialTags }) => {
  const [tagInput, setTagInput] = useState('');
  const [chipData, setChipData] = useState([]);

  useEffect(() => {
    if (open && initialTags) {
      const tagsToChips = initialTags.map((label, index) => ({
        key: index,
        label,
      }));
      setChipData(tagsToChips);
    }
  }, [initialTags, open]);

  const handleInputChange = (event) => {
    setTagInput(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && tagInput) {
      event.preventDefault();
      const isDuplicate = chipData.some(chip => chip.label.toLowerCase() === tagInput.toLowerCase());

      if (!isDuplicate) {
        const newChip = { key: Date.now(), label: tagInput };
        setChipData(prevChipData => [...prevChipData, newChip]);
        setTagInput('');
      } else {
        alert("Tag already exists.");
      }
    }
  };


  const handleDeleteChip = (chipToDelete) => () => {
    setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
  };

  const handleSubmit = () => {
    onTagSubmit(chipData.map((chip) => chip.label));
    onClose();
  };

  return (
    <Dialog onClose={onClose} open={open} sx={{
      '& .MuiDialog-paper': {
        width: '80%',
        maxWidth: '400px',
        backgroundColor: '#0a0a0a !important',
        color: 'white'
      },
    }}>
      <DialogTitle>
        <DSText fontSize="1.5em" boldVar="600" marginBottom="-5px">Update Tags</DSText>
      </DialogTitle>
      <DialogContent sx={{
        padding: '20px 24px 10px 24px'
      }}>
        <TextField
          autoFocus
          margin="dense"
          id="tag-input"
          label="Type and press enter to add tags"
          type="text"
          fullWidth
          value={tagInput}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          InputLabelProps={{
            sx: {
              fontFamily: "'Outfit', sans-serif !important",
              marginTop: "-4px !important",
              "&.Mui-focused": {
                display: "none",
              },
              "&.MuiInputLabel-outlined": {
                marginTop: "-5px",
                color: "#1e1e1e"
              }
            },
          }}
          InputProps={{
            spellCheck: 'false',
            disableUnderline: true,
            sx: {
              fontFamily: "'Outfit', sans-serif !important",
              height: "3em",
              border: "none !important",
              "& fieldset": {
                border: "none !important",
              }
            },
          }}
        />
        <div style={{ marginTop: "8px" }}>
          {chipData.map((chip) => (
            <Chip
              key={chip.key}
              label={chip.label}
              onDelete={handleDeleteChip(chip)}
              sx={{
                marginTop: '4px',
                marginRight: '4px',
                background: '#fafafa !important',
                color: 'black',
                border: '1px solid black',
                fontFamily: 'Outfit, sans-serif',
                fontSize: '0.8em',
              }}
            />
          ))}
        </div>
      </DialogContent>
      <DialogActions sx={{ padding: "8px 24px 22px 8px !important" }}>
        <DSButton onClick={onClose} buttonText="Cancel" size="small" isFilled={true} />
        <DSButton onClick={handleSubmit} buttonText="Update" size="small" />
      </DialogActions>
    </Dialog>
  );
};

export default TagSelectionDialog;