import { db } from "../firebase";
import { collection, query, where, getDocs, doc, arrayUnion, arrayRemove, updateDoc, deleteDoc, getDoc, setDoc, runTransaction, increment } from 'firebase/firestore';
import { getStorage, ref, deleteObject, uploadBytes, getDownloadURL } from "firebase/storage";
import { toBlob } from 'html-to-image';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import dayjs from "dayjs";

const storage = getStorage();

/*** Account Management ***/
export const handleUsernameChange = async (event, setUsername, setUsernameErrorMessage, setDuplicateUsername) => {
    const reservedWords = [
        "landing",
        "home",
        "feed",
        "user",
        "profile",
        "artist",
        "creator",
        "join",
        "join-waitlist",
        "waitlist",
        "signup",
        "login",
        "forgot-email",
        "forgot-password",
        "profile-edit",
        "edit-profile",
        "reset-password",
        "account",
        "settings",
        "create",
        "build",
        "curate",
        "palette",
        "list",
        "shade",
        "hue",
        "mix",
        "explore",
        "discover",
        "trending",
        "liked",
        "saved",
        "recommendations",
        "insights",
        "social",
        "fun",
        "gallery",
        "exhibition",
        "assistant",
        "following",
        "undefined",
        "public",
        "private",
        "404",
        "500",
        "challenge",
        "challenges"
    ]
    let currValue = event.target.value;
    currValue = currValue.toLowerCase();
    setUsername(currValue);
    const validUsernameRegex = /^[a-zA-Z0-9._]+$/;
    if (!validUsernameRegex.test(currValue)) {
        setDuplicateUsername(true);
        setUsernameErrorMessage('Usernames can only contain letters, numbers, periods (.), and underscores (_).')
    } else {
        const userQuery = query(collection(db, 'members'), where('username', '==', currValue));
        const querySnapshot = await getDocs(userQuery);
        if (!querySnapshot.empty || reservedWords.includes(currValue)) {
            setDuplicateUsername(true)
            setUsernameErrorMessage("This username is already taken.")
        } else {
            setDuplicateUsername(false)
        }
    }
}

export const getStorageUrlFromGooglePhotoUrl = async (email, photoUrl) => {
    if (email && photoUrl) {
        const response = await fetch(photoUrl);
        const blob = await response.blob();
        const storageRef = ref(storage, `profileImages/${email}`);
        const snapshot = await uploadBytes(storageRef, blob);
        return await getDownloadURL(snapshot.ref);
    }
    return null;
}

export const createUserProfileAndSendEmail = async (userEmail, userFirstName, userLastName, userSignUpId, username, userImage, setAccountCreated) => {
    // Generate a unique ID for profile notifications
    const profileNotifsUUID = uuidv4();

    // Create a new document for the user in the 'members' collection
    await setDoc(doc(db, "members", userEmail), {
        email: userEmail,
        firstName: userFirstName,
        lastName: userLastName,
        id: userSignUpId,
        lists: [],
        likedLists: [],
        followers: [],
        following: [],
        notifications: profileNotifsUUID,
        username: username,
        notificationsTabLastSeenAt: Date.now(),
        notificationsUnseen: 0,
        profileImage: await getStorageUrlFromGooglePhotoUrl(userEmail, userImage)
    });

    // Create a new document for profile notifications
    await setDoc(doc(db, "profileNotifications", profileNotifsUUID), {
        id: profileNotifsUUID,
        events: []
    });

    if (typeof setAccountCreated === "function") {
        setAccountCreated(true);
    }

    // Email details
    let subject = "Congratulations on creating your Palette!";
    let template = "Sign Up";
    let variables = {
        firstName: userFirstName,
        link: window.location.origin + "/" + username
    };

    // Send the email
    try {
        await axios.post(`/api/email`, { email: userEmail, subject, template, variables });
    } catch (error) {
        console.error('Error sending email:', error.message);
    }
}

export const scheduleRecentlyJoinedEmail = async (email, username, firstName, sendDate) => {
    const emailScheduleDoc = {
        email: email,
        username: username,
        firstName: firstName,
        sendDate: sendDate,
        sent: false
    };
    await setDoc(doc(db, "emailSchedules", email), emailScheduleDoc);
};

/*** Edit Lists ***/
export const addListEntry = async (listId, mapToAdd) => {
    try {
        const listDocRef = doc(db, 'lists', listId);
        const listDocSnap = await getDoc(listDocRef);
        if (listDocSnap.exists()) {
            const listData = listDocSnap.data();
            let listHasEntry;
            switch (listData.category) {
                case "Web Pages":
                    listHasEntry = listData.data.some(entry => normalizeUrl(entry.url) === normalizeUrl(mapToAdd.url));
                    break;
                case "Freestyle":
                    listHasEntry = listData.data.some(entry => entry.title === mapToAdd.title);
                    break;
                default:
                    listHasEntry = listData.data.some(entry => entry.id === mapToAdd.id);
                    break;
            }
            if (listHasEntry) {
                return "duplicate";
            }
            await updateDoc(listDocRef, {
                data: arrayUnion(mapToAdd),
                lastUpdated: Date.now()
            });
            return "success";
        }
    } catch (error) {
        console.error('Error adding entry to document: ', error);
        return "failure";
    }
}
function normalizeUrl(url) {
    return url.replace(/(^\w+:|^)\/\//, '').replace(/^www\./, '');
}

export const removeListEntry = async (listId, removedEntryData) => {
    try {
        const listDocRef = doc(db, 'lists', listId);
        await updateDoc(listDocRef, {
            data: arrayRemove(removedEntryData),
            lastUpdated: Date.now()
        });
    } catch (error) {
        console.error('Error removing entry from document: ', error);
    }
};

export const deleteList = async (listId) => {
    try {
        // Get list documents
        let q = query(collection(db, 'lists'), where('id', '==', listId));
        let querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            const listDoc = querySnapshot.docs[0];
            const listDataFromDB = listDoc.data();

            // Update owner documents
            const memberIdArray = listDataFromDB.memberId;
            let memberDataArray = []
            memberIdArray.forEach(async (memberId) => {
                q = query(collection(db, 'members'), where('id', '==', memberId));
                querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const ownerDoc = querySnapshot.docs[0];
                    const ownerDataFromDB = ownerDoc.data();
                    memberDataArray.push(ownerDataFromDB)
                    const updatedLists = ownerDataFromDB.lists.filter(listItem => listItem !== listId);
                    await updateDoc(doc(db, 'members', ownerDoc.id), { lists: updatedLists });
                }
            });

            // Update member documents for those who liked the list
            q = query(collection(db, 'members'), where('likedLists', 'array-contains', listId));
            querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                querySnapshot.forEach(async (userDoc) => {
                    const userDataFromDB = userDoc.data();
                    const updatedLikedLists = userDataFromDB.likedLists.filter(listItem => listItem !== listId);
                    await updateDoc(doc(db, 'members', userDoc.id), { likedLists: updatedLikedLists });
                })
            }

            // Delete list notification events and document
            if (listDataFromDB.hasOwnProperty("notifications")) {
                const listNotification = listDataFromDB.notifications;
                const listNotificationsRef = doc(db, 'listNotifications', listNotification);
                const notificationsSnapshot = await getDoc(listNotificationsRef);
                if (notificationsSnapshot.exists()) {
                    const notificationsDataFromDB = notificationsSnapshot.data();
                    notificationsDataFromDB.events.forEach(async (eventId) => {
                        const eventRef = doc(db, 'notifications', eventId);
                        const eventSnap = await getDoc(eventRef);
                        if (eventSnap.exists()) {
                            // Notification number update
                            for (let i = 0; i < memberDataArray.length; i++) {
                                if (memberDataArray[i].hasOwnProperty("notificationsTabLastSeenAt") && eventSnap.data().createdAt > memberDataArray[i].notificationsTabLastSeenAt) {
                                    const ownerDocRef = doc(db, 'members', memberDataArray[i].email);
                                    await updateDoc(ownerDocRef, {
                                        notificationsUnseen: increment(-1),
                                    });
                                }
                            }
                        }
                        await deleteDoc(eventRef);
                    });
                    await deleteDoc(listNotificationsRef);
                }
            }

            await deleteListNotificationsAndUpdateUnseenCount(listId);

            // Delete list image if present
            if (listDataFromDB.listImage) {
                const listImageRef = ref(storage, listDataFromDB.listImage);
                deleteObject(listImageRef).catch((error) => {
                    console.error("List image deletion failed due to error", error);
                });
            }

            // Delete share images if present
            let shareImageRef = ref(storage, `shareImages/list/${listId}-rect.png`);
            deleteObject(shareImageRef).catch((error) => { });
            shareImageRef = ref(storage, `shareImages/list/${listId}-square.png`);
            deleteObject(shareImageRef).catch((error) => { });

            // Delete list document
            await deleteDoc(listDoc.ref);
            return false;
        } else {
            console.error('No document found with listId:', listId);
        }
    } catch (error) {
        console.error("List deletion failed due to error", error);
    }
    return true;
}

export const handleCollapsibleUpdate = async (listId, index, rating, annotation, checked, checkedTime, setSelectedEntriesData) => {
    try {
        const listDocRef = doc(db, 'lists', listId);
        const listDocSnapshot = await getDoc(listDocRef);
        if (listDocSnapshot.exists()) {
            let originalData = listDocSnapshot.data().data;
            originalData[originalData.length - index - 1].rating = rating;
            if (annotation === "Add review, annotation, or link...") {
                annotation = "";
            }
            originalData[originalData.length - index - 1].annotation = annotation;
            originalData[originalData.length - index - 1].checked = checked;
            if (checkedTime) {
                originalData[originalData.length - index - 1].checkedTime = checkedTime
            }
            originalData[originalData.length - index - 1].lastUpdated = Date.now();
            const reversedData = [...originalData].reverse();
            await updateDoc(listDocRef, {
                data: originalData,
                lastUpdated: Date.now()
            });
            setSelectedEntriesData(reversedData);
        } else {
            console.error('Error updating rating');
        }
    } catch (error) {
        console.error('Error updating rating:', error);
    }
}

/*** List Information ***/
export const getListImageFromEntryData = (entryData, listCategory) => {
    if (entryData) {
        switch (listCategory) {
            case "Movies":
            case "TV Shows":
                if (entryData.posterpath) {
                    return `https://image.tmdb.org/t/p/w300/${entryData.posterpath}`;
                }
                return "https://firebasestorage.googleapis.com/v0/b/list-curation.appspot.com/o/unavailable-300-400.png?alt=media&token=2b642515-9ca0-40a6-b93f-5171b747c67e";
            case "Books":
            case "Video Games":
                if (entryData.posterpath) {
                    return entryData.posterpath;
                }
                return "https://firebasestorage.googleapis.com/v0/b/list-curation.appspot.com/o/unavailable-300-400.png?alt=media&token=2b642515-9ca0-40a6-b93f-5171b747c67e";
            case "Web Pages":
            case "Freestyle":
                return entryData.imagePath;
            default:
                return "https://firebasestorage.googleapis.com/v0/b/list-curation.appspot.com/o/unavailable-200-200.png?alt=media&token=86602359-56be-4622-b2a8-757242e3d3cb";
        }
    }
    return "https://firebasestorage.googleapis.com/v0/b/list-curation.appspot.com/o/unavailable-200-200.png?alt=media&token=86602359-56be-4622-b2a8-757242e3d3cb";
}

export const getListImageFromListId = async (listId) => {
    try {
        const q = query(collection(db, 'lists'), where('id', '==', listId));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            const listDoc = querySnapshot.docs[0];
            const listDataFromDB = listDoc.data();
            if (listDataFromDB.listImage) {
                return listDataFromDB.listImage;
            }
            return getListImageFromEntryData(listDataFromDB.data[listDataFromDB.data.length - 1], listDataFromDB.category)
        }
    } catch (error) {
        console.error("Error fetching list image:", error);
    }
    return null;
};

export const getListTitleFromListId = async (listId) => {
    try {
        const q = query(collection(db, 'lists'), where('id', '==', listId));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            const listDoc = querySnapshot.docs[0];
            const listDataFromDB = listDoc.data();
            return listDataFromDB.title;
        }
    } catch (error) {
        console.error("Error fetching list title:", error);
    }
    return null;
};

export const getListScoreFromListData = (listData) => {
    let score = 0;
    if (listData.likedBy) {
        score += listData.likedBy.length;
    }
    if (listData.score) {
        score += listData.score;
    }
    return score;
}

export const getOwnerDataFromOwnerIds = async (ownerIds) => {
    const ownerDataArray = [];
    for (let i = 0; i < ownerIds.length; i++) {
        let memberId = ownerIds[i];
        try {
            const q = query(collection(db, 'members'), where('id', '==', memberId));
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                const userDoc = querySnapshot.docs[0];
                const userDataFromDB = userDoc.data();
                ownerDataArray.push(userDataFromDB);
            } else {
                console.error("Some owner wasn't found");
            }
        } catch (error) {
            console.error('Error fetching owner data:', error.message);
        }
    }
    return ownerDataArray;
}

/*** List Actions ***/
export const handleLike = async (listName, listId, userData, likedLists, setLikedLists, location, navigate, ownerData, setAllListData = null, listLikedBy = null, setListLikedBy = null) => {
    if (!userData) {
        navigate(`/login?redirectTo=${location.pathname}`);
    } else if (!likedLists.includes(listId)) {
        try {
            if (setAllListData) {
                setAllListData(prevListData => {
                    return prevListData.map(listData => {
                        if (listData.id === listId) {
                            if (!listData.likedBy.includes(userData.id)) {
                                return { ...listData, likedBy: [...listData.likedBy, userData.id] };
                            }
                        }
                        return listData;
                    });
                });
            } else if (listLikedBy && setListLikedBy) {
                if (!listLikedBy.includes(userData.id)) {
                    setListLikedBy([...listLikedBy, userData.id]);
                }
            }
            const listRef = doc(db, 'lists', listId)
            await updateDoc(listRef, {
                likedBy: arrayUnion(userData.id)
            });
            setLikedLists([...likedLists, listId]);
            const userDocRef = doc(db, 'members', userData.email);
            await updateDoc(userDocRef, {
                likedLists: arrayUnion(listId),
            });

            // Notification logic
            const notifOwnerData = ownerData.filter(item => item.id !== userData.id);
            const docSnap = await getDoc(listRef);
            if (docSnap.exists()) {
                const currSnap = docSnap.data();
                let listNotification;
                if (!currSnap.hasOwnProperty("notifications")) {
                    listNotification = uuidv4();
                    await updateDoc(listRef, {
                        notifications: listNotification
                    })
                    await setDoc(doc(db, "listNotifications", listNotification), {
                        id: listNotification,
                        events: []
                    });
                } else {
                    listNotification = currSnap.notifications;
                }
                const listNotificationsRef = doc(db, 'listNotifications', listNotification)
                const eventUUID = uuidv4()
                const newNotificationEvent = {
                    id: eventUUID,
                    type: "like",
                    createdAt: Date.now(),
                    userId: userData.id,
                    listId: listId
                }
                await setDoc(doc(db, "notifications", eventUUID), newNotificationEvent)
                await updateDoc(listNotificationsRef, {
                    events: arrayUnion(eventUUID),
                });
            }

            // Unseen Notifications Count
            for (let i = 0; i < notifOwnerData.length; i++) {
                const ownerDocRef = doc(db, 'members', notifOwnerData[i].email);
                await runTransaction(db, async (transaction) => {
                    const ownerDoc = await transaction.get(ownerDocRef);
                    const notificationsUnseen = ownerDoc.data()?.notificationsUnseen;
                    if (notificationsUnseen !== undefined) {
                        // If notificationsUnseen exists, increment it by 1
                        transaction.update(ownerDocRef, { notificationsUnseen: notificationsUnseen + 1 });
                    } else {
                        // If notificationsUnseen does not exist, set it to 1
                        transaction.set(ownerDocRef, { notificationsUnseen: 1 }, { merge: true });
                    }
                });
            }

            // Send email to list owners
            for (let i = 0; i < notifOwnerData.length; i++) {
                const ownerDocRef = doc(db, 'members', notifOwnerData[i].email);
                const ownerSnap = await getDoc(ownerDocRef);
                if (ownerSnap.exists()) {
                    const ownerData = ownerSnap.data();
                    let email = ownerData.email;
                    let subject = "Your list has a new like on Palette!";
                    let template = "New Like";
                    let variables = {
                        userFirstName: ownerData.firstName,
                        listName: listName === "" ? "Untitled List" : listName,
                        link: window.location.origin + "/list/" + listId,
                        likerFirstName: userData.firstName,
                        likerLink: window.location.origin + "/" + userData.username
                    };
                    try {
                        await axios.post(`/api/email`, { email, subject, template, variables });
                    } catch (error) {
                        console.error('Error sending email:', error.message);
                    };
                }
            }
        } catch (error) {
            console.error('Error updating upvote:', error.message);
        }
    }
};

export const handleUnlike = async (listId, userData, likedLists, setLikedLists, ownerData, setAllListData = null, listLikedBy = null, setListLikedBy = null) => {
    if (likedLists.includes(listId)) {
        try {
            if (setAllListData) {
                setAllListData(prevListData => {
                    return prevListData.map(listData => {
                        if (listData.id === listId) {
                            const updatedLikedBy = listData.likedBy.includes(userData.id)
                                ? listData.likedBy.filter(id => id !== userData.id)
                                : [...listData.likedBy, userData.id];
                            return { ...listData, likedBy: updatedLikedBy };
                        }
                        return listData;
                    });
                });
            } else if (listLikedBy && setListLikedBy) {
                setListLikedBy(listLikedBy.includes(userData.id)
                    ? listLikedBy.filter(id => id !== userData.id)
                    : [...listLikedBy, userData.id]);
            }
            const listRef = doc(db, 'lists', listId);
            await updateDoc(listRef, {
                likedBy: arrayRemove(userData.id)
            });
            setLikedLists((prevLikedLists) => prevLikedLists.filter((id) => id !== listId));
            const userDocRef = doc(db, 'members', userData.email);
            await updateDoc(userDocRef, {
                likedLists: arrayRemove(listId),
            });

            // Notification logic
            const notifOwnerData = ownerData.filter(item => item.id !== userData.id);
            const notificationRef = collection(db, "notifications");
            const deleteQuery = query(notificationRef,
                where("userId", "==", userData.id),
                where("listId", "==", listId),
                where("type", "==", "like")
            );
            getDocs(deleteQuery).then(async (querySnapshot) => {
                if (!querySnapshot.empty) {
                    const firstDoc = querySnapshot.docs[0];
                    for (let i = 0; i < notifOwnerData.length; i++) {
                        if (notifOwnerData[i].hasOwnProperty("notificationsTabLastSeenAt") && firstDoc.data().createdAt > notifOwnerData[i].notificationsTabLastSeenAt) {
                            const ownerDocRef = doc(db, 'members', notifOwnerData[i].email);
                            await updateDoc(ownerDocRef, {
                                notificationsUnseen: increment(-1),
                            });
                        }
                    }
                    deleteDoc(doc(db, "notifications", firstDoc.id))
                        .catch((error) => {
                            console.error("Error removing document: ", error);
                        });
                    const listSnapshot = await getDoc(listRef);
                    if (!listSnapshot.empty) {
                        const listDataFromDB = listSnapshot.data();
                        if (listDataFromDB.hasOwnProperty("notifications")) {
                            const listNotifications = listDataFromDB.notifications;
                            const notificationDocRef = doc(db, 'listNotifications', listNotifications);
                            await updateDoc(notificationDocRef, {
                                events: arrayRemove(firstDoc.id),
                            });
                        }
                    }
                }
            }).catch((error) => {
                console.error("Error getting documents: ", error);
            });
        } catch (error) {
            console.error('Error updating downvote:', error.message);
        }
    }
};

export const handleShare = async (listData) => {
    try {
        // let node = document.getElementById(`${listData.id}-rect`);
        // const blob = (await toBlob(node))
        // const filesArray = [new File([blob], 'share.png', { type: blob.type, lastModified: new Date().getTime() })];
        await navigator
            .share({
                text: `Check out this ${getTextForListOfCategory(listData.category)} curated by ${getOwnerStringFromOwnerData(await getOwnerDataFromOwnerIds(listData.memberId))}!`,
                url: window.location.origin + "/list/" + String(listData.id),
                // title: listData.name,
                // files: filesArray,
            })
    } catch (error) {
        console.error(`Web share failed because: ${error}`);
    }
}

export const updateShareImage = async (listId) => {
    let node = document.getElementById(`${listId}-rect`);
    if (node) {
        const blob = (await toBlob(node));
        const storageRef = ref(storage, `shareImages/list/${listId}-rect.png`);
        await uploadBytes(storageRef, blob);
    }
    node = document.getElementById(`${listId}-square`);
    if (node) {
        const blob = (await toBlob(node));
        const storageRef = ref(storage, `shareImages/list/${listId}-square.png`);
        await uploadBytes(storageRef, blob);
    }
}

/*** Member Information ***/
export const getProfileImageFromProfileData = (profileData) => {
    if (profileData) {
        if (profileData.hasOwnProperty("profileImage") && profileData.profileImage) {
            return profileData.profileImage;
        }
        return "https://firebasestorage.googleapis.com/v0/b/list-curation.appspot.com/o/profileImages%2Fdefault.png?alt=media&token=186abfe7-9270-49a8-8a16-a62af0ef926d";
    }
    return "https://firebasestorage.googleapis.com/v0/b/list-curation.appspot.com/o/profileImages%2Fdefault.png?alt=media&token=186abfe7-9270-49a8-8a16-a62af0ef926d";
}

export const getUsernameFromUserId = async (userId) => {
    try {
        const q = query(collection(db, 'members'), where('id', '==', userId));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0];
            const userDataFromDB = userDoc.data();
            return userDataFromDB.username;
        }
    } catch (error) {
        console.error("Error fetching user data:", error);
    }
    return null;
};

export const getUserDataFromUserId = async (userId) => {
    try {
        const q = query(collection(db, 'members'), where('id', '==', userId));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0];
            const userDataFromDB = userDoc.data();
            return userDataFromDB;
        }
    } catch (error) {
        console.error("Error fetching user data (utils):", error);
    }
    return null;
};

/*** Member Actions ***/
export const handleProfileShare = async (profileData) => {
    try {
        // let node = document.getElementById(`${profileData.username}-rect`);
        // const blob = (await toBlob(node))
        // const filesArray = [new File([blob], 'share.png', { type: blob.type, lastModified: new Date().getTime() })];
        await navigator
            .share({
                text: `Check out ${profileData?.firstName}'${!profileData?.firstName.endsWith('s') ? 's' : ''} palette!`,
                url: window.location.origin + `/${profileData.username}`,
                // title: profileData.name,
                // files: filesArray,
            })
    } catch (error) {
        console.error(`Web share failed because: ${error}`);
    }
}

export const updateProfileShareImage = async (username) => {
    let node = document.getElementById(`${username}-rect`);
    if (node) {
        const blob = (await toBlob(node));
        const storageRef = ref(storage, `shareImages/profile/${username}-rect.png`);
        await uploadBytes(storageRef, blob);
    }
    node = document.getElementById(`${username}-square`);
    if (node) {
        const blob = (await toBlob(node));
        const storageRef = ref(storage, `shareImages/profile/${username}-square.png`);
        await uploadBytes(storageRef, blob);
    }
}

/*** Display and Parsing ***/
export const getVisualListFromArray = (listArray) => {
    if (!listArray || listArray.length === 0) {
        return null;
    }

    if (listArray.length === 1) {
        return listArray[0];
    }

    if (listArray.length === 2) {
        return `${listArray[0]} & ${listArray[1]}`;
    }

    const formattedList = listArray.slice(0, -1).join(', ') + ` & ${listArray[listArray.length - 1]}`;
    return formattedList;
};

export const getOwnerStringFromOwnerData = (ownerData) => {
    let ownerString = "";

    if (ownerData.length === 1) {
        ownerString += (ownerData[0]?.firstName || '') + " " + (ownerData[0]?.lastName || '');
    } else if (ownerData.length === 2) {
        ownerString += (ownerData[0]?.firstName || '') + " " + (ownerData[0]?.lastName || '') + " and " + (ownerData[1]?.firstName || '') + " " + (ownerData[1]?.lastName || '');
    } else if (ownerData.length > 2) {
        ownerString += (ownerData[0]?.firstName || '') + " " + (ownerData[0]?.lastName || '') + " and " + (ownerData.length - 1) + " others";
    } else {
        ownerString += "Unknown";
    }

    return ownerString;
}

export const formatDate = (inputDate) => {
    try {
        if (inputDate === "") {
            return "Unknown";
        }
        const [year, month, day] = inputDate.split('-');
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        let result = "";
        if (day) {
            const formattedDate = new Date(year, month - 1, day);
            const monthName = months[formattedDate.getMonth()];
            const formattedDay = formattedDate.getDate();
            const formattedYear = formattedDate.getFullYear();
            result = `${monthName} ${formattedDay}, ${formattedYear}`;
        } else {
            const monthName = months[month - 1];
            const formattedYear = year;
            result = `${monthName} ${formattedYear}`;
        }
        return result;
    } catch {
        return "Unknown";
    }
};

export const formatTimeDifference = (timestamp, isLong = false) => {
    const now = Date.now();
    const diffInSeconds = Math.floor((now - timestamp) / 1000);
    let quantity, ending;
    if (diffInSeconds < 60) {
        quantity = diffInSeconds;
        ending = isLong ? ' second' : 's';
    } else if (diffInSeconds < 3600) {
        quantity = Math.floor(diffInSeconds / 60);
        ending = isLong ? ' minute' : 'm';
    } else if (diffInSeconds < 86400) {
        quantity = Math.floor(diffInSeconds / 3600);
        ending = isLong ? ' hour' : 'h';
    } else if (diffInSeconds < 604800) {
        quantity = Math.floor(diffInSeconds / 86400);
        ending = isLong ? ' day' : 'd';
    } else if (diffInSeconds < 31536000) {
        quantity = Math.floor(diffInSeconds / 604800);
        ending = isLong ? ' week' : 'w';
    } else {
        quantity = Math.floor(diffInSeconds / 31536000);
        ending = isLong ? ' year' : 'y';
    }
    if (isLong && quantity !== 1) {
        ending += 's ago';
    }
    return quantity + ending;
}

export const convertUTCToLocalTime = (time) => {
    const dateObj = new Date(time);
    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    const formattedDate = dateObj.toLocaleDateString('en-US', options);
    return formattedDate
}

export const getTextForListOfCategory = (category, isPlural = false) => {
    switch (category) {
        case "All":
            return `list${isPlural ? "s" : ""}`;
        case "TV Shows":
            return `list${isPlural ? "s" : ""} of TV shows`;
        case "Freestyle":
            return `freestyle list${isPlural ? "s" : ""}`;
        default:
            return `list${isPlural ? "s" : ""} of ${category.toLowerCase()}`;
    }
}

/*** Following Created List Notification ***/
export const sendNewListCreationNotifications = async (listId, userData) => {
    try {
        const newNotificationId = uuidv4();
        const newNotificationDoc = {
            id: newNotificationId,
            type: "newList",
            createdAt: Date.now(),
            listId: listId,
            userId: userData.id
        };

        const followers = userData.followers;

        // Save the notification document
        await setDoc(doc(db, "notifications", newNotificationId), newNotificationDoc);

        for (let i = 0; i < followers.length; i++) {
            const currentFollowerId = followers[i];
            const currentFollowerQuery = query(collection(db, 'members'), where('id', '==', currentFollowerId));
            const querySnapshot = await getDocs(currentFollowerQuery);
            if (!querySnapshot.empty) {
                const currentFollowerDoc = querySnapshot.docs[0];
                const currentFollowerData = currentFollowerDoc.data();

                // Increment notificationsUnseen by 1
                await updateDoc(doc(db, 'members', currentFollowerData.email), {
                    notificationsUnseen: increment(1)
                });

                // Add notification event to profile notifications array
                const currentFollowerNotificationId = currentFollowerData.notifications;
                await updateDoc(doc(db, 'profileNotifications', currentFollowerNotificationId), {
                    events: arrayUnion(newNotificationId)
                });

                try {
                    // Email details
                    let subject = "New List Alert: Someone you follow just added to their Palette!";
                    let template = "Following User Creates List";
                    let variables = {
                        userFirstName: currentFollowerData.firstName,
                        listOwnerFirstName: userData.firstName,
                        listOwnerLink: window.location.origin + "/" + userData.username,
                        listName: await getListTitleFromListId(listId),
                        listLink: window.location.origin + "/list/" + listId
                    };

                    // Send the email
                    try {
                        await axios.post(`/api/email`, { email: currentFollowerData.email, subject, template, variables });
                    } catch (error) {
                        console.error('Error sending email:', error.message);
                    }
                } catch (error) {
                    console.error('Error sending email:', error.message);
                }
            }
        }
    } catch (error) {
        console.error('Error sending notifications:', error.message);
    }
}

export const deleteListNotificationsAndUpdateUnseenCount = async (listId) => {
    try {
        const q = query(collection(db, 'notifications'), where('listId', '==', listId));
        const querySnapshot = await getDocs(q);

        // Map over the notifications and create promises for deletions and unseen count updates
        const profileNotificationDeletePromises = querySnapshot.docs.map(async (profileNotifDoc) => {
            const notificationData = profileNotifDoc.data();
            const userId = notificationData.userId;
            const createdAt = notificationData.createdAt;

            // Query the user's document using userId parameter
            const memberQuery = query(collection(db, 'members'), where('id', '==', userId));
            const memberQuerySnapshot = await getDocs(memberQuery);
            if (!memberQuerySnapshot.empty) {
                const userDoc = memberQuerySnapshot.docs[0];
                const userData = userDoc.data();
                if (userData && userData.followers) {
                    const followerPromises = userData.followers.map(async (followerId) => {
                        const followerQuery = query(collection(db, 'members'), where('id', '==', followerId));
                        const followerQuerySnapshot = await getDocs(followerQuery);
                        if (!followerQuerySnapshot.empty) {
                            const followerDoc = followerQuerySnapshot.docs[0];
                            const followerData = followerDoc.data();
                            const notificationsTabLastSeenAt = followerData.notificationsTabLastSeenAt;
                            if (createdAt > notificationsTabLastSeenAt) {
                                await updateDoc(followerDoc.ref, {
                                    notificationsUnseen: increment(-1),
                                });
                            }
                        }
                    });
                    await Promise.all(followerPromises);
                }
            }
            return deleteDoc(profileNotifDoc.ref);
        });
        await Promise.all(profileNotificationDeletePromises);
    } catch (error) {
        console.error('Error deleting notifications and updating unseen count:', error.message);
    }
};

/*** List Recommendations ***/
export const fetchRecommendations = async (elementList, selectedCategory, moviesApiKey, setDisplayCardData, setNoRecommendations, setPageIsLoading, listId) => {
    const validElementList = elementList.filter(([elementId, originalLang]) => elementId !== undefined);
    const recDataFetchPromises = validElementList.map(async (currItem) => {
        const [elementId, originalLang] = currItem;
        let currentDB;
        let endPoint;
        if (selectedCategory === "Movies") {
            currentDB = 'MovieRecommendations';
            endPoint = "movie";
        } else if (selectedCategory === "TV Shows") {
            currentDB = "TVRecommendations";
            endPoint = "tv";
        }
        const docRef = doc(db, currentDB, elementId.toString());
        const docSnap = await getDoc(docRef);
        if (docSnap.exists() && docSnap.data().hasOwnProperty("supportsAdaptive")) {
            return docSnap.data().recList;
        } else {
            const url = `https://api.themoviedb.org/3/${endPoint}/${elementId}/recommendations?page=1&api_key=${moviesApiKey}`;
            const response = await fetch(url);
            const responseData = await response.json();
            const currentRecsList = responseData.results.filter(val => val.original_language === originalLang).slice(0, 25);
            const finalArray = await filterHashmaps(currentRecsList, selectedCategory, moviesApiKey);
            if (finalArray.length > 0) {
                await setDoc(doc(db, currentDB, elementId.toString()), {
                    id: elementId,
                    recList: finalArray,
                    supportsAdaptive: true
                });
            }
            return finalArray;
        }
    });
    try {
        const allRecData = await Promise.all(recDataFetchPromises);
        let dislikedRecommendations = [];
        if (listId) {
            const listRef = doc(db, 'lists', listId);
            const listSnap = await getDoc(listRef);
            if (listSnap.exists()) {
                const listData = listSnap.data();
                if (!listData.hasOwnProperty('recommendationsUnliked')) {
                    await updateDoc(listRef, {
                        recommendationsUnliked: []
                    });
                } else {
                    dislikedRecommendations = listData.recommendationsUnliked;
                }
            }
        }
        let allFilteredRecData = [];
        allRecData.forEach((recData) => {
            let filteredRecData = recData.filter(
                item => !dislikedRecommendations.includes(item.id)
            );
            filteredRecData = filteredRecData.slice(0, 3);
            allFilteredRecData.push(filteredRecData);
        });
        allFilteredRecData = allFilteredRecData.flat().filter((item, index, self) =>
            index === self.findIndex(obj => obj.id === item.id) && !elementList.some(subArray => subArray.includes(item.id))
        );
        allFilteredRecData = allFilteredRecData.slice(0, 20);
        setDisplayCardData(allFilteredRecData);
        if (allFilteredRecData.length === 0) {
            setNoRecommendations(true);
        }
        setPageIsLoading(false);
    } catch (error) {
        console.error('Error fetching recommendations:', error.message);
        setPageIsLoading(false);
    }
};

const filterHashmaps = async (array, selectedCategory, moviesApiKey) => {
    const endPoint = selectedCategory === "Movies" ? "movie" : "tv";
    const chooseArr = selectedCategory === "Movies" ? ['id', 'overview', 'title', 'release_date', 'poster_path', 'vote_average', 'vote_count'] : ['id', 'overview', 'name', 'poster_path', 'vote_average', 'vote_count'];
    const renameMap = selectedCategory === "Movies" ? { title: 'movieName', release_date: 'releaseDate', poster_path: 'posterpath' } : { name: 'showName', poster_path: 'posterpath' };
    return Promise.all(array.map(async (hashmap) => {
        const filteredHashmap = chooseArr.reduce((acc, key) => {
            if (hashmap.hasOwnProperty(key)) {
                acc[key] = hashmap.hasOwnProperty(key) ? hashmap[key] : null;
            }
            return acc;
        }, {});
        if (hashmap.id) {
            const response = await fetch(`https://api.themoviedb.org/3/${endPoint}/${hashmap.id}?api_key=${moviesApiKey}`);
            const jsonResponse = await response.json();
            filteredHashmap["genres"] = jsonResponse.genres || null;
            if (selectedCategory === "Movies") {
                filteredHashmap["runtime"] = jsonResponse.runtime || null;
            } else {
                filteredHashmap["numberEpisodes"] = jsonResponse.number_of_episodes || null;
                filteredHashmap["numberSeasons"] = jsonResponse.number_of_seasons || null;
                filteredHashmap["episodeRuntime"] = jsonResponse.episode_run_time || null;
            }
        }
        return renameKeys(filteredHashmap, renameMap);
    }));
};

const renameKeys = (obj, newKeys) => Object.keys(obj).reduce((acc, key) => {
    const newKey = newKeys[key] || key;
    acc[newKey] = obj[key];
    return acc;
}, {});

export const dislikeRecommendationForList = async (event, listId, itemId) => {
    event.preventDefault();
    const listRef = doc(db, 'lists', listId);
    const listSnap = await getDoc(listRef);
    if (listSnap.exists()) {
        const listData = listSnap.data();
        if (!listData.hasOwnProperty('recommendationsUnliked')) {
            await updateDoc(listRef, {
                recommendationsUnliked: [itemId]
            });
        } else {
            await updateDoc(listRef, {
                recommendationsUnliked: arrayUnion(itemId)
            });
        }
    }
};

/*** List Feed Sorting ***/
export const customSort = (a, b) => {
    const weightLastUpdated = 0.3;
    const weightScore = 0.4;
    const weightImageExists = 0.1;
    const weightDataLength = 0.15;
    const weightPenaltyOver20Entries = 0.05;

    const now = Date.now();

    const penaltyAge = 30 * 24 * 60 * 60 * 1000; // 30 days ago, we can potentially change this to 45 days
    const aAge = a.lastUpdated ? now - a.lastUpdated : penaltyAge;
    const bAge = b.lastUpdated ? now - b.lastUpdated : penaltyAge;

    const aScore = getListScoreFromListData(a);
    const bScore = getListScoreFromListData(b);

    const aImageExists = a.listImage ? 1 : 0;
    const bImageExists = b.listImage ? 1 : 0;

    const aDataLength = a.data.length > 20 ? 20 : a.data.length;
    const bDataLength = b.data.length > 20 ? 20 : b.data.length;

    const aPenaltyOver20Entries = aDataLength > 20 ? (a.data.length - 20) : 0;
    const bPenaltyOver20Entries = bDataLength > 20 ? (b.data.length - 20) : 0;

    const aTotal = ((weightLastUpdated * (now - aAge)) / now) +
        (weightScore * aScore) +
        (weightImageExists * aImageExists) +
        (weightDataLength * aDataLength) -
        (weightPenaltyOver20Entries * aPenaltyOver20Entries);
    const bTotal = ((weightLastUpdated * (now - bAge)) / now) +
        (weightScore * bScore) +
        (weightImageExists * bImageExists) +
        (weightDataLength * bDataLength) -
        (weightPenaltyOver20Entries * bPenaltyOver20Entries);

    return bTotal - aTotal;
};

export const updateListOnSort = (lists, sortOptions) => {
    if (sortOptions === "Latest") {
        lists = lists.sort((a, b) => {
            const thirtyDaysAgo = new Date(Date.now() - (30 * 24 * 60 * 60 * 1000)).getTime();
            const lastUpdatedA = a.lastUpdated ? new Date(a.lastUpdated).getTime() : thirtyDaysAgo;
            const lastUpdatedB = b.lastUpdated ? new Date(b.lastUpdated).getTime() : thirtyDaysAgo;
            return lastUpdatedB - lastUpdatedA;
        });
    } else if (sortOptions === "Popular") {
        lists = lists.sort((a, b) => (b.score + b.likedBy.length) - (a.score + a.likedBy.length));
    } else if (sortOptions === "Relevant") {
        lists = lists.sort(customSort);
    }
    return lists;
}

/*** Miscellaneous ***/
export const scrollToTargetDiv = (targetDivRef) => {
    if (targetDivRef.current) {
        const rect = targetDivRef.current.getBoundingClientRect();
        const absoluteTop = window.scrollY + rect.top;
        window.scrollTo({
            top: absoluteTop - 155,
            behavior: 'smooth',
        });
    }
};

export const updateUserStreak = async (userData) => {
    const userRef = doc(db, 'members', userData.email);
    const currentTime = dayjs(new Date());

    if (!userData.lastSeenAt) {
        await updateDoc(userRef, {
            lastSeenAt: currentTime.valueOf(),
            currentStreak: 1,
        });
    } else {
        const lastSeenTime = dayjs(userData.lastSeenAt);

        let pseudoLastDay = lastSeenTime.startOf('day');
        let pseudoCurrentDay = currentTime.startOf('day');

        const differenceInDays = pseudoCurrentDay.diff(pseudoLastDay, 'day');

        if (differenceInDays === 1) {
            await updateDoc(userRef, {
                lastSeenAt: currentTime.valueOf(),
                currentStreak: userData.currentStreak + 1,
            });
        } else if (differenceInDays > 1) {
            await updateDoc(userRef, {
                lastSeenAt: currentTime.valueOf(),
                currentStreak: 1,
            });
        } else {
            await updateDoc(userRef, {
                lastSeenAt: currentTime.valueOf()
            });
        }
    }
};


export const refreshUserData = async (userId, setUserData) => {
    try {
        const q = query(collection(db, 'members'), where('id', '==', userId));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0];
            const userDataFromDB = userDoc.data();
            setUserData(userDataFromDB);
            await updateUserStreak(userDataFromDB)
        }
    } catch (error) {
        console.error("Error fetching list image:", error);
    }
    return null;
};

/*** Image Cropper ***/
// cropImage.js
export const getCroppedImg = async (imageSrc, crop) => {
    const createImage = (url) =>
        new Promise((resolve, reject) => {
            const image = new Image();
            image.addEventListener('load', () => resolve(image));
            image.addEventListener('error', (error) => reject(error));
            image.setAttribute('crossOrigin', 'anonymous');
            image.src = url;
        });

    const getCroppedImg = async (imageSrc, crop) => {
        const image = await createImage(imageSrc);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const maxSize = Math.max(image.width, image.height);
        const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

        canvas.width = safeArea;
        canvas.height = safeArea;

        ctx.translate(safeArea / 2, safeArea / 2);
        ctx.translate(-safeArea / 2, -safeArea / 2);

        ctx.drawImage(
            image,
            safeArea / 2 - image.width * 0.5,
            safeArea / 2 - image.height * 0.5
        );

        const data = ctx.getImageData(0, 0, safeArea, safeArea);

        canvas.width = crop.width;
        canvas.height = crop.height;

        ctx.putImageData(
            data,
            Math.round(0 - safeArea / 2 + image.width * 0.5 - crop.x),
            Math.round(0 - safeArea / 2 + image.height * 0.5 - crop.y)
        );

        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                const file = new File([blob], 'croppedImage.jpg', { type: 'image/jpeg' });
                resolve(file);
            }, 'image/jpeg');
        });
    };

    return getCroppedImg(imageSrc, crop);
};

export const handleGroupListCreation = async (listTitle, listCategory, userData, navigate, groupId, isCreatingList, setIsCreatingList, isChallenge) => {
    if (isCreatingList) {
        return;
    }
    setIsCreatingList(true);

    try {
        const listId = uuidv4();
        const notifsUUID = uuidv4();

        const newList = {
            data: [],
            id: listId,
            memberId: [userData.id],
            score: 0,
            likedBy: [],
            title: listTitle,
            category: listCategory,
            isPublic: isChallenge ? true : false,
            listImage: null,
            createdTime: Date.now(),
            lastUpdated: Date.now(),
            notifications: notifsUUID,
            groupId: groupId
        };
        await setDoc(doc(db, "lists", listId), newList);

        const newNotifs = {
            id: notifsUUID,
            events: []
        };
        await setDoc(doc(db, "listNotifications", notifsUUID), newNotifs);

        if (userData && userData.email) {
            const userDocRef = doc(db, 'members', userData.email);
            await updateDoc(userDocRef, {
                lists: arrayUnion(listId)
            });
        } else {
            console.error('User data or email not available');
            navigate("/500");
            return;
        }
        navigate(`/list/${listId}`);
        if (isChallenge) {
            await sendNewListCreationNotifications(listId, userData)
        }
    } catch (error) {
        console.error('Error creating a new list:', error.message);
        navigate("/500");
    }
};


export const moveCard = async (oldIndex, newIndex, selectedEntriesData, setSelectedEntriesData, listId) => {
    const newCardsData = [...selectedEntriesData];
    const movedCard = newCardsData.splice(oldIndex, 1)[0];
    newCardsData.splice(newIndex, 0, movedCard);

    try {
        const listDocRef = doc(db, 'lists', listId);
        const listDocSnapshot = await getDoc(listDocRef);
        let reversedList = [...newCardsData].reverse();
        if (listDocSnapshot.exists()) {
            await updateDoc(listDocRef, {
                data: reversedList,
                // lastUpdated: Date.now()
            });
            setSelectedEntriesData(newCardsData); // Update the local state with the new order
        } else {
            console.error('Error updating data after reordering cards');
        }
    } catch (error) {
        console.error('Error updating data after reordering cards:', error);
    }
};