import React from 'react';

const DSText = ({ fontSize, color, boldVar, marginTop, marginBottom, lineHeight, overflowWrap, children }) => {
  const style = {
    fontSize: fontSize || '16px',
    color: color || '#ffffff',
    fontWeight: boldVar || '400',
    marginTop: marginTop || '0px',
    marginBottom: marginBottom || '0px',
    fontFamily: "'Outfit', sans-serif",
    lineHeight: lineHeight || 'auto',
    overflowWrap: overflowWrap || 'normal'
  };
  return (
    <div style={style}>
      {children}
    </div>
  );
};

export default DSText;
