import { useState } from 'react';
import axios from 'axios';
import ClearIcon from '@mui/icons-material/Clear';
import DSText from '../../DSText'
import CopyMessage from './CopyMessage';
import './styles.css';

const DSChallengeModal = ({ onClose, userData, challengeId, challengeTitle }) => {
  const [email, setEmail] = useState("")

  const handleSubmit = async (e) => {
    e.preventDefault();
    let subject = "Someone invited you to join a challenge on Palette!";
    let template = "Challenge Invite";
    let variables = {
      senderFirstName: userData && userData.firstName ? `Your friend, ${userData.firstName},` : "Someone you know",
      challengeLink: window.location.origin + "/challenge/" + challengeId,
      challengeTitle: challengeTitle
    };
    try {
      await axios.post(`/api/email`, { email, subject, template, variables });
    } catch (error) {
      console.error('Error sending email:', error.message);
    }
    onClose();
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content modal-content-wide" onClick={(e) => e.stopPropagation()}>
        <div>
          <div className="modal-content-padding">
            <DSText fontSize="1.6em" boldVar="600" marginBottom="10px">
              Challenge Friends
            </DSText>
          </div>
          <div className="modalListContainer">
            <div>
              <form onSubmit={handleSubmit}>
                <label className="challengeFriendsLabel">Invite by email</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="email"
                  required
                />
                <button className="challengeFriendEmailButton" type="submit">
                  <img src="/email-icon-logo.svg" className="continue-icon" alt="Email" />
                  Send
                </button>
              </form>
              <DSText fontSize="0.95em" marginTop="20px" marginBottom="7px">Or invite by message</DSText>
              <div>
                <CopyMessage challengeId={challengeId} challengeTitle={challengeTitle} />
              </div>
            </div>
          </div>
        </div>
        <ClearIcon
          sx={{
            width: '0.75em',
            position: 'absolute',
            top: '8px',
            right: '8px',
            color: 'white',
            cursor: 'pointer',
            transition: 'all 0.15s ease-in-out',
            '&:hover': {
              backgroundColor: 'transparent !important',
              color: '#ef00e7 !important',
            },
          }}
          onClick={onClose}
        />
      </div>
    </div>
  );
};

export default DSChallengeModal;