import React, { useEffect, useState } from 'react';
import { getTextForListOfCategory } from '../../../Utils';
import DSText from '../../../DSSystem/DSText';
import ListCard from '../../ListCard';

const PaletteTab = ({ uid, userData, likedLists, setLikedLists, allListData, setAllListData, flagArray, setFlagArray, isHoverArray, setIsHoverArray }) => {
  const [hoveredId, setHoveredId] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [filteredListData, setFilteredListData] = useState([]);
  const categories = ['All', 'Movies', 'TV Shows', 'Books', 'Video Games', 'Web Pages', 'Freestyle'];

  useEffect(() => {
    const adminEmails = ["mehulrastogi2002@gmail.com", "mittalprakhar57@gmail.com"]
    const visibleListData = allListData?.filter(listData => (
      listData.isPublic || (listData.memberId && userData && listData.memberId.includes(userData.id)) || (userData && adminEmails.includes(userData.email))
    ));
    setFilteredListData(selectedCategory === 'All'
      ? visibleListData
      : visibleListData.filter(listData => listData.category === selectedCategory));
  }, [userData, allListData, selectedCategory])

  return (
    <>
      {allListData ? (
        <>
          {allListData.length > 0 ? (
            <>
              <div className="category-button-container">
                {categories.map(category => (
                  <button
                    key={category}
                    className={`category-button category-button-selected-${selectedCategory === category}`}
                    onClick={() => setSelectedCategory(category)}
                  >
                    {category}
                  </button>
                ))}
              </div>
              {filteredListData.length > 0 ? (
                <div className="listCardContainer">
                  {filteredListData.map((listData, index) => (
                    <ListCard
                      key={listData.id}
                      listData={listData}
                      userData={userData}
                      likedLists={likedLists}
                      setLikedLists={setLikedLists}
                      setAllListData={setAllListData}
                      index={index}
                      hoveredId={hoveredId}
                      setHoveredId={setHoveredId}
                      flagArray={flagArray}
                      setFlagArray={setFlagArray}
                      isHoverArray={isHoverArray}
                      setIsHoverArray={setIsHoverArray}
                    />
                  ))}
                </div>
              ) : (
                <DSText marginTop="15px" marginBottom={userData && uid === userData.id ? '35px' : '0px'}>
                  {`No ${getTextForListOfCategory(selectedCategory, true)} found.`}
                </DSText>
              )}
            </>
          ) : userData && uid === userData.id ? (
            <div style={{ paddingTop: '10px' }} />
          ) : (
            <DSText marginTop="15px">Palette is empty.</DSText>
          )}
        </>
      ) : (
        <DSText marginTop="15px" marginBottom="40px">Loading...</DSText>
      )}
    </>
  );
};

export default PaletteTab;