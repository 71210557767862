import React, { useState } from 'react';
import isURL from 'validator/lib/isURL'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DSText from '../../../../../DSSystem/DSText';
import DSButton from '../../../../../DSSystem/DSButton';
import ImageCropper from './ImageCropper';
import './styles.css';

const EditArticleDialog = ({ open, onClose, onSubmit, entryData, category }) => {
    const [articleName, setArticleName] = useState(category === "freestyle" ? entryData.title : entryData.articleName);
    const [author, setAuthor] = useState(category === "freestyle" ? entryData.subtitle : entryData.author);
    const [newImage, setNewImage] = useState(null);
    const [url, setUrl] = useState(entryData.url);
    const [isUrlValid, setIsUrlValid] = useState(true);

    return (
        <Dialog onClose={onClose} open={open} sx={{
            '& .MuiDialog-paper': {
                width: '80%',
                maxWidth: '400px',
                backgroundColor: '#0a0a0a !important',
                color: 'white'
            },
        }}>
            <DialogTitle>
                <DSText fontSize="1.5em" boldVar="600" marginBottom="-5px">Edit Card</DSText>
            </DialogTitle>
            <DialogContent sx={{
                padding: '20px 24px 0px 24px'
            }}>
                <label className="editDialogLabel">Title</label>
                <input
                    type="text"
                    value={articleName}
                    onChange={(e) => setArticleName(e.target.value)}
                    required
                    className="editDialogInput"
                />
                <label className="editDialogLabel">{category === "freestyle" ? "Subtitle" : "Author"}</label>
                <input
                    type="text"
                    value={author}
                    onChange={(e) => setAuthor(e.target.value)}
                    required
                    className="editDialogInput"
                />
                {category === "freestyle" &&
                    <>
                        <label className="editDialogLabel">Link</label>
                        <input
                            type="text"
                            value={url}
                            onChange={(e) => setUrl(e.target.value)}
                            required
                            className="editDialogInput"
                        />
                        {isUrlValid ? <></> : <div className="invalidUrl">Invalid link</div>}
                    </>
                }
                <label className="editDialogLabel">Thumbnail <span style={{ marginLeft: "2px", color: "#bfbfbf" }}>(Optional)</span></label>
                <ImageCropper setNewImage={setNewImage} />
            </DialogContent>
            <DialogActions sx={{ padding: "8px 24px 22px 8px !important" }}>
                <DSButton onClick={onClose} buttonText="Cancel" size="small" isFilled={true} />
                {category === "freestyle"
                    ? <DSButton onClick={() => {
                        if (url === "" || isURL(url)) {
                            setIsUrlValid(true);
                            onSubmit({ title: articleName, subtitle: author, newImage, url })
                        } else {
                            setIsUrlValid(false);
                        }
                    }} buttonText="Update" size="small" />
                    : <DSButton onClick={() => onSubmit({ articleName, author, newImage })} buttonText="Update" size="small" />
                }
            </DialogActions>
        </Dialog>
    );
};

export default EditArticleDialog;
