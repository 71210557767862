import React, { useState, useEffect } from 'react';
import { fetchRecommendations } from '../../../Utils';
import MovieCard from '../CategoryCards/MovieCard';
import ShowCard from '../CategoryCards/ShowCard';
import DSText from '../../../DSSystem/DSText';

const ListRecommendations = ({ entryData, userData, ownerIds, listCategory, moviesApiKey, setSelectedEntriesData, listId }) => {
  const [displayCardData, setDisplayCardData] = useState([]);
  const [noRecommendations, setNoRecommendations] = useState(true);
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [updateTrigger, setUpdateTrigger] = useState(false);

  useEffect(() => {
    if (!(listCategory === "Movies" || listCategory === "TV Shows")) {
      return;
    }
    const allIds = entryData.map(hashmap => [hashmap.id, hashmap.original_language]);
    const fetchData = async () => {
      try {
        await fetchRecommendations(allIds, listCategory, moviesApiKey, setDisplayCardData, setNoRecommendations, setPageIsLoading, listId);
      } catch (error) {
        console.error("Unable to fetch recommendations:", error);
      }
    };

    if (entryData && listCategory && moviesApiKey && listId) {
      fetchData();
    }
  }, [entryData, listCategory, moviesApiKey, listId, updateTrigger]);

  if (!(listCategory === "Movies" || listCategory === "TV Shows")) {
    return null;
  }

  if (pageIsLoading) {
    return <DSText fontSize="1em" marginTop="15px">Brewing your recommendations...</DSText>;
  } else if (displayCardData.length === 0 && noRecommendations) {
    return <DSText fontSize="1em" marginTop="15px">Add to your list to see new recommendations!</DSText>;
  } else {
    return (
      <div>
        {displayCardData.map((entry, index) => (
          listCategory === "Movies" ? (
            <MovieCard
              key={entry.id}
              index={index}
              entryData={entry}
              userData={userData}
              ownerIds={ownerIds}
              setSelectedEntriesData={setSelectedEntriesData}
              listId={listId}
              isRecommendation={true}
              setUpdateTrigger={setUpdateTrigger}
            />
          ) : (
            <ShowCard
              key={entry.id}
              index={index}
              entryData={entry}
              userData={userData}
              ownerIds={ownerIds}
              setSelectedEntriesData={setSelectedEntriesData}
              listId={listId}
              isRecommendation={true}
              setUpdateTrigger={setUpdateTrigger}
            />
          )
        ))}
      </div>
    );
  }
};

export default ListRecommendations;