import React from 'react';
import { Card, CardContent, IconButton, Box } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DSText from '../../../DSText';
import '../../styles.css'

const CopyMessage = ({ challengeId, challengeTitle }) => {
  const messageToBeCopied = `Holy smokes! 🔥 Think you've got taste as killer as mine? Let's duke it out in this mind-blowing Palette challenge: "${challengeTitle}"! Flex those curation muscles and show me whatcha got at www.palette.build/challenge/${challengeId}. It's time to see who's the real tastemaker around here! 🏆`

  const handleCopy = () => {
    navigator.clipboard.writeText(messageToBeCopied);
    alert("Text copied to clipboard!");
  };

  return (
    <Card sx={{ maxWidth: 345, borderRadius: 2, backgroundColor: 'white', padding: 0 }}>
      <CardContent sx={{ padding: '12px 12px 0px 12px !important' }}>
        <DSText fontSize="0.89em" color="#1e1e1e" overflowWrap="anywhere">{messageToBeCopied}</DSText>
        <Box display="flex" justifyContent="flex-end" alignItems="center" mb={0.5} mr={-1.5}>
          <IconButton onClick={handleCopy} color="primary">
            <FileCopyIcon
              sx={{ color: '#645dfc', fontSize: '0.9em !important' }}
            />
          </IconButton>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CopyMessage;
