import React from 'react';
import DSText from '../../../../DSSystem/DSText';
import Box from '@mui/material/Box';

export default function FeatureCard({ heading, icon: Icon }) {
  return (
    <div style={{
      borderRadius: '20px',
      padding: '20px',
      backgroundColor: '#212121',
      boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
      display: 'flex',
      alignItems: 'center',
    }}>
      <Box sx={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
        {Icon ? <Icon sx={{ color: "#c389ff", fontSize: "1.65em" }} /> : null}
        <DSText fontSize="1.3em" boldVar="400">
          {heading}
        </DSText>
      </Box>
    </div>
  );
}
