import React from 'react';
import DSText from '../../../DSSystem/DSText';
import './styles.css';

const ProfileShareRectangle = ({ profileImage, profileUsername, profileFirstName, profileLastName, publicListLength, followersLength }) => {
	return (
		<div style={{ position: "absolute", top: "0", opacity: "0", pointerEvents: "none" }}>
			<div className="profileShareRect" id={`${profileUsername}-rect`}>
				<div className="profileShareRectBody">
					{profileImage && <img className="profileShareRectThumbnail" src={profileImage} alt="thumbnail" />}
					<div>
						<DSText fontSize="16px" boldVar="600" marginBottom="1px">{profileFirstName} {profileLastName}</DSText>
						<DSText fontSize="14px" boldVar="600" color="#cfcfcf" marginBottom="9px">@{profileUsername}</DSText>
						<DSText fontSize="14px">
							{publicListLength}
							{publicListLength === 1 ? " list" : " lists"}
							<span style={{ paddingRight: "10px" }}></span>
							{followersLength}
							{followersLength === 1 ? " follower" : " followers"}
						</DSText>
					</div>
				</div>
				<div className="profileShareRectFooter">
					<DSText fontSize="14px">
						{"See profile at "}
						<span className="profileShareRectLink">palette.build/{profileUsername}</span>
					</DSText>
				</div>
			</div>
		</div>
	);
};

export default ProfileShareRectangle;